import React from "react";
import { Container, Form, Button } from "react-bootstrap";
import styled from "styled-components";
import role from "../../constants/role";
import appTheme from "../../constants/theme";
import RoleCmp from "../RoleCmp/RoleCmp";
import { Search } from "@styled-icons/boxicons-regular";

const TeamContainer = styled(Container)`
  padding: 2rem 5rem 3rem;
  background: rgba(12, 122, 147, 0.03);
  text-align: center;
  background-color: #fff;

  @media only screen and (max-width: 767px) {
    padding: 2rem 3rem 2rem;
  }

  @media only screen and (max-width: 580px) {
    padding: 1rem 1rem 1rem;
  }
`;

const Wrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  font-weight: ${appTheme.WEIGHT.mediumBold};
  color: ${appTheme.COLORS.grey1};
  text-align: center;
  margin: 0 auto;
  padding-bottom: 2rem;

  @media only screen and (max-width: 828px) {
    font-size: 2.2rem;
  }

  @media only screen and (max-width: 773px) {
    font-size: 2rem;
  }

  @media only screen and (max-width: 368px) {
    font-size: 1.7rem;
  }
`;

const RoleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormContainer = styled.div`
  display: flex;
  justify-content: center;

  @media only screen and (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }
`;

const SearchWrapper = styled.div`
  position: relative;
  margin: 1rem;
  width: 40%;

  @media only screen and (max-width: 991px) {
    width: 50%;
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
    margin: 0.5rem 1rem;
  }
`;

const SearchIconWrapper = styled(Button)`
  position: absolute;
  background-color: rgba(225, 235, 245, 0.5);
  border-radius: 20px 0px 0px 20px;
  left: 0;
  padding: 1.3rem 1.1rem;
  margin: 0;
  cursor: pointer;
  outline: none;
  border: none;

  &:hover,
  &:focus,
  &:active {
    box-shadow: none !important;
    background-color: rgba(225, 235, 245, 0.5);
    border-radius: 20px 0px 0px 20px;
    left: 0;
    padding: 1.3rem 1.1rem;
    cursor: pointer;
    outline: none;
    border: none;
  }
`;

const Input = styled(Form.Control)`
  padding: 1.3rem 1rem;
  padding-left: 5rem;
  padding-right: 3.1rem;
  border-radius: 20px;
  color: #374151;
  background: rgba(225, 235, 245, 0.3);
  border: ${(props) =>
    props.message ? "1px solid red" : "1px solid transparent"};
  font-weight: 400;
  font-size: 16px;

  &:focus {
    box-shadow: none;
    border: 1px solid ${appTheme.COLORS.blueSecondary};
    background: rgba(243, 244, 246, 0.7);
  }
  &::placeholder {
    color: rgba(107, 114, 128, 0.3);
    font-weight: 400;
    font-size: 16px;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: rgba(107, 114, 128, 0.3);
    font-weight: 400;
    font-size: 18px;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: rgba(107, 114, 128, 0.3);
    font-weight: 400;
    font-size: 18px;
  }

  @media only screen and (max-width: 400px) {
    padding-left: 4.5rem;
  }
`;

const DropdownWrapper = styled.div`
  margin: 1rem;
  width: 40%;

  @media only screen and (max-width: 991px) {
    width: 50%;
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
    margin: 0.5rem 1rem;
  }
`;

const SelectInput = styled(Form.Select)`
  padding: 1.3rem 1rem;
  padding-left: 2rem;
  padding-right: 3.1rem;
  border-radius: 20px;
  color: #374151;
  background-color: rgba(225, 235, 245, 0.3);
  border: ${(props) =>
    props.message ? "1px solid red" : "1px solid transparent"};
  font-weight: 400;
  font-size: 16px;

  &:focus {
    box-shadow: none;
    border: 1px solid ${appTheme.COLORS.blueSecondary};
    background-color: rgba(243, 244, 246, 0.7);
  }
  &::placeholder {
    color: rgba(107, 114, 128, 0.3);
    font-weight: 400;
    font-size: 16px;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: rgba(107, 114, 128, 0.3);
    font-weight: 400;
    font-size: 18px;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: rgba(107, 114, 128, 0.3);
    font-weight: 400;
    font-size: 18px;
  }

  @media only screen and (max-width: 400px) {
    padding-left: 1.5rem;
  }
`;

function JoinCmp() {
  const selectData = [
    "All Teams",
    "Administration",
    "Compliance",
    "Customer Experience",
    "Engineering",
    "Finance",
    "Human Resource",
    "Marketing",
    "Payment",
    "Product",
    "Quality Assurance",
    "Sales",
    "Scrum & Productivity",
  ];
  return (
    <>
      <TeamContainer>
        <Wrapper>
          <Title>Join Our Team</Title>
          <FormContainer>
            <SearchWrapper>
              <SearchIconWrapper>
                <Search size={20} color="rgba(107, 114, 128, 0.5)" />
              </SearchIconWrapper>
              <Input placeholder="Search" />
            </SearchWrapper>
            <DropdownWrapper>
              <SelectInput placeholder="Select a choice">
                <option value="">Select a choice</option>
                {selectData &&
                  selectData.map((value, index) => {
                    return (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    );
                  })}
              </SelectInput>
            </DropdownWrapper>
          </FormContainer>
          <RoleWrapper>
            {role.map((v, i) => {
              return (
                <RoleCmp key={i} url={v.url} attr={v.attr} name={v.name} />
              );
            })}
          </RoleWrapper>
        </Wrapper>
      </TeamContainer>
    </>
  );
}

export default JoinCmp;
