import React from "react";
import styled from "styled-components";
import appTheme from "../../constants/theme";

const Text = styled.p`
  color: ${appTheme.COLORS.grey1};
  font-weight: ${(props) =>
    props.weighty ? appTheme.WEIGHT.mediumBold : appTheme.WEIGHT.small3};
  font-size: 16px;
  margin: ${(props) => props.noMargin && "0"};

  @media only screen and (max-width: 600px) {
    font-size: 14px;
  }
`;

const SpanText = styled.span`
  font-weight: 700;
`;

function TextCmp(props) {
  return (
    <>
      <Text noMargin={props.noMargin} weighty={props.weighty}>
        {props.span && <SpanText>{props.span}</SpanText>} {props.text}
      </Text>
    </>
  );
}

export default TextCmp;
