import React from "react";
import styled from "styled-components";
import appTheme from "../../constants/theme";

const QualityWrapper = styled.div`
  background-color: #fff;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  height: 200px;
  width: 260px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 0.3rem 3rem;
  padding: 1rem;

  @media only screen and (max-width: 968px) {
    margin-bottom: 1rem;
  }
`;

const Title = styled.h2`
  font-weight: ${appTheme.WEIGHT.medium};
  font-size: 20px;
  color: #374151;
`;

const Desc = styled.p`
  font-size: 15px;
  font-weight: ${appTheme.WEIGHT.small3};
  color: #374151;
`;

function QualityCard(props) {
  return (
    <>
      <QualityWrapper>
        <Title>{props.title}</Title>
        <Desc>{props.desc}</Desc>
      </QualityWrapper>
    </>
  );
}

export default QualityCard;
