import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import appTheme from "../../constants/theme";

const PageContainer = styled(Container)`
  background: ${(props) =>
    props.backgroundImage &&
    `url(${props.backgroundImage}),
    ${props.backgroundColor} no-repeat`};
  background-color: ${(props) =>
    props.backgroundColor && props.backgroundColor};
  padding: ${(props) => (props.padding ? `${props.padding} 0` : "8rem 0")};
  text-align: center;
  background-size: cover;

  @media only screen and (max-width: 920px) {
    padding: ${(props) =>
      props.padding ? `calc(${props.padding} - 2rem) 0` : "6rem 0"};
  }

  @media only screen and (max-width: 600px) {
    padding: ${(props) =>
      props.padding ? `calc(${props.padding} - 3rem) 0` : "5rem 0"};
  }
`;

const PageTitle = styled.h2`
  font-weight: ${appTheme.WEIGHT.mediumBold};
  color: ${appTheme.COLORS.grey1};
  font-size: 3rem;
  padding: 0 1rem;

  @media only screen and (max-width: 920px) {
    font-size: 2.5rem;
  }

  @media only screen and (max-width: 600px) {
    font-size: 2rem;
  }
`;

const PageDescription = styled.p`
  font-weight: ${appTheme.WEIGHT.small3};
  color: #374151;
  font-size: 18px;
  max-width: 680px;
  text-align: center;
  margin: 0 auto;
  padding: 0 1.5rem;
`;

const Wrapper = styled.div`
  max-width: ${(props) => props.width && props.width};
  margin: 0 auto;

  @media only screen and (max-width: 1023px) {
    max-width: unset;
  }
`;

function PageHeaderCmp(props) {
  return (
    <>
      <PageContainer
        fluid
        backgroundImage={props.backImage}
        backgroundColor={props.backColor}
        padding={props.padding}
      >
        <Wrapper width={props.width}>
          <PageTitle>{props.title}</PageTitle>
          {props.description && (
            <PageDescription>{props.description}</PageDescription>
          )}
        </Wrapper>
      </PageContainer>
    </>
  );
}

export default PageHeaderCmp;
