import React, { useState } from "react";
import styled from "styled-components";
import Collapsible from "react-collapsible";
import { ChevronUp } from "@styled-icons/evaicons-solid";
import "./CollapsibleCmp.css";

const Wrapper = styled.div``;

const CollapsibleWrapper = styled(Collapsible)`
  border: ${(props) =>
    props.opened === true ? `1px solid #016cba` : "1px solid #adb5bd"};
`;

const ChevronIcon = styled(ChevronUp)`
  color: #000 !important;
`;

function CollapsibleCmp(props) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Wrapper>
        <CollapsibleWrapper
          opened={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          trigger={[`${props.collapseTitle}`, <ChevronIcon size={28} />]}
        >
          {props.desc}
        </CollapsibleWrapper>
      </Wrapper>
    </>
  );
}

export default CollapsibleCmp;
