import React from "react";
import FooterCmp from "../components/FooterCmp/FooterCmp";
import NavbarCmp from "../components/Navbar/NavbarCmp";
import NewsletterCmp from "../components/NewsletterCmp/NewsletterCmp";
import PageHeaderCmp from "../components/PageHeaderCmp/PageHeaderCmp";
import CareerSubImg from "../assets/careersub-vector.svg";
import CareerSubCmp from "../components/CareerSubCmp/CareerSubCmp";
import WorkWithUsCmp from "../components/WorkWithUsCmp/WorkWithUsCmp";

function CareerSub() {
  return (
    <>
      <NavbarCmp colored />
      <PageHeaderCmp
        padding="10rem"
        title="Product Manager"
        backColor="rgba(208, 218, 228, 0.2)"
        backImage={CareerSubImg}
      />
      <CareerSubCmp />
      <WorkWithUsCmp
        title="Apply Now"
        desc="Send your application and we will get back to you shortly"
      />
      <NewsletterCmp backColor="#f9fafb" />
      <FooterCmp />
    </>
  );
}

export default CareerSub;
