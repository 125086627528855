import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import appTheme from "../../constants/theme";
import SolidButton from "../Buttons/SolidButton";
import NewsCardCmp from "../NewsCardCmp/NewsCardCmp";
import { useNavigate } from "react-router-dom";
import { getAllNewsHome } from "../../redux/actions/newsAction";
import { connect } from "react-redux";

const NewsContainer = styled(Container)`
  padding: 4rem 5rem 0rem;

  @media only screen and (max-width: 767px) {
    padding: 1rem 4rem 3rem;
  }

  @media only screen and (max-width: 580px) {
    padding: 2rem 1rem 2rem;
  }
`;

const InTheNewsWrapper = styled.div`
  max-width: 1536px;
  margin: 0 auto;
`;

const InTheNewsTitle = styled.h2`
  color: ${appTheme.COLORS.grey1};
  font-weight: ${appTheme.WEIGHT.mediumBold};
  font-size: ${appTheme.SIZES.bigTitle};
  text-align: center;
`;

const NewsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1050px;
  margin: 0 auto;
`;

const NewsContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 3rem 0;

  @media only screen and (max-width: 991px) {
    padding: 2rem 0 1rem;
  }
`;

const EachBlog = styled.div``;

function InTheNewsCmp({ setGetAllNewsHome, newsHome, loadingHome, errorHome }) {
  const [newsData, setNewsData] = useState([]);
  useEffect(() => {
    const getAllNews = async () => {
      const response = await setGetAllNewsHome();

      setNewsData(response.data.results);
    };
    getAllNews();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigate();
  return (
    <>
      <NewsContainer>
        <InTheNewsWrapper>
          <InTheNewsTitle>In The News</InTheNewsTitle>
          <NewsWrapper>
            <NewsContentWrapper>
              {newsData &&
                newsData?.length > 0 &&
                newsData.map((v, i) => {
                  return (
                    <EachBlog
                      key={i}
                      onClick={() => {
                        navigate(`/post/${v.slug}`, { state: v });
                      }}
                    >
                      <NewsCardCmp
                        key={i}
                        title={v.title}
                        desc={v.snippet}
                        image={v.newsPicUrl}
                        slugs={v.tags}
                      />
                    </EachBlog>
                  );
                })}
              {!newsData && <div>No news added at the moment</div>}
            </NewsContentWrapper>
            {newsData.length > 0 && (
              <SolidButton
                text="See More"
                onClick={() => {
                  navigate("/blog");
                }}
                type="button"
                weighty="500"
                textFontSize="16px"
                specifyPadding="1rem 2rem"
                color="#374151"
                borderRadius="22px"
                backColor="#FFB140"
                backgroundHoverColor="#FFB140"
                textHoverColor="#374151"
                margin="1.5rem auto 0"
              />
            )}
          </NewsWrapper>
        </InTheNewsWrapper>
      </NewsContainer>
    </>
  );
}

const mapState = (state) => ({
  loadingHome: state.news.allNewsHomeLoading,
  errorHome: state.news.allNewsHomeError,
  newsHome: state.news.allNewsHome,
});

const mapDispatch = (dispatch) => ({
  setGetAllNewsHome: () => dispatch(getAllNewsHome()),
});

export default connect(mapState, mapDispatch)(InTheNewsCmp);
