import * as yup from "yup";

const newsletterValidatorSchema = yup.object().shape({
  email: yup
    .string()
    .email("It must be a valid email")
    .required("Please enter your email address"),
});

export default newsletterValidatorSchema;
