const qualitiesData = [
  {
    title: "Accountability",
    desc: "We are determined to take responsibility because together we can make a difference",
  },
  {
    title: "An open mind",
    desc: "We believe that change and new ideas are meant to be developed not threatening",
  },
  {
    title: "Passion",
    desc: "We are enthusiastic about our work, which births our consciousness towards the mission.",
  },
  {
    title: "An adaptive mindset",
    desc: "We have a positive perspective towards our work",
  },
  {
    title: "Self-direction",
    desc: "We Figure Out Solutions, And Make Tremendous Efforts In Carrying Them Out ",
  },
  {
    title: "A proactive attitude",
    desc: "As Colleagues, We Look Out For Each Other And Make Our Work Our Priority",
  },
];

export default qualitiesData;
