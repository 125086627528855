import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import TextCmp from "../TextCmp/TextCmp";
import TitleCmp from "../TitleCmp/TitleCmp";
import TitleCmpWithStar from "../TitleCmp/TitleCmpWithStar";

const ContentContainer = styled(Container)`
  background-color: #f9fafb;
  padding: 4rem 3rem;
  text-align: left;

  @media only screen and (max-width: 600px) {
    padding: 3rem 2rem;
  }
`;

const ContentWrapper = styled.div`
  max-width: 850px;
  margin: 0 auto;
`;

const WhiteBackground = styled.div`
  max-width: 1000px;
  background-color: #fff;
  margin: 0 auto 2rem;
  padding: 3rem 0;
  border-radius: 20px;
  margin-top: 3rem;

  @media only screen and (max-width: 975px) {
    padding: 2rem;
  }
`;

function TermsContent() {
  return (
    <>
      <ContentContainer fluid>
        <ContentWrapper>
          <TitleCmp title="Overview" />
          <TextCmp
            text="This Terms of Use (this “Agreement”) is an agreement between you and Jessie and same governs your use of all Jessie’s Services. You unconditionally accept and agree to be bound by the terms and conditions in this Terms of Use including the Privacy Policy as well as any applicable laws and guidelines when you access the Jessie’s Platform or use any of the Jessie’s Services. If you disagree with any part hereof, please do not use the Jessie’s Platform or any of the Services offered by Jessie. You should read this Agreement carefully.

"
          />
        </ContentWrapper>
        <WhiteBackground>
          <ContentWrapper>
            <TitleCmpWithStar title="Our Services" />
            <TextCmp
              noMargin
              text="Jessie is a financial technologies services provider and acts as such by creating, hosting, maintaining and providing Jessie’s Services through our website, mobile application or API (altogether referred to as “Jessie’s Platform”).
              Jessie’s Services refers to the services which enable you and other users congregated on the Jessie Platform to –              
"
            />
            <TextCmp
              noMargin
              text="(a) Buy and Sell giftcards such as Steam, Googleplay, iTunes, Amazon, (“giftcard transactions”) via a giftcard trading channel hosted by Jessie;             
"
            />
            <TextCmp
              noMargin
              text="(b) Send, receive, buy and sell cryptocurrency such as bitcoin and Ethereum (“cryptocurrency transactions”) via a cryptocurrency wallet hosted by Jessie;             
"
            />
            <TextCmp
              noMargin
              text="(c) Send, receive, buy and sell Perfect Money (“Perfect Money transactions”) via a Perfect Money Exchange channel hosted by Jessie;          
"
            />
            <TextCmp
              text="(d) pay for bills and services such as airtime, data, electricity, cable TV (“renew transactions) via a fiat wallet hosted by Jessie (altogether referred to as the “Jessie’s Services”)        
"
            />
            <br />
            <TitleCmpWithStar title="Eligibility" />
            <TextCmp
              noMargin
              text="To be eligible to use or access the Jessie Platform and the Jessie Services, the following conditions must be met by you –            
"
            />
            <TextCmp
              noMargin
              text="(a) you must create a Jessie user account on the Jessie Platform;           
"
            />
            <TextCmp
              noMargin
              text="(b)  as an individual, be at least 18 or be of legal age to form a binding contract under applicable laws;          
"
            />
            <TextCmp
              noMargin
              text="(c) as an individual, legal person, or other organization, you have full power, authority and capacity to accept these Terms of Use;      
"
            />
            <TextCmp
              noMargin
              text="(d) as an employee or agent of a legal entity, you must have the full power, authority and capacity to accept these Terms of Use on their behalf and bind such legal entity to these Terms of Use;      
"
            />
            <TextCmp
              noMargin
              text="(e) you have not been previously restricted from using or accessing the Jessie Platform and Jessie Services      
"
            />
            <TextCmp
              text="(f) your use of Jessie Services will not violate any applicable laws and regulations applicable to you, including but not limited to regulations on anti-money laundering, anti-corruption, and counter-terrorist financing.     
"
            />
            <br />
            <TitleCmpWithStar title="KYC Information" />
            <TextCmp
              text="You hereby acknowledge that in order to use or access the Jessie Platform or Jessie Services, you must provide us with correct and updated Personal Information.    
"
            />
            <TextCmp
              text="Personal Information may include –identity data, identity documents, transaction data, and technical data. Your Personal Information shall be properly protected and kept confidential at all times. 
"
            />
            <TextCmp
              noMargin
              text="We may use, process, store and transfer your Personal Information in order to – 
"
            />
            <TextCmp
              noMargin
              text="(a) provide Jessie Services to you; 
"
            />
            <TextCmp
              noMargin
              text="(b) comply with applicable laws and regulatory requirements;
"
            />
            <TextCmp
              noMargin
              text="(c) secure your Jessie user account and maintain data and information security;
"
            />
            <TextCmp
              noMargin
              text="(d) verify the identity details and bank account details which you provide to us;
"
            />
            <TextCmp
              noMargin
              text="(e) protect our vital interests and that of others;
"
            />
            <TextCmp
              noMargin
              text="(f) provide service communications;
"
            />
            <TextCmp
              noMargin
              text="(g) provide customer service; 
"
            />
            <TextCmp
              noMargin
              text="(h) facilitate corporate reorganizations; and
"
            />
            <TextCmp
              text="(i) implement direct marketing campaigns.
"
            />
            <br />
            <TitleCmpWithStar title="Security And Unauthorized Use" />
            <TextCmp
              text="Jessie remains committed to providing adequate data and information security to ensure that the detection and prevention of fraudulent and unauthorized access to Jessie user accounts, however, it is of utmost importance that Jessie Users treat the credentials used to access Jessie Platform as confidential information and avoid the disclosure of same to anybody.
"
            />
            <TextCmp
              text="You hereby agree that you are responsible for maintaining adequate confidentiality and control of any and all credentials that you use to access the Jessie Services or Jessie Platform.
"
            />
            <TextCmp
              text="
              You acknowledge that Jessie shall not in any way be liable for any loss, damage or costs caused by authorized or unauthorized use of your Jessie user account credentials.              
"
            />
            <TextCmp
              text="Jessie will never request for your credentials via email, SMS, phone call or any other means of communications other than the Jessie Platform. If you receive such communication, please disregard and contact us through our customer help desk provided on Jessie’s Platform
              You should immediately notify Jessie if you believe there has been an unauthorized transaction or unauthorized access to your Jessie user account or your password or PIN has been compromised.              
"
            />
            <br />
            <TitleCmpWithStar title="Transaction Cancellation, Modification or Refund" />
            <TextCmp
              text="Users must verify transaction amount or reference numbers where applicable before confirming payments for all transactions made on the Jessie Platform.            
"
            />
            <TextCmp
              text="Payments for all transactions made on the Jessie Platform are non-refundable, irrevocable and cannot be changed after the transaction is confirmed by the user.             
"
            />
            <TextCmp
              text="In the case of a renew transactions, you must directly contact the service provider to amend, adjust or refund payments for erroneous transactions.            
"
            />
            <TextCmp
              text="In the case of cryptocurrency transactions, there shall be no cancellation, reversal or amendment after the transaction has been completed.           
"
            />
            <br />
            <TitleCmpWithStar title="Payment Methods" />
            <TextCmp
              text="Fiat wallets on the Jessie’s Platform may be funded via Electronic Funds Transfer, Mobile Money or Card Payments as applicable for the user’s region.           
"
            />
            <br />
            <TitleCmpWithStar title="Responsibility" />
            <TextCmp
              text="Jessie takes responsibility for all aspects relating to the Jessie Services, customer service and support, and dispute resolution.
"
            />
            <br />
            <TitleCmpWithStar title="Customer Privacy Policy" />
            <TextCmp
              text="Jessie shall take all reasonable steps to protect the personal information of users in accordance with applicable data protection laws and our privacy policy 
"
            />
            <br />
            <TitleCmpWithStar title="No Financial Advice" />
            <TextCmp
              text="All contents published on the Jessie Platform or communicated through Jessie’s social media pages and blogs or by any employee, agent or affiliate of Jessie are for informational or marketing purposes only. No contents or communication may be deemed to constitute professional and/or financial advice.
"
            />
            <TextCmp
              text="Our Website is for informational purposes only and no information contained therein may be deemed to constitute legal advice or an exhaustive statement of the law.
"
            />
            <TextCmp
              text="You are solely responsible for any decision to store, buy or sell cryptocurrency, and such decision should take into account your risk tolerance and financial circumstances. For more information on the risks involved, see Risks and the Risk Warning on the Jessie website. You should consult your legal or tax professional in relation to your specific situation
"
            />
            <br />
            <TitleCmpWithStar title="Prohibited Activities" />
            <TextCmp
              noMargin
              text="During the use of the Jessie Platform or Jessie Services and in the course of your interactions with Jessie, affiliated third parties or other Jessie users, you will not –
"
            />
            <TextCmp
              noMargin
              text="(a) Breach this Terms of Use, any policy and/or any agreement that you have agreed to with Jessie;
"
            />
            <TextCmp
              noMargin
              text="(b) Breach any applicable laws and regulations;
"
            />
            <TextCmp
              noMargin
              text="(c) Engage in or facilitate fraudulent activities;
"
            />
            <TextCmp
              noMargin
              text="(d) Infringe on Jessie or any third party's copyright, patent, trademark, trade secret or other intellectual property rights, or rights of publicity or privacy;
"
            />
            <TextCmp
              noMargin
              text="(e) Sell counterfeit goods and/or product or service that is prohibited by law or is contrary to public order or good moral;
"
            />
            <TextCmp
              noMargin
              text="(f) Act in a manner that is defamatory, trade libelous, threatening or harassing to Jessie , our employees, agents or other Jessie users;
"
            />
            <TextCmp
              noMargin
              text="(g) Provide false, inaccurate or misleading Personal Information;
"
            />
            <TextCmp
              noMargin
              text="(h) Engage in potentially fraudulent or suspicious activity and/or transactions;
"
            />
            <TextCmp
              noMargin
              text="(i) Receive or attempt to receive chargeback from both Jessie and a Merchant, bank or card issuer for the same transaction during the course of a dispute;
"
            />
            <TextCmp
              noMargin
              text="(j) Take any action that imposes an unreasonable or disproportionately large load on our infrastructure; and
"
            />
            <TextCmp
              text="(k) Facilitate any viruses, Trojan horses, worms or other computer programming routines that may damage, detrimentally interfere with, surreptitiously intercept or expropriate any system, data or Personal Information;
"
            />
            <br />
            <TitleCmpWithStar title="Termination and Restriction of Access" />
            <TextCmp
              text="You hereby acknowledge that Jessie reserves the right to restrict access to your Jessie account and/or the termination of this Agreement upon the reasonable suspicion that you have contravened any of the terms and conditions contained in this Terms of Use
"
            />
            <TextCmp
              text="Jessie, in its sole discretion, reserves the right to terminate this Agreement and/or access to the Jessie Services and Jessie Platform for any reason and at any time upon notice and payment to you of any unrestricted funds held in your wallet.
"
            />
            <TextCmp
              text="If we limit access to your Jessie user account, including through the placement of a lien, we will provide you with notice of our actions, and the opportunity to request restoration of access if, in our sole discretion, we deem it appropriate. Further, you acknowledge that Jessie’s decision to take certain actions, including limiting access to your Jessie user account, may be based on confidential criteria that is essential to our management of risk, the security of other Jessie users’ accounts and the Jessie system. You agree that Jessie is under no obligation to disclose the details of its risk management or its security procedures to you.
"
            />
            <br />
            <TitleCmpWithStar title="Disclaimers and Limitation of Liability" />
            <TextCmp
              text="THE JESSIE SERVICES AND PLATFORM ARE PROVIDED “AS IT IS” AND WITHOUT ANY REPRESENTATION OF WARRANTY, WHETHER EXPRESS, IMPLIED OR STATUTORY. JESSIE, OUR PARENT AND AFFILIATES, OUR OFFICERS, DIRECTORS, AGENTS, JOINT VENTURES, EMPLOYEES AND OUR SUPPLIERS SPECIFICALLY DISCLAIM ANY IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.
"
            />
            <TextCmp
              text="Jessie does not have any control over the products or services that are paid for with the Jessie’s Services and Jessie cannot ensure that a Merchant you are dealing with will actually complete the transaction or is authorized to do so. Jessie does not guarantee continuous, uninterrupted or secure access to any part of the Jessie Services, and operation of our site may be temporarily suspended for maintenance or upgrade or interfered with by numerous factors outside of our control. Jessie will make reasonable efforts to ensure that service requests are processed in a timely manner, but Jessie makes no representations or warranties regarding the amount of time needed to complete processing because the Jessie Services are dependent upon many factors outside of our control, such as delays in the banking system or delays by partners and vendors.
"
            />
            <TextCmp
              text="
              To the maximum extent permitted by law, Jessie and its subsidiaries and affiliates, officers, directors, agents, employees or suppliers shall not be liable to any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with any use of, or inability to use of the Jessie Platform, any content displayed on Jessie Platform and any linked third-party websites.              
"
            />
            <br />
            <TitleCmpWithStar title="Rate And Fees" />
            <TextCmp
              text="Before buying or selling any product or services on the Jessie platform you must confirm the current rates
"
            />
            <br />
            <TitleCmpWithStar title="Changes" />
            <TextCmp
              text="Jessie reserves the rights to make changes to this Terms of Use without notice. You are required to visit this page regularly as you continued use of the Jessie Platform or Jessie Services will be deemed as an unconditional acceptance and agreement to be bound by the revised Terms of Use.
"
            />
            <TextCmp
              text="Notwithstanding the foregoing, will do our best to notify you of any changes to the Terms of Use and any such notice will be published on the Jessie Platform by posting same on our website, sending push notifications through on the mobile app or sending an email to the email address associated with your Jessie user account.
"
            />
            <br />
            <TitleCmpWithStar title="Google Analytics" />
            <TextCmp
              text="Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualize and personalize the ads of its own advertising network.
"
            />
            <TextCmp
              text={
                <>
                  For more information on the privacy practices of Google,
                  please visit the Google Privacy Terms web page:&nbsp;
                  <a
                    style={{ wordBreak: "break-word" }}
                    href="https://policies.google.com/privacy?hl=en"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://policies.google.com/privacy?hl=en
                  </a>
                </>
              }
            />
            <TextCmp
              text={
                <>
                  We also encourage you to review the Google's policy for
                  safeguarding your data:&nbsp;
                  <a
                    style={{ wordBreak: "break-word" }}
                    href="https://support.google.com/analytics/answer/6004245"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://support.google.com/analytics/answer/6004245
                  </a>
                  .
                </>
              }
            />
            <br />
            <TitleCmpWithStar title="Acknowledgement" />
            <TextCmp
              text="By using service or other services provided by us, you acknowledge that you have read these terms of service and agree to be bound by them.
"
            />
            <br />
            <TitleCmpWithStar title="Contact Us" />
            <TextCmp
              text="Please send your feedback, comments, requests for technical support:
"
            />
            <TextCmp
              text={
                <>
                  By email:&nbsp;
                  <a
                    href="mailto:hello@jessiepay.co"
                    target="_blank"
                    rel="noreferrer"
                  >
                    hello@jessiepay.co
                  </a>
                  .
                </>
              }
            />
          </ContentWrapper>
        </WhiteBackground>
      </ContentContainer>
    </>
  );
}

export default TermsContent;
