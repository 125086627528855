import * as yup from "yup";

const workWithUsValidatorSchema = yup.object().shape({
  email: yup
    .string()
    .email("It must be a valid email")
    .required("Please enter your email address"),
  skills: yup.string().required("Please highlight your skills"),
  portfolio: yup.string().required("Please highlight your portfolio"),
  file: yup.mixed().required("Please drop your CV"),
});

export default workWithUsValidatorSchema;
