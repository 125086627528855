import {
  CREATE_OTC_ERROR,
  CREATE_OTC_LOADING,
  CREATE_OTC_SUCCESS,
} from "./types";

const initialState = {
  createOtcLoading: null,
  createOtcSuccess: null,
  createOtcError: null,
};

export default function ticketReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_OTC_LOADING:
      return { ...state, createOtcLoading: action.payload };
    case CREATE_OTC_SUCCESS:
      return { ...state, createOtcSuccess: action.payload };
    case CREATE_OTC_ERROR:
      return { ...state, createOtcError: action.payload };
    default:
      return state;
  }
}
