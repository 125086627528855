import newsReducer from "./newsReducer";
import ticketReducer from "./ticketReducer";
import faqReducer from "./faqReducer";
import otcReducer from "./otcReducer";
import feedbackReducer from "./feedbackReducer";
import newsletterReducer from "./newsletterReducer";

const rootReducer = {
  news: newsReducer,
  ticket: ticketReducer,
  faq: faqReducer,
  otc: otcReducer,
  feedback: feedbackReducer,
  newsletter: newsletterReducer,
};

export default rootReducer;
