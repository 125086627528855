import React from "react";
import NavbarCmp from "../components/Navbar/NavbarCmp";
import PageHeaderCmp from "../components/PageHeaderCmp/PageHeaderCmp";
import CareerSubImg from "../assets/careersub-vector.svg";
import TransactionsCmp from "../components/TransactionsCmp/TransactionsCmp";
import NewsletterCmp from "../components/NewsletterCmp/NewsletterCmp";
import FooterCmp from "../components/FooterCmp/FooterCmp";

function Transaction() {
  return (
    <>
      <NavbarCmp colored />
      <PageHeaderCmp
        padding="10rem"
        title="The OTC desk is safe, secure and for you!"
        description="For personalized and confidential service for large crypto block trades (₦ 5,000 and above) simply submit your trade request by filling the form below and we will get back to you as soon as we can!"
        backColor="rgba(24, 153, 60, 0.1)"
        backImage={CareerSubImg}
        width="50%"
      />
      <TransactionsCmp />
      <NewsletterCmp backColor="#f9fafb" />
      <FooterCmp />
    </>
  );
}

export default Transaction;
