import React from "react";
import styled from "styled-components";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import appTheme from "../../constants/theme";

const Wrapper = styled.div`
  max-height: 380px;
  max-width: 380px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 0;

  @media only screen and (max-width: 991px) {
    margin-bottom: 2rem;
  }

  @media only screen and (max-width: 370px) {
    margin-bottom: 4rem;
  }
`;

const ImgWrapper = styled.div`
  margin: 0;
  padding: 0;
  height: 170px;
`;

const ImgTop = styled(LazyLoadImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 36px 36px 0px 0px;
`;

const ContentWrapper = styled.div`
  padding: 1rem 1.5rem 2rem;
  background-color: ${appTheme.COLORS.white};
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.13);
  border-radius: 0px 0px 27px 27px;
  height: calc(380px - 170px);

  @media only screen and (max-width: 370px) {
    height: fit-content;
  }
`;

const NewsTitle = styled.h2`
  font-size: ${appTheme.SIZES.verysmallTitle};
  color: ${appTheme.COLORS.grey1};
  font-weight: ${appTheme.WEIGHT.medium};
`;

const NewsText = styled.p`
  font-weight: ${appTheme.WEIGHT.small2};
  color: #374151;
  font-size: 15px;
  margin-bottom: 1.5rem;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  padding-top: 0.5rem;
`;

const InfoText = styled.p`
  font-weight: ${appTheme.WEIGHT.small3};
  font-size: 11px;
  color: #1d1e2c;
  margin-bottom: 0.5rem;
`;

const Dot = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin: 0 0.5rem;
  background-color: #016cba;
`;

function NewsCardCmp(props) {
  return (
    <>
      <Wrapper>
        <ImgWrapper>
          <ImgTop
            src={props.image}
            alt={props.title}
            effect="black-and-white"
          />
        </ImgWrapper>
        <ContentWrapper>
          <InfoWrapper>
            {props.slugs.map((v, i) => {
              return (
                <>
                  <InfoText key={v}>{v}</InfoText>
                  {i < props.slugs.length - 1 && <Dot />}
                </>
              );
            })}
          </InfoWrapper>
          <NewsTitle>{props.title}</NewsTitle>
          <NewsText>{props.desc}</NewsText>
        </ContentWrapper>
      </Wrapper>
    </>
  );
}

export default NewsCardCmp;
