import React from "react";
import FooterCmp from "../components/FooterCmp/FooterCmp";
import NavbarCmp from "../components/Navbar/NavbarCmp";
import NewsletterCmp from "../components/NewsletterCmp/NewsletterCmp";
import PageHeaderCmp from "../components/PageHeaderCmp/PageHeaderCmp";
import TermsContent from "../components/TermsContentCmp/TermsContent";

function Terms() {
  return (
    <>
      <NavbarCmp type="color" />
      <PageHeaderCmp
        title="Terms Of Service"
        backColor="rgba(208, 218, 228, 0.2)"
      />
      <TermsContent />
      <NewsletterCmp backColor="#f9fafb" />
      <FooterCmp />
    </>
  );
}

export default Terms;
