import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import BackCmp from "../BackCmp/BackCmp";
import TextCmp from "../TextCmp/TextCmp";
import TitleCmp from "../TitleCmp/TitleCmp";

const ContentContainer = styled(Container)`
  background-color: #f9fafb;
  padding: 4rem 3rem;
  text-align: left;

  @media only screen and (max-width: 600px) {
    padding: 3rem 2rem;
  }
`;

const ContentWrapper = styled.div`
  max-width: 850px;
  margin: 0 auto;
`;

function CareerSubCmp() {
  return (
    <>
      <ContentContainer fluid>
        <ContentWrapper>
          <BackCmp text="Back to all jobs" />
          <TitleCmp title="Job Summary" />
          <TextCmp text="The Product Manager is responsible for the product planning and execution throughout the Product Lifecycle, including: gathering and prioritizing product and customer requirements, defining the product vision, and working closely with engineering, marketing and Customer support to ensure revenue and customer satisfaction goals are met. The Product Manager's job also includes ensuring that the product supports the company's overall strategy and goals." />
          <br />
          <TitleCmp title="Job Expectation" />
          <TextCmp text="The following are the job details for the role" />
          <TextCmp text="• Translate business strategy into product strategy." />
          <TextCmp text="• Define and own the product strategy document." />
          <TextCmp text="• Execute product strategy" />
          <TextCmp text="• Deliver MRDs and PRDs with Prioritized features and corresponding Justification." />
          <TextCmp text="• Run beta and pilot programs with early-stage products and Drive action throughout the organization to get products to market" />
          <TextCmp text="• Plan and carry out product launches Provide product marketing with insights on key differ entiators and messages to stakeholders on the product and market" />
          <TextCmp text="• Write high-level requirements " />
          <TextCmp text="• Work with external third parties to assess partnerships and licensing opportunities." />
          <TextCmp text="• Support the product Owner and Product marketer with any questions they may have" />
          <TextCmp text="• Manage all aspects of in-life products, including customer feedback, requirements, andissues." />
          <TextCmp text="• Manage Product profitability and commercial success- Own the business case" />
          <br />
          <TitleCmp title="Job Requirements" />
          <TextCmp text="• University degree in any field" />
          <TextCmp text="• A deep understanding of Blockchain and the Global Crypto Exchange market" />
          <TextCmp text="• Previous experience (at least 3 years) in a Product manager role for a Crypto Exchangebusiness" />
          <TextCmp text="• Highly Accountable and high level of responsibility" />
          <TextCmp text="• Advanced analytical, interpersonal and people management skills" />
          <TextCmp text="• Solid technical background with understanding and/or hands-on experience in software development and web technologies" />
          <TextCmp text="• Strong problem-solving skills and willingness to roll up one's sleeves to get the job done" />
          <br />
          <TitleCmp title="Work Benefits" />
          <TextCmp text="• Competitive Salary." />
          <TextCmp text="• Performance Bonuses." />
          <TextCmp text="• Full Medical Insurance." />
          <TextCmp text="• Paid Leave" />
          <TextCmp text="• Flexible Hours" />
          <TextCmp text="• Work From Anywhere" />
        </ContentWrapper>
      </ContentContainer>
    </>
  );
}

export default CareerSubCmp;
