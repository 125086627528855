import React from "react";
import NavbarCmp from "../components/Navbar/NavbarCmp";
import PageHeaderCmp from "../components/PageHeaderCmp/PageHeaderCmp";
import CareerSubImg from "../assets/careersub-vector.svg";
import NewsletterCmp from "../components/NewsletterCmp/NewsletterCmp";
import FooterCmp from "../components/FooterCmp/FooterCmp";
import DareDifferentCmp from "../components/DareDifferentCmp/DareDifferentCmp";
import TeamDifferent from "../components/TeamDifferentCmp/TeamDifferent";
import TeamQualitiesSctn from "../components/TeamQualitiesSctn/TeamQualitiesSctn";
import JoinCmp from "../components/JoinCmp/JoinCmp";
import JobBenefitCmp from "../components/JobBenefitCmp/JobBenefitCmp";
import WorkWithUsCmp from "../components/WorkWithUsCmp/WorkWithUsCmp";

function Career() {
  return (
    <>
      <NavbarCmp colored />
      <PageHeaderCmp
        padding="10rem"
        title="Let's make a difference"
        description="We are a fast-growing startup and always on the lookout for talented individuals to join our growing team."
        backColor="rgba(208, 218, 228, 0.2)"
        backImage={CareerSubImg}
      />
      <DareDifferentCmp />
      <JobBenefitCmp />
      <TeamDifferent />
      <TeamQualitiesSctn />
      <JoinCmp />
      <WorkWithUsCmp title="Interested in working with us?" />
      <NewsletterCmp backColor="#f9fafb" />
      <FooterCmp />
    </>
  );
}

export default Career;
