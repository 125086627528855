import React from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import SingleFeatureCmp from "../SingleFeatureCmp/SingleFeatureCmp";
import Briefcase from "../../assets/briefcase.svg";
import Health from "../../assets/health.svg";
import Money from "../../assets/money.svg";
import Luggage from "../../assets/luggage.svg";
import Naira from "../../assets/naira.svg";
import Timer from "../../assets/timer.svg";

const BenefitContainer = styled(Container)`
  padding: 2rem 5rem 2rem;
  background-color: #fff;

  @media only screen and (max-width: 767px) {
    padding: 1rem 4rem 1rem;
  }
`;

const BenefitWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;

  /* @media only screen and (max-width: 760px) {
    flex-direction: column;
  } */

  /* @media only screen and (max-width: 424px) {
    padding: 3rem 2rem;
  } */
`;

const ItemHolder = styled.div`
  flex-basis: 33%;

  @media only screen and (max-width: 790px) {
    flex: 50%;
  }

  @media only screen and (max-width: 560px) {
    flex: 100%;
  }
`;

function JobBenefitCmp() {
  return (
    <>
      <BenefitContainer fluid>
        <BenefitWrapper>
          <ItemHolder>
            <SingleFeatureCmp
              type="careers"
              size="50px"
              icon={Briefcase}
              circleColor="rgba(12, 58, 147, 0.3)"
              title="Work from anywhere"
              desc="Most of our team works remote. Nevertheless, you are also always welcome in our office."
            />
          </ItemHolder>
          <ItemHolder>
            <SingleFeatureCmp
              type="careers"
              size="50px"
              icon={Timer}
              circleColor="rgba(251, 168, 46, 0.3)"
              title="Flexible hours"
              desc="Work whenever it suits your personal schedule. For most positions we don't have any fixed hours."
            />
          </ItemHolder>
          <ItemHolder>
            <SingleFeatureCmp
              type="careers"
              size="50px"
              icon={Naira}
              circleColor="rgba(63, 209, 139, 0.1)"
              title="Competitive salary"
              desc="Naturally, we offer fair salaries, always paid on time."
            />
          </ItemHolder>
          <ItemHolder>
            <SingleFeatureCmp
              type="careers"
              size="50px"
              icon={Luggage}
              circleColor="rgba(12, 124, 202, 0.3)"
              title="Paid leave"
              desc="Away from work for holiday, illness, etc? You still receive normal pay."
            />
          </ItemHolder>
          <ItemHolder>
            <SingleFeatureCmp
              type="careers"
              size="50px"
              icon={Health}
              circleColor="rgba(222, 83, 72, 0.2)"
              title="Health insurance"
              desc="Adequate health insurance is of utmost importance, Everyone at Jessie get free medical care."
            />
          </ItemHolder>
          <ItemHolder>
            <SingleFeatureCmp
              type="careers"
              size="50px"
              icon={Money}
              circleColor="rgba(82, 67, 170, 0.3)"
              title="Performance Bonus"
              desc="Every team member gets an additional compensation for achieving specific goals or hitting predetermined targets."
            />
          </ItemHolder>
        </BenefitWrapper>
      </BenefitContainer>
    </>
  );
}

export default JobBenefitCmp;
