import React, { useState } from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import appTheme from "../../constants/theme";
import SolidButton from "../Buttons/SolidButton";
import CustomFileCmp from "../InputsCmp/CustomFileCmp";
import CustomInputCmp from "../InputsCmp/CustomInputCmp";
import CustomTextAreaCmp from "../InputsCmp/CustomTextAreaCmp";
import { Element } from "react-scroll";
import { Formik } from "formik";
import createTicketSchema from "../../validators/createTicketValidator";
import { createTicket } from "../../redux/actions/ticketAction";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import SpinnerCmp from "../../components/SpinnerCmp/SpinnerCmp";

const ResourcesContainer = styled(Container)`
  background: rgba(225, 235, 245, 0.3);
  padding: 5rem 0 3rem;
  text-align: center;

  @media only screen and (max-width: 600px) {
    padding: 3rem 0;
  }
`;

const CreateTicketContainer = styled.div`
  background-color: #fff;
  margin: 0 auto;
  max-width: 900px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 30px;
  padding: 0 1rem;
`;

const TicketWrapper = styled.div`
  max-width: 500px;
  margin: 0 auto;
  padding-bottom: 2rem;
`;

const TicketTitle = styled.h2`
  color: ${appTheme.COLORS.grey1};
  font-size: 2rem;
  font-weight: ${appTheme.WEIGHT.mediumBold};
  margin: 0;
  padding: 3rem 0;
`;

const InputWrapper = styled.div`
  padding-bottom: 1.5rem;
  text-align: left;
`;

const InputLabel = styled.h2`
  font-size: 14px;
  font-weight: ${appTheme.WEIGHT.small2};
  color: ${appTheme.COLORS.grey1};
`;

const ButtonWrapper = styled.div`
  text-align: right;
`;

function CreateTicketCmp({ setCreateTicket, loading }) {
  const [, setImagePreview] = useState();
  return (
    <>
      <Element name="createTicket">
        <ResourcesContainer fluid>
          <CreateTicketContainer>
            <TicketWrapper>
              <TicketTitle>Create a ticket</TicketTitle>
              <Formik
                validationSchema={createTicketSchema}
                initialValues={{
                  transactionId: "",
                  email: "",
                  issue: "",
                  file: null,
                }}
                onSubmit={async (values, { resetForm }) => {
                  let formData = new FormData();
                  formData.append(
                    "ticketImage",
                    values.file[0],
                    values.file[0].name
                  );
                  formData.append("email", values.email);
                  formData.append("description", values.issue);
                  formData.append("txnId", values.transactionId);

                  const response = await setCreateTicket(formData);

                  if (
                    response &&
                    response.status === 201 &&
                    response.message === "Ticket created"
                  ) {
                    resetForm({
                      transactionId: "",
                      email: "",
                      issue: "",
                      file: null,
                    });
                    setImagePreview();
                    return toast.success(
                      "Your complaint has been submitted and a representative will get to it right away",
                      { toastId: "Ticket created" }
                    );
                  }
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  values,
                  errors,
                  setFieldValue,
                }) => (
                  <>
                    <form noValidate onSubmit={handleSubmit}>
                      <TicketWrapper>
                        <InputWrapper>
                          <InputLabel>Transaction ID</InputLabel>
                          <CustomInputCmp
                            background="#fff"
                            required={true}
                            type="text"
                            name="transactionId"
                            placeholder="Enter Transaction ID"
                            values={values}
                            onChange={handleChange}
                            message={`${
                              errors["transactionId"]
                                ? errors["transactionId"]
                                : ""
                            }`}
                            showMessage={true}
                          />
                        </InputWrapper>
                        <InputWrapper>
                          <InputLabel>Email Address</InputLabel>
                          <CustomInputCmp
                            background="#fff"
                            required={true}
                            type="email"
                            name="email"
                            placeholder="Enter your email address"
                            values={values}
                            onChange={handleChange}
                            message={`${
                              errors["email"] ? errors["email"] : ""
                            }`}
                            showMessage={true}
                          />
                        </InputWrapper>
                        <InputWrapper>
                          <InputLabel>
                            Tell us more about this transaction
                          </InputLabel>
                          <CustomTextAreaCmp
                            background="#fff"
                            as="textarea"
                            rows={4}
                            required={true}
                            type="text"
                            name="issue"
                            placeholder="Describe the problem"
                            values={values}
                            onChange={handleChange}
                            message={`${
                              errors["issue"] ? errors["issue"] : ""
                            }`}
                            showMessage={true}
                          />
                        </InputWrapper>
                        <InputWrapper>
                          <InputLabel>Upload an attachment</InputLabel>
                          <CustomFileCmp
                            maxFiles={1}
                            maxSize={1000000}
                            accept={"image/jpeg, image/png, image/jpeg"}
                            customFn={(blob) => {
                              setImagePreview(blob);
                            }}
                            type="text"
                            placeholder="Click to upload file"
                            values={values}
                            setFieldValue={setFieldValue}
                            name="file"
                            showMessage
                            message={`${errors["file"] ? errors["file"] : ""}`}
                          />
                        </InputWrapper>
                        <ButtonWrapper>
                          <SolidButton
                            text={
                              loading ? (
                                <>
                                  <SpinnerCmp
                                    enabled={true}
                                    color={appTheme.COLORS.bluePrimary}
                                    size={20}
                                    secondaryColor={appTheme.COLORS.white}
                                  />
                                </>
                              ) : (
                                "Submit Report"
                              )
                            }
                            margin="2rem 0"
                            type="submit"
                            weighty="500"
                            textFontSize="16px"
                            specifyPadding="1rem 1.5rem"
                            color="#374151"
                            borderRadius="8px"
                            backColor="#FFB140"
                            backgroundHoverColor="#FFB140"
                            textHoverColor="#374151"
                            widthWebkit="100%"
                            widthMoz="100%"
                            widthNormal="100%"
                            maxWidth="170px"
                            disabled={loading ? true : false}
                          />
                        </ButtonWrapper>
                      </TicketWrapper>
                    </form>
                  </>
                )}
              </Formik>
            </TicketWrapper>
          </CreateTicketContainer>
        </ResourcesContainer>
      </Element>
    </>
  );
}

const mapState = (state) => ({
  loading: state.ticket.createTicketLoading,
});

const mapDispatch = (dispatch) => ({
  setCreateTicket: (data) => dispatch(createTicket(data)),
});

export default connect(mapState, mapDispatch)(CreateTicketCmp);
