import React from "react";
import NavbarCmp from "../components/Navbar/NavbarCmp";
import PageHeaderCmp from "../components/PageHeaderCmp/PageHeaderCmp";
import CareerSubImg from "../assets/careersub-vector.svg";
import NewsletterCmp from "../components/NewsletterCmp/NewsletterCmp";
import FooterCmp from "../components/FooterCmp/FooterCmp";
import CreateTicketCmp from "../components/CreateTicketCmp/CreateTicketCmp";
import ResourcesCmp from "../components/ResourcesCmp/ResourcesCmp";
import ChatCmp from "../components/ChatCmp/ChatCmp";

function Resources() {
  return (
    <>
      <ChatCmp />
      <NavbarCmp colored />
      <PageHeaderCmp
        padding="10rem"
        title="How can we help you?"
        description="Get answers and explore everything you need to know about your question at your fingertips."
        backColor="rgba(251, 168, 46, 0.1)"
        backImage={CareerSubImg}
        width="50%"
      />
      <ResourcesCmp />
      <CreateTicketCmp />
      <NewsletterCmp backColor="rgba(225, 235, 245, 0.3)" />
      <FooterCmp />
    </>
  );
}

export default Resources;
