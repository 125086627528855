const role = [
  {
    name: "Product Manager",
    attr: ["Full Time", "Remote Possible"],
    url: "/careers/1",
  },
  {
    name: "Marketing Specialist",
    attr: ["Full Time", "Remote Possible"],
    url: "/careers/1",
  },
  {
    name: "Head of Compliance",
    attr: ["Full Time", "Remote Possible"],
    url: "/careers/1",
  },
  {
    name: "Article write",
    attr: ["Full Time", "Remote Possible"],
    url: "/careers/1",
  },
];

export default role;
