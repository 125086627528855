import axios from "axios";
import { toast } from "react-toastify";
import { apiBaseUrl } from "../../constants/url";
import { CREATE_TICKET_ERROR, CREATE_TICKET_LOADING } from "../reducers/types";

export const createTicket = (data) => async (dispatch) => {
  dispatch({ type: CREATE_TICKET_LOADING, payload: true });
  dispatch({ type: CREATE_TICKET_ERROR, payload: null });
  try {
    const response = await axios.post(`${apiBaseUrl}/ticket/add-ticket`, data);
    dispatch({ type: CREATE_TICKET_LOADING, payload: null });

    if (response.data.status !== 201) {
      return toast.error(response.data.message);
    }
    return response.data;
  } catch (err) {
    dispatch({ type: CREATE_TICKET_LOADING, payload: null });

    if (err.response.status === 422) {
      return toast.error(`Validation error`);
    } else {
      return toast.error(err.response?.data?.message);
    }
  }
};
