import { Container } from "react-bootstrap";
import styled from "styled-components";
import TextCmp from "../TextCmp/TextCmp";
import TitleCmp from "../TitleCmp/TitleCmp";
import TitleCmpWithStar from "../TitleCmp/TitleCmpWithStar";

const ContentContainer = styled(Container)`
  background-color: #f9fafb;
  padding: 4rem 3rem;
  text-align: left;

  @media only screen and (max-width: 600px) {
    padding: 3rem 2rem;
  }
`;

const ContentWrapper = styled.div`
  max-width: 850px;
  margin: 0 auto;
`;

const WhiteBackground = styled.div`
  max-width: 1000px;
  background-color: #fff;
  margin: 0 auto 2rem;
  padding: 3rem 0;
  border-radius: 20px;
  margin-top: 3rem;

  @media only screen and (max-width: 975px) {
    padding: 2rem;
  }
`;

function PolicyContent() {
  return (
    <>
      <ContentContainer fluid>
        <ContentWrapper>
          <TitleCmp title="Overview" />
          <TextCmp
            text="Money Laundering and Terrorist Financing is the process in which assets obtained or generated through criminal activities are moved or concealed to obscure their link with the crime. Perpetrators of the crime find ways to launder the funds in order to use them without drawing the attention of the applicable authorities. Money Laundering empowers corruption and organized crime where corrupt public officials and criminals can launder proceeds from crimes, bribes, kickbacks, public funds and on some occasion, even development loans from international financial institutions. Organized criminal groups want to be able to launder the proceeds of drug trafficking and commodity smuggling through the financial systems without a trace. In the modern-day definition, Money Laundering now covers various predicate offences including child trafficking, prostitution, etc.
            "
          />
          <TextCmp
            text="The degree of sophistication and complexity in the money laundering scheme is infinite and is limited only by the creative imagination and expertise of criminals. Terrorist activities are sometimes funded from the proceeds of illegal activities. Although often linked in legislation and regulation, terrorist financing and money laundering are conceptual opposites. Money laundering is the process where cash raised from criminal activities is made to look legitimate for re-integration into the financial system, whereas terrorist financing cares little about the source of the funds, but it is what the funds are to be used for that defines its scope.
            "
          />
          <TextCmp
            text="
            In recent years, the international community has become more aware of the dangers that money laundering and terrorist financing poses in all these areas, and many governments and jurisdictions have committed themselves to acting. The United Nations and the other international organizations like Financial Action Task Force (FATF) are committed to helping governments in any way they can.
            "
          />
          <TextCmp
            text="
            JESSIEDOTCOMDOTNG LIMITED is committed to fighting money laundering and complying fully with anti-money laundering laws in Nigeria. We understand that we have responsibilities to help fight the global battle against money laundering and our commitment will supersede all other privacy obligations contained in our policies. Accordingly, Jessiedotcomdotng Limited will take all reasonable and appropriate steps to prevent persons engaged in money laundering, fraud, or other financial crimes from utilizing our products and services. Our AML policies, procedures and internal controls are designed to ensure compliance with all applicable BSA regulations and FINRA rules and will be reviewed and updated on a regular basis to ensure appropriate policies, procedures and internal controls are in place to account for both changes in regulations and changes in our business.
            "
          />
        </ContentWrapper>
        <WhiteBackground>
          <ContentWrapper>
            <TitleCmpWithStar title="Scope of Our AML/CFT Policy" />
            <TextCmp text="We adopt a risk-based approach to mitigating the Money Laundering and Terrorism Financing (ML/TF) risks to ensure Jessie is adequately protected from being used as a conduit or facilitator of ML/TF. As a mode of enforcement, the company reserves the right to take disciplinary action for non-compliant employees or contractors as provisions set out in this Policy must always be complied with." />
            <TextCmp
              noMargin
              text="The three major areas within the scope of Compliance are –"
            />
            <TextCmp
              span="1. Organizational related risk:"
              text="This includes risk in relation to non-compliance with organizational conflicts of interest rules, market abuse rules and insider trading rules, intermediary risks, business partner risks, non-licensed business activities risk etc.
"
            />
            <TextCmp
              span="2. Financial Services conduct related risk:"
              text="This includes addressing risks in relation to potential noncompliance with regulatory requirements of both local and international financial services regulators, as well as any other relevant laws and regulations, internal company or business rules and policies, and relevant principles and values. Risks in relation to relationship with regulators.
"
            />
            <TextCmp
              span="3. Client-related, or Financial & Economic Crime risk:"
              text="This includes risks in relation to potential noncompliance with Money Laundering and Terrorist Financing laws, antifraud regulations, and regulations in relation to politically and financially exposed persons. Regulations in relation to import and export control regulations, transactions through sanctioned countries etc.
"
            />
            <TextCmp
              text="There is an independent AML/CTF Compliance function at Jessie which shall be executed and managed by a designated Compliance Officer, who will regularly update management and board of directors on all material issues.
"
            />
            <TextCmp
              text="
              The AML/CTF Policy encompasses, but is not limited to the following activities:              
"
            />
            <TextCmp
              noMargin
              text="(a) Know Your Customer (identification requirements),"
            />
            <TextCmp
              noMargin
              text="(b) Customer Due Diligence (verification procedures),
              "
            />
            <TextCmp
              noMargin
              text="(c) Risk identification and assessment - defining the scope of eligible and not eligible activities,"
            />
            <TextCmp noMargin text="(d) Transactional monitoring, " />
            <TextCmp noMargin text="(e) PEP and Sanctions screening, " />
            <TextCmp
              text="(f) Record keeping, training and other pertinent aspects, as required by applicable laws and regulations.
              "
            />
            <br />
            <TitleCmpWithStar title="Know Your Customer Procedures" />
            <TextCmp
              text="Establishing and maintaining a risk-based approach to Customer Due Diligence (CDD), including customer identification, verification and KYC procedures. To ensure we meet these standards, our customers are required to provide certain personal details when opening a Jessie’s account and generate tokens, OTP and PIN for any withdrawal request. The nature, and extent, of what is required is guided by the customer’s deposit and withdrawal limits, account to be used and in some cases, the customer’s country of residence.
              "
            />
            <TextCmp
              text="In certain circumstances, Jessie may perform enhanced due diligence procedures for customers presenting a higher risk, such as those transacting large volumes etc. Maintaining appropriate KYC records for the minimum prescribed periods.
              "
            />
            <TextCmp
              text="Jessie shall ensure timely and accurate rendition of all AML/CFT returns as specified in the SEC AML/CFT Rules and Regulations as well as other relevant Regulations/Act/Guidelines/Circulars that may be issued from time to time by various government agencies. We shall exercise due diligence in identifying and reporting a suspicious transaction.
              "
            />
            <TextCmp
              noMargin
              text="
              Suspicious transactions shall include: 
              "
            />
            <TextCmp
              noMargin
              text="
              • Transactions which are structured to avoid reporting and record keeping requirements.
              "
            />
            <TextCmp
              noMargin
              text="
              • Altered or false identification or inconsistent information or any transaction involving criminal activity in Jessie’s view
              "
            />
            <TextCmp
              text="
              • Entity that belongs to a person or organization considered as terrorist.
              "
            />
            <br />
            <TitleCmpWithStar title="Internal Control" />
            <TextCmp
              text="
              Jessie has formulated and implemented internal controls and other procedures that will deter criminals from using its facilities for money laundering and terrorist financing and to ensure that its obligations under subsisting laws and regulations are met.
              "
            />
            <br />
            <TitleCmpWithStar title="Risk Assessment" />
            <TextCmp
              text="
              Jessie identifies and assess the money laundering and terrorism financing risks that may be associated with its unique business, services, and customers. More specifically, Jessie shall carry out a business-wide ML/TF risk assessment on a yearly basis, assessing the risks to which Jessie is exposed as a result of the nature and complexity of its business; and assess the risks to which Jessie is exposed as a result of entering a business relationship or carrying out a particular transaction, on a continuous basis.
              "
            />
            <TextCmp
              text="
              Each risk assessment will consist of two separate, but related steps which are identification of the ML/TF risk factors, and assessment of any such factors.
              "
            />
            <TextCmp
              text="
              Jessie performs its annual risk assessment, where AML/CTF risks are fully addressed. Based on the outcome of risk assessment, an appropriate compliance plan is developed and executed.
              "
            />
            <TextCmp
              noMargin
              text="
              Also. Jessie will identify and assess the money laundering and terrorism financing risks that may arise in relation to –
              "
            />
            <TextCmp
              noMargin
              text="
              (a) New products and business practices as well as new service delivery channels.
              "
            />
            <TextCmp
              noMargin
              text="
              (b) The adoption of new technologies for both new and pre-existing products.
            "
            />
            <TextCmp
              text="
              (c) New AML regulations and how they impact new and existing products and services.
          "
            />
            <TextCmp
              text="
              Risk Assessment shall be done prior to the launch or use of such products, practices, and technologies, where applicable, paying attention to products or practices that favor anonymity.
          "
            />
            <br />
            <TitleCmpWithStar title="Applicable Laws, Regulations and Standards" />
            <TextCmp
              text="
              Jessie complies with all applicable laws and regulations (hereinafter “AML/CTF Applicable Laws”), and, AML laws and regulations, FATF Recommendations and additional local AML regulations, as required.
          "
            />
            <br />
            <TitleCmpWithStar title="Awareness and Training" />
            <TextCmp
              text="
              Jessie implements appropriate training and awareness for all its employees on AML/CFT. We provide awareness to our customers through newsletters, flash-news, online and in-person training sessions etc. These trainings and awareness take cognizance of emerging trends, regulations and standards on ML/FT risk.
          "
            />
          </ContentWrapper>
        </WhiteBackground>
      </ContentContainer>
    </>
  );
}

export default PolicyContent;
