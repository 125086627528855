import React from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import CounterCmp from "../CounterCmp/CounterCmp";

const CounterContainer = styled(Container)`
  background: #fff;
  padding: 3rem 0 6rem;
  text-align: center;

  @media only screen and (max-width: 600px) {
    padding-top: 2rem;
  }
`;

const Wrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const EachCounter = styled.div`
  margin: 1rem;
`;

function CounterRowCmp() {
  const stat = [
    {
      stat: "320",
      suffix: "K",
      backColor: "#F6F6FB",
      desc: "Registered Users",
      duration: "3",
    },
    {
      stat: "5",
      suffix: "K",
      backColor: "#FBF9F7",
      desc: "Daily active Users",
      duration: "5",
    },
    {
      stat: "13",
      suffix: "K",
      backColor: "#FDF4F4",
      desc: "Daily transactions",
      duration: "4",
    },
    {
      stat: "15",
      suffix: "+",
      backColor: "#FAF8FB",
      desc: "Countries Serviced",
      duration: "4",
    },
  ];
  return (
    <>
      <CounterContainer>
        <Wrapper>
          {stat.map((v, i) => {
            return (
              <EachCounter>
                <CounterCmp
                  stat={v.stat}
                  duration={v.duration}
                  desc={v.desc}
                  suffix={v.suffix}
                  backColor={v.backColor}
                />
              </EachCounter>
            );
          })}
        </Wrapper>
      </CounterContainer>
    </>
  );
}

export default CounterRowCmp;
