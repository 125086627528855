import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import store from "./redux/store";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import Career from "./pages/Career";
import CareerSub from "./pages/CareerSub";
import Home from "./pages/Home";
import About from "./pages/About";
import Policy from "./pages/Policy";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Transaction from "./pages/Transaction";
import Resources from "./pages/Resources";
import Market from "./pages/Market";
import Feedback from "./pages/Feedback";
import Blog from "./pages/Blog";
import Post from "./pages/Post";
import Faq from "./pages/Faq";

function App() {
  return (
    <>
      <Provider store={store}>
        <ToastContainer />
        <Router>
          <ScrollToTop>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/otc" element={<Transaction />} />
              <Route path="/market" element={<Market />} />
              <Route path="/privacy-policy" element={<Privacy />} />
              <Route path="/terms-of-service" element={<Terms />} />
              <Route path="/resources" element={<Resources />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/post/:slug" element={<Post />} />
              <Route path="/faq" element={<Faq />} />
              <Route path="/feedback" element={<Feedback />} />
              <Route
                path="/anti-money-laundering-policy"
                element={<Policy />}
              />
              <Route path="/careers" element={<Career />} />
              <Route path="/careers/1" element={<CareerSub />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </ScrollToTop>
        </Router>
      </Provider>
    </>
  );
}

export default App;
