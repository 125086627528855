import { Facebook, Linkedin, Twitter } from "@styled-icons/boxicons-logos";
import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import appTheme from "../../constants/theme";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { ArrowBack, ArrowForward } from "@styled-icons/evaicons-solid";
import { convertFromRaw } from "draft-js";
import RenderEditorCmp from "../InputsCmp/RenderEditorCmp";
import {
  TwitterShareButton,
  LinkedinShareButton,
  FacebookShareButton,
} from "react-share";

const BlogContainer = styled(Container)`
  background: rgba(225, 235, 245, 0.3);
  padding: 5rem 0 5rem;

  @media only screen and (max-width: 600px) {
    padding: 4rem 0;
  }
`;

const Wrapper = styled.div`
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

const BlogHeadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 1rem;
  margin: 0 2rem;
  align-items: center;

  @media only screen and (max-width: 420px) {
    margin: 0 1rem;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (max-width: 360px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Title = styled.h2`
  font-size: 16px;
  font-weight: 500;
  color: ${appTheme.COLORS.grey1};
  margin: 0;
  padding-right: 10px;

  @media only screen and (max-width: 500px) {
    font-size: 14px;
  }

  @media only screen and (max-width: 450px) {
    font-size: 12px;
  }

  @media only screen and (max-width: 375px) {
    font-size: 11px;
  }

  @media only screen and (max-width: 360px) {
    padding: 0;
    font-size: 13px;
  }
`;

const DateTxt = styled.h2`
  font-weight: 500;
  font-size: 16px;
  color: #c4c4c4;
  margin: 0;

  @media only screen and (max-width: 500px) {
    font-size: 14px;
  }

  @media only screen and (max-width: 450px) {
    font-size: 12px;
  }

  @media only screen and (max-width: 375px) {
    font-size: 11px;
  }

  @media only screen and (max-width: 360px) {
    padding: 0;
    font-size: 13px;
  }
`;

const DateSpan = styled.span`
  padding-right: 10px;

  @media only screen and (max-width: 360px) {
    display: none;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const IconContainer = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #adb5bd;
  background-color: #fff;
  cursor: pointer;
  margin: 0 0.5rem 0.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContentWrapper = styled.div`
  margin: 0 2rem;

  @media only screen and (max-width: 420px) {
    margin: 0 1rem;
  }
`;

const BlogImage = styled(LazyLoadImage)`
  padding-bottom: 2rem;
  background-size: cover;
  width: 100%;
`;

const PostWrapper = styled.div``;

// const PostText = styled.p`
//   color: ${appTheme.COLORS.grey1};
//   font-size: 1rem;
//   font-weight: 300;
// `;

const Line = styled.hr`
  width: 100%;
  height: 1px;
  color: #adb5bd;
  background-color: #adb5bd;
  margin: 2rem 0;
`;

const OtherWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @media only screen and (max-width: 695px) {
    justify-content: center;
  }
`;

const OtherNews = styled.div`
  width: 300px;
  height: 80px;
  cursor: pointer;
  border-radius: 6px;
  background: rgba(159, 199, 229, 0.38);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  margin: 0.5rem;
`;

const OtherNewsText = styled.h2`
  color: ${appTheme.COLORS.grey1};
  font-weight: 300;
  font-size: 15px;
  max-width: 200px;
  margin: 0;
`;

function PostCmp({ data }) {
  const dateStr = new Date(data.news?.updatedAt);
  return (
    <>
      <BlogContainer fluid>
        <Wrapper>
          <BlogHeadingWrapper>
            <TitleWrapper>
              <Title>By Jessiepay.co</Title>
              <DateTxt>
                <DateSpan>|</DateSpan> {dateStr.toLocaleDateString()}
              </DateTxt>
            </TitleWrapper>
            <IconWrapper>
              <IconContainer>
                <TwitterShareButton
                  url={`${process.env.REACT_APP_BASE_URL}/post/${data?.news?.slug}`}
                  title={data?.news?.title}
                  hashtags={data?.news?.tags}
                >
                  <Twitter size={24} color="#323232" />
                </TwitterShareButton>
              </IconContainer>
              <IconContainer>
                <FacebookShareButton
                  url={`${process.env.REACT_APP_BASE_URL}/post/${data?.news?.slug}`}
                  quote={data?.news?.title}
                  hashtag={data?.news?.tags.map((v, i) => `#${v}`)}
                >
                  <Facebook size={24} color="#323232" />
                </FacebookShareButton>
              </IconContainer>
              <IconContainer>
                <LinkedinShareButton
                  url={`${process.env.REACT_APP_BASE_URL}/post/${data?.news?.slug}`}
                  title={data?.news?.title}
                  summary={data?.news?.snippet}
                  source={`${process.env.REACT_APP_BASE_URL}/post/${data?.news?.slug}`}
                >
                  <Linkedin size={24} color="#323232" />
                </LinkedinShareButton>
              </IconContainer>
            </IconWrapper>
          </BlogHeadingWrapper>
          <ContentWrapper>
            <div style={{ textAlign: "center" }}>
              <BlogImage
                src={data?.news?.newsPicUrl}
                alt="Post"
                effect="blur"
              />
            </div>
            <PostWrapper>
              {/* <PostText>
                Many people may not be aware of PERFECT MONEY, because it exists
                in the shadows as an alternative to the now-defunct Liberty
                Reserved.
              </PostText>
              <PostText>
                In 2008, a universal system was created as a perfect tool for
                online payments with its headquarters in Zurich, Switzerland.
                That universal system is PERFECT MONEY.
              </PostText>
              <PostText>
                Perfect Money is a reliable financial answer that makes it easy
                for users to send/transfer cash over the internet. We no longer
                live in a time we need to visit the financial institution or
                stand in line on the ATM to make a transfer. Perfect Money
                presents wonderful possibilities for internet customers and
                entrepreneurs. The users of Perfect Money can additionally
                alternate their cash into digital currencies in a number of
                minutes and at a decrease rate. They have a well-mounted banking
                dating system that lets them in to make and get hold of
                transactions everywhere in the universe.
              </PostText>
              <PostText>
                Are you familiar with what Perfect Money could be used for? Why
                don't you allow yourself to be pampered?
              </PostText>
              <TextCmp text="• Make money transfer between PM users: as long as you and your friends have a PM account, you can transfer money to each other easily" />
              <TextCmp text="• Accept payments for business projects online: With this, you can stand as a middle man for online businesses when it’s time for their money transactions" />
              <TextCmp text="• Pay for online goods and services: just like the way your make your payments for your online shopping using your cards, PM is here to save the day" />
              <TextCmp text="• Store assets in cryptocurrency; remember, PM operates with crypto. So with this you can easily store your cryptocurrencies with PM with no risk at all. And guess what? You do not need to create another wallet." />
              <PostText>
                Perfect money is known for its versatility, as each user’s
                account is a multi-currency account. It has 4 different currency
                divisions.
              </PostText>
              <TextCmp text="• USD (PM units, the equivalent of United States dollar)" />
              <TextCmp text="• EUR  (PM units, the equivalent of Euro)" />
              <TextCmp text="• GOLD (PM units, the equivalent of the precious metal, expressed in troy ounces)" />
              <TextCmp text="• Bitcoin" />
              <PostText>
                Permitting users from any country to use Perfect Money is one of
                the most obvious benefits of this technology. Sweet, Right!? But
                that’s not all;
              </PostText>
              <PostText>
                Another competitive advantages of Perfect Money is low tariffs.
                Also, the payment system interface is simple and instant
                transfer of money between system users
              </PostText>
              <PostText>
                As you can see, Perfect Money is just perfect for you. However,
                JESSIEPAY assures you of a safe, smooth, easy and secured
                Perfect Money exchange.
              </PostText>
              <PostText>Got perfect money? Trade with Jessie today!</PostText> */}
              <RenderEditorCmp
                contentState={convertFromRaw(JSON.parse(data?.news?.content))}
              />
            </PostWrapper>
            <Line />
            <OtherWrapper>
              {data.previousNews && (
                <OtherNews
                  onClick={() => {
                    window.location.assign(`/post/${data.previousNews.slug}`);
                  }}
                >
                  <ArrowBack size={28} color="#323232" />
                  <OtherNewsText>{data.previousNews.title}</OtherNewsText>
                </OtherNews>
              )}
              {data.nextNews && (
                <OtherNews
                  onClick={() => {
                    window.location.assign(`/post/${data.nextNews.slug}`);
                  }}
                >
                  <OtherNewsText>{data.nextNews.title}</OtherNewsText>
                  <ArrowForward size={28} color="#323232" />
                </OtherNews>
              )}
            </OtherWrapper>
          </ContentWrapper>
        </Wrapper>
      </BlogContainer>
    </>
  );
}

export default PostCmp;
