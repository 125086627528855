import React from "react";
import { Container, Form } from "react-bootstrap";
import styled from "styled-components";
import appTheme from "../../constants/theme";
import NewsletterSvg from "../../assets/newsletter-svg.svg";
import SolidButton from "../Buttons/SolidButton";
import { Formik } from "formik";
import newsletterValidatorSchema from "../../validators/newsletterValidator";
import { subscribe } from "../../redux/actions/newsletterAction";
import { connect } from "react-redux";
import SpinnerCmp from "../SpinnerCmp/SpinnerCmp";
import { toast } from "react-toastify";

const NewsletterContainer = styled(Container)`
  padding: 4rem 12rem 2rem;
  margin-bottom: -3.5rem;
  background-color: ${(props) =>
    props.backColor ? props.backColor : "transparent"};

  @media only screen and (max-width: 1100px) {
    padding: 4rem 9rem 2rem;
  }

  @media only screen and (max-width: 960px) {
    padding: 4rem 7rem 2rem;
  }

  @media only screen and (max-width: 880px) {
    padding: 4rem 5rem 2rem;
  }

  @media only screen and (max-width: 760px) {
    padding: 4rem 2rem 2rem;
  }

  @media only screen and (max-width: 560px) {
    padding: 4rem 0rem 2rem;
    margin-bottom: 3rem;
  }
`;

const NewsLetterWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  background: url(${NewsletterSvg}), #fffdfa no-repeat;
  padding: 3rem;
  border-radius: 24px;
  text-align: center;
  position: relative;
  background-size: cover;

  @media only screen and (max-width: 760px) {
    padding: 3rem 2rem;
  }

  @media only screen and (max-width: 760px) {
    padding: 3rem 1rem;
  }
`;

const Title = styled.h2`
  font-weight: ${appTheme.WEIGHT.mediumBold};
  font-size: ${appTheme.SIZES.mediumTitle};
  color: ${appTheme.COLORS.grey1};

  @media only screen and (max-width: 560px) {
    font-size: 1.5rem;
  }
`;

const Desc = styled.p`
  font-size: ${appTheme.SIZES.body2};
  color: #374151;
  font-weight: ${appTheme.WEIGHT.small};
  padding-top: 1rem;

  @media only screen and (max-width: 560px) {
    font-size: 14px;
  }
`;

const FormWrapper = styled(Form)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 2rem 7rem;

  @media only screen and (max-width: 1032px) {
    margin: 2rem 0;
  }
`;

const Input = styled(Form.Control)`
  padding: 1.3rem 1rem;
  padding-right: 10rem;
  border-radius: 5px;
  border: ${(props) => (props.message ? "1px solid red" : "1px solid #d0dae4")};
  background: rgba(243, 244, 246, 0.7);
  border-radius: 18px;
  color: #374151;
  font-weight: 500;
  font-size: 18px;
  &:focus {
    box-shadow: none;
    border: 1px solid ${appTheme.COLORS.blueSecondary};
    background: rgba(243, 244, 246, 0.7);
  }
  &::placeholder {
    color: #d0dae4;
    font-weight: 500;
    font-size: 18px;
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #d0dae4;
    font-weight: 500;
    font-size: 18px;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #d0dae4;
    font-weight: 500;
    font-size: 18px;
  }

  @media only screen and (max-width: 400px) {
    padding-right: 8.5rem;
  }
`;

function NewsletterCmp({ loading, setSubscribe, ...props }) {
  return (
    <>
      <NewsletterContainer backColor={props.backColor} fluid>
        <NewsLetterWrapper>
          <Title>Subscribe To Our Newsletter</Title>
          <Desc>
            There's a lot to explore in the crypto space and we'd love to share
            that with you. Get latest crypto updates directly to your inbox,
            direct access to amazing bonuses and promos, also a first hand
            updates on our products and services.
          </Desc>

          <Formik
            validationSchema={newsletterValidatorSchema}
            initialValues={{
              email: "",
            }}
            onSubmit={async (values, { resetForm }) => {
              const response = await setSubscribe({
                ...values,
              });

              if (
                response &&
                response.status === 200 &&
                response.message === "You are now a subscriber"
              ) {
                resetForm();
                return toast.success(
                  "Thanks for subscribing to our newsletter",
                  { toastId: "Newsletter" }
                );
              }
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
              values,
              touched,
              isValid,
              errors,
            }) => (
              <>
                <FormWrapper noValidate onSubmit={handleSubmit}>
                  <Input
                    value={values.email}
                    onChange={handleChange}
                    name="email"
                    placeholder="Enter your email address"
                    message={`${errors["email"] ? errors["email"] : ""}`}
                  />
                  <SolidButton
                    customStyle={{ position: "absolute", right: "0.8rem" }}
                    text={
                      loading ? (
                        <>
                          <SpinnerCmp
                            enabled={true}
                            color={appTheme.COLORS.bluePrimary}
                            size={20}
                            secondaryColor={appTheme.COLORS.white}
                          />
                        </>
                      ) : (
                        "Subscribe"
                      )
                    }
                    type="submit"
                    weighty="500"
                    textFontSize="14px"
                    specifyPadding="0.8rem 2rem"
                    color="#fff"
                    borderRadius="18px"
                    backGradient="107.51deg, #0191C5 38.01%, #16639A 88.96%"
                    textHoverColor="#fff"
                    widthWebkit="100%"
                    widthMoz="100%"
                    widthNormal="100%"
                    maxWidth="128px"
                    disabled={loading ? true : false}
                  />
                </FormWrapper>
              </>
            )}
          </Formik>
        </NewsLetterWrapper>
      </NewsletterContainer>
    </>
  );
}

const mapState = (state) => ({
  loading: state.newsletter.subscribeLoading,
});

const mapDispatch = (dispatch) => ({
  setSubscribe: (data) => dispatch(subscribe(data)),
});

export default connect(mapState, mapDispatch)(NewsletterCmp);
