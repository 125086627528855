import React from "react";
import styled from "styled-components";
import appTheme from "../../constants/theme";
import JessieVector1 from "../../assets/jessie-vector-1.svg";
import Security from "../../assets/security.svg";
import UserFriendly from "../../assets/user-friendly.svg";
import Fast from "../../assets/fast.svg";
import Exchange from "../../assets/exchange.svg";
import CustomerService from "../../assets/customer-service.svg";
import SolidButton from "../Buttons/SolidButton";
import SingleFeatureCmp from "../SingleFeatureCmp/SingleFeatureCmp";

const FeatureContainer = styled.div`
  margin: -3rem auto 0rem;
  background: url(${JessieVector1}), #fffdfa no-repeat;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
  border-radius: 24px;
  position: relative;
  background-size: 100%;
  background-repeat: no-repeat;
  max-width: 1536px;

  @media only screen and (max-width: 1536px) {
    margin: -3rem 5rem 0rem 5rem;
  }

  @media only screen and (max-width: 890px) {
    margin: -3rem 4rem 0rem 4rem;
  }

  @media only screen and (max-width: 580px) {
    margin: -3rem 2rem 0rem 2rem;
  }

  @media only screen and (max-width: 374px) {
    margin: -3rem 1rem 0rem 1rem;
  }
`;

const FeatureWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 4rem 3rem 4rem;
  justify-content: space-between;

  @media only screen and (max-width: 1281px) {
    flex-direction: column;
  }

  @media only screen and (max-width: 890px) {
    text-align: center;
  }

  @media only screen and (max-width: 580px) {
    padding: 4rem 1rem 4rem;
  }
`;

const FeatureLeft = styled.div`
  padding-right: 0.5rem;
`;

const FeatureLeftTitle = styled.h2`
  color: ${appTheme.COLORS.grey1};
  font-weight: ${appTheme.WEIGHT.mediumBold};
  font-size: ${appTheme.SIZES.mediumTitle};
  line-height: 3rem;

  @media only screen and (max-width: 508px) {
    line-height: 2.4rem;
    font-size: 1.5rem;
  }
`;

const FeatureLeftDesc = styled.p`
  color: ${appTheme.COLORS.grey1};
  font-weight: ${appTheme.WEIGHT.small};
  font-size: ${appTheme.SIZES.body1};
`;

const ButtonLeftWrapper = styled.div`
  margin: 2rem 0;
`;

const FeatureRight = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-basis: 150%;

  /* @media only screen and (min-width: 1860px) and (max-width: 2014px) {
    padding-left: 3rem;
  } */

  @media only screen and (min-width: 1328px) and (max-width: 1397px) {
    flex-basis: 200%;
  }

  @media only screen and (min-width: 1282px) and (max-width: 1327px) {
    flex-basis: 250%;
  }

  @media only screen and (max-width: 890px) {
    text-align: left;
    justify-content: center;
  }
`;

const FeatureCol = styled.div``;

function Feature() {
  return (
    <>
      <FeatureContainer>
        <FeatureWrapper>
          <FeatureLeft>
            <FeatureLeftTitle>
              Jessie provides the best and most reliable solutions
            </FeatureLeftTitle>
            <FeatureLeftDesc>
              to using and trading Digital Assets in the world.
            </FeatureLeftDesc>
            <ButtonLeftWrapper>
              <SolidButton
                text="Get Started"
                type="button"
                weighty="500"
                textFontSize="14px"
                specifyPadding="0.8rem 1.5rem"
                color="#fff"
                borderRadius="24px"
                backGradient="107.51deg, #0191C5 38.01%, #16639A 88.96%"
                textHoverColor="#fff"
              />
            </ButtonLeftWrapper>
          </FeatureLeft>
          <FeatureRight>
            <FeatureCol>
              <SingleFeatureCmp
                icon={Security}
                circleColor="rgba(83, 189, 177, 0.2)"
                title="Safe & Secured"
                desc="Relax and get the best, we are confident to say that your digital asset is safe and secured."
              />
              <SingleFeatureCmp
                icon={UserFriendly}
                circleColor="rgba(0, 51, 153, 0.2)"
                title="User-Friendly"
                desc="Everyone love user-friendly platform, which is why our priority is to provide you with endless possibilities."
              />
            </FeatureCol>
            <FeatureCol>
              <SingleFeatureCmp
                icon={Fast}
                circleColor="rgba(1, 108, 186, 0.2)"
                title="Fast & Reliable"
                desc="Think jessie think credibility, trust and speed, instant payment."
              />
              <SingleFeatureCmp
                icon={CustomerService}
                circleColor="rgba(222, 83, 72, 0.2)"
                title="Customer Service"
                desc="Jessie always got you on time, 24/7/365 hours’ comfort online."
              />
            </FeatureCol>
            <FeatureCol>
              <SingleFeatureCmp
                icon={Exchange}
                circleColor="rgba(251, 168, 46, 0.3)"
                title="High Exchange Rates"
                desc="Best rates ever, our rates are unbeatable with 100% assurance."
              />
            </FeatureCol>
          </FeatureRight>
        </FeatureWrapper>
      </FeatureContainer>
    </>
  );
}

export default Feature;
