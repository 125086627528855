import React from "react";
import { Container, Form } from "react-bootstrap";
import styled from "styled-components";
import appTheme from "../../constants/theme";
import WorkVector from "../../assets/work-vector.svg";
import CustomInputCmp from "../InputsCmp/CustomInputCmp";
import CustomFileCmp from "../InputsCmp/CustomFileCmp";
import SolidButton from "../Buttons/SolidButton";
import { Formik } from "formik";
import workWithUsValidatorSchema from "../../validators/workWithUsValidator";

const WorkContainer = styled(Container)`
  padding: 2rem 5rem 3rem;
  background: rgba(12, 122, 147, 0.03);
  text-align: center;
  background-color: #fff;
  margin-bottom: 2rem;

  @media only screen and (max-width: 767px) {
    padding: 2rem 3rem 2rem;
  }

  @media only screen and (max-width: 580px) {
    padding: 1rem 1rem 1rem;
  }
`;

const Wrapper = styled.div`
  max-width: 1536px;
  margin: 0 auto;
  background: url(${WorkVector}), #f0f5f9 no-repeat;
  background-size: cover;
  border-radius: 24px;
  padding: 4rem 0;
`;

const Title = styled.h2`
  font-weight: ${appTheme.WEIGHT.mediumBold};
  font-size: 36px;
  color: ${appTheme.COLORS.grey1};

  @media only screen and (max-width: 800px) {
    font-size: 30px;
  }

  @media only screen and (max-width: 650px) {
    font-size: 25px;
  }

  @media only screen and (max-width: 450px) {
    font-size: 22px;
  }

  @media only screen and (max-width: 355px) {
    font-size: 18px;
  }
`;

const Desc = styled.p`
  font-weight: ${appTheme.WEIGHT.small2};
  color: ${appTheme.COLORS.grey1};
  font-size: 15px;
  margin: 0;
`;

const FormWrapper = styled(Form)`
  max-width: 500px;
  margin: 0 auto;
  padding: 2rem;
`;

const EachFormContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1.5rem;

  @media only screen and (max-width: 380px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Label = styled.h2`
  color: ${appTheme.COLORS.grey1};
  font-weight: ${appTheme.WEIGHT.small2};
  font-size: 1rem;
  margin: 0;
  width: 9rem;
  text-align: left;

  @media only screen and (max-width: 450px) {
    width: 7rem;
  }

  @media only screen and (max-width: 410px) {
    width: 6.2rem;
  }

  @media only screen and (max-width: 380px) {
    padding-bottom: 0.8rem;
  }
`;

const ButtonWrapper = styled.div`
  text-align: right;
`;

function WorkWithUsCmp(props) {
  return (
    <>
      <WorkContainer fluid>
        <Wrapper>
          <Title>{props.title}</Title>
          {props.desc && <Desc>{props.desc}</Desc>}
          <Formik
            validationSchema={workWithUsValidatorSchema}
            initialValues={{
              skills: "",
              email: "",
              portfolio: "",
              file: null,
            }}
            onSubmit={async (values, { resetForm }) => {
              console.log("Submitted");
              console.log(values);
              resetForm();
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
              values,
              touched,
              isValid,
              errors,
            }) => (
              <>
                <FormWrapper noValidate onSubmit={handleSubmit}>
                  <EachFormContainer>
                    <Label>Skills</Label>
                    <CustomInputCmp
                      type="text"
                      name="skills"
                      values={values}
                      onChange={handleChange}
                      placeholder="Tell us what  you can do for us"
                      message={`${errors["skills"] ? errors["skills"] : ""}`}
                    />
                  </EachFormContainer>
                  <EachFormContainer>
                    <Label>Email</Label>
                    <CustomInputCmp
                      type="email"
                      name="email"
                      values={values}
                      onChange={handleChange}
                      placeholder="Enter your email address"
                      message={`${errors["email"] ? errors["email"] : ""}`}
                    />
                  </EachFormContainer>
                  <EachFormContainer>
                    <Label>Portfolio</Label>
                    <CustomInputCmp
                      type="text"
                      name="portfolio"
                      values={values}
                      onChange={handleChange}
                      placeholder="Enter link to your portfolio"
                      message={`${
                        errors["portfolio"] ? errors["portfolio"] : ""
                      }`}
                    />
                  </EachFormContainer>
                  <EachFormContainer>
                    <Label>Files</Label>
                    <CustomFileCmp
                      type="text"
                      placeholder="Click to upload file"
                      values={values}
                      setFieldValue={setFieldValue}
                      name="file"
                      message={`${errors["file"] ? errors["file"] : ""}`}
                    />
                  </EachFormContainer>
                  <ButtonWrapper>
                    <SolidButton
                      text="Submit"
                      type="submit"
                      weighty="500"
                      textFontSize="16px"
                      specifyPadding="1rem 2rem"
                      color="#374151"
                      borderRadius="8px"
                      backColor="#FFB140"
                      backgroundHoverColor="#FFB140"
                      textHoverColor="#374151"
                    />
                  </ButtonWrapper>
                </FormWrapper>
              </>
            )}
          </Formik>
        </Wrapper>
      </WorkContainer>
    </>
  );
}

export default WorkWithUsCmp;
