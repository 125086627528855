import React, { useEffect, useState } from "react";
import NavbarCmp from "../components/Navbar/NavbarCmp";
import PageHeaderCmp from "../components/PageHeaderCmp/PageHeaderCmp";
import CareerSubImg from "../assets/careersub-vector.svg";
import NewsletterCmp from "../components/NewsletterCmp/NewsletterCmp";
import FooterCmp from "../components/FooterCmp/FooterCmp";
import PostCmp from "../components/PostCmp/PostCmp";
import { useLocation, useParams } from "react-router-dom";
import { getANewsBySlug } from "../redux/actions/newsAction";
import { connect } from "react-redux";

const Post = ({ setGetANewsBySlug }) => {
  const { state } = useLocation();
  const params = useParams();

  const [newsData, setNewsData] = useState();
  useEffect(() => {
    const getANewsFromSlug = async () => {
      const response = await setGetANewsBySlug(params.slug);

      setNewsData(response.data);
    };
    getANewsFromSlug();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <NavbarCmp colored />
      <PageHeaderCmp
        padding="9rem"
        title={state?.title || newsData?.news?.title}
        backColor="rgba(251, 228, 236, 0.2)"
        backImage={CareerSubImg}
      />
      {newsData && <PostCmp data={newsData} />}
      <NewsletterCmp backColor="#f9fafb" />
      <FooterCmp />
    </>
  );
};

const mapDispatch = (dispatch) => ({
  setGetANewsBySlug: (slug) => dispatch(getANewsBySlug(slug)),
});

export default connect(null, mapDispatch)(Post);
