import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import qualitiesData from "../../constants/qualitiesData";
import appTheme from "../../constants/theme";
import QualityCard from "../QualityCard/QualityCard";

const SctnContainer = styled(Container)`
  padding: 4rem 5rem 4rem;
  background: rgba(12, 122, 147, 0.03);
  text-align: center;
  background-color: #fff;

  @media only screen and (max-width: 767px) {
    padding: 5rem 4rem 3rem;
  }

  @media only screen and (max-width: 580px) {
    padding: 4rem 1rem 3rem;
  }
`;

const Wrapper = styled.div`
  max-width: 900px;
  margin: 0 auto;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  font-weight: ${appTheme.WEIGHT.mediumBold};
  color: ${appTheme.COLORS.grey1};
  text-align: center;
  max-width: 350px;
  margin: 0 auto;
  padding-bottom: 2rem;

  @media only screen and (max-width: 828px) {
    font-size: 2.2rem;
  }

  @media only screen and (max-width: 773px) {
    font-size: 2rem;
  }

  @media only screen and (max-width: 368px) {
    font-size: 1.7rem;
  }
`;

const QualitiesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
`;

const QualityItem = styled.div`
  flex-basis: 33%;
`;

function TeamQualitiesSctn(props) {
  return (
    <>
      <SctnContainer fluid>
        <Wrapper>
          <Title>Jessie Team Tends To Have</Title>
          <QualitiesWrapper>
            {qualitiesData.map((v, i) => {
              return (
                <QualityItem key={i}>
                  <QualityCard attr={v.attr} title={v.title} desc={v.desc} />
                </QualityItem>
              );
            })}
          </QualitiesWrapper>
        </Wrapper>
      </SctnContainer>
    </>
  );
}

export default TeamQualitiesSctn;
