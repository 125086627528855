//types used in NEWS
export const ALL_NEWS_LOADING = "ALL_NEWS_LOADING";
export const ALL_NEWS_SUCCESS = "ALL_NEWS_SUCCESS";
export const ALL_NEWS_ERROR = "ALL_NEWS_ERROR";

export const ALL_NEWS_HOME_LOADING = "ALL_NEWS_HOME_LOADING";
export const ALL_NEWS_HOME_SUCCESS = "ALL_NEWS_HOME_SUCCESS";
export const ALL_NEWS_HOME_ERROR = "ALL_NEWS_HOME_ERROR";

export const A_NEWS_LOADING = "A_NEWS_LOADING";
export const A_NEWS_SUCCESS = "A_NEWS_SUCCESS";
export const A_NEWS_ERROR = "A_NEWS_ERROR";

//types used in TICKET
export const CREATE_TICKET_LOADING = "CREATE_TICKET_LOADING";
export const CREATE_TICKET_SUCCESS = "CREATE_TICKET_SUCCESS";
export const CREATE_TICKET_ERROR = "CREATE_TICKET_ERROR";

//types used in FAQ HOME
export const ALL_FAQS_HOME_LOADING = "ALL_FAQS_HOME_LOADING";
export const ALL_FAQS_HOME_SUCCESS = "ALL_FAQS_HOME_SUCCESS";
export const ALL_FAQS_HOME_ERROR = "ALL_FAQS_HOME_ERROR";

//types used in FAQ PAGE
export const ALL_FAQS_LOADING = "ALL_FAQS_LOADING";
export const ALL_FAQS_SUCCESS = "ALL_FAQS_SUCCESS";
export const ALL_FAQS_ERROR = "ALL_FAQS_ERROR";

//types used in OTC
export const CREATE_OTC_LOADING = "CREATE_OTC_LOADING";
export const CREATE_OTC_SUCCESS = "CREATE_OTC_SUCCESS";
export const CREATE_OTC_ERROR = "CREATE_OTC_ERROR";

//types used in FEEDBACK
export const CREATE_FEEDBACK_LOADING = "CREATE_FEEDBACK_LOADING";
export const CREATE_FEEDBACK_SUCCESS = "CREATE_FEEDBACK_SUCCESS";
export const CREATE_FEEDBACK_ERROR = "CREATE_FEEDBACK_ERROR";

//types used in SUBSCRIBE
export const SUBSCRIBE_LOADING = "SUBSCRIBE_LOADING";
export const SUBSCRIBE_SUCCESS = "SUBSCRIBE_SUCCESS";
export const SUBSCRIBE_ERROR = "SUBSCRIBE_ERROR";
