import React, { useState } from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import appTheme from "../../constants/theme";
import { countries } from "../../constants/countries";
import { cryptoTypeData } from "../../constants/cryptoTypeData";
import CustomInputIconCmp from "../InputsCmp/CustomInputIconCmp";
import { User } from "@styled-icons/boxicons-regular";
import { Email } from "@styled-icons/material-outlined";
import CustomInputSelectCmp from "../InputsCmp/CustomInputSelectCmp";
import CustomInputCmp from "../InputsCmp/CustomInputCmp";
import SolidButton from "../Buttons/SolidButton";
import { Formik } from "formik";
import { banksName } from "../../constants/bank";
import createOtcSchema from "../../validators/createOtcValidator";
import { createOtc } from "../../redux/actions/otcAction";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import SpinnerCmp from "../SpinnerCmp/SpinnerCmp";

const TransactionsContainer = styled(Container)`
  background: rgba(225, 235, 245, 0.3);
  padding: 5rem 0 7rem;
  text-align: center;

  @media only screen and (max-width: 600px) {
    padding: 3rem 0;
  }
`;

const TabsWrapper = styled.div`
  max-width: 400px;
  border-radius: 24px;
  background-color: rgba(225, 235, 245, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 4rem;
  text-align: center;
  padding: 0.5rem 0.8rem;

  @media only screen and (max-width: 424px) {
    flex-direction: column;
    max-width: fit-content;
  }
`;

const Tab = styled.div`
  width: 200px;
  padding: 1rem 0;
  /* flex-basis: 50%; */
  cursor: pointer;
  background-color: ${(props) => (props.active ? "#fff" : "transparent")};
  border-radius: 24px;
`;

const Text = styled.h2`
  color: ${(props) => (props.active ? "#016cba" : "#6b7280")};
  font-size: 1rem;
  font-weight: ${appTheme.WEIGHT.small};
  margin: 0;
`;

const FormContainer = styled.div`
  background-color: #fff;
  margin: 0 auto;
  margin-top: 2rem;
  max-width: 1000px;
`;

const TitleText = styled.h2`
  color: #016cba;
  font-size: 1.2rem;
  font-weight: ${appTheme.WEIGHT.mediumBold};
  margin: 0;
  padding: 3rem 0;
`;

const FormWrapper = styled.div`
  max-width: 500px;
  margin: 0 auto;
  padding-bottom: 2rem;
`;

const InputWrapper = styled.div`
  padding-bottom: 1rem;
  margin: 0 1rem;
`;

function TransactionsCmp({ loading, setCreateOtc }) {
  const [activeTab, setActiveTab] = useState("Buy");
  return (
    <>
      <TransactionsContainer fluid>
        <TabsWrapper>
          <Tab
            active={activeTab === "Buy" ? true : false}
            onClick={() => {
              setActiveTab("Buy");
            }}
          >
            <Text active={activeTab === "Buy" ? true : false}>
              Buy transactions
            </Text>
          </Tab>
          <Tab
            active={activeTab === "Sell" ? true : false}
            onClick={() => {
              setActiveTab("Sell");
            }}
          >
            <Text active={activeTab === "Sell" ? true : false}>
              Sell transactions
            </Text>
          </Tab>
        </TabsWrapper>
        <FormContainer>
          <FormWrapper>
            {(activeTab === "Buy" || activeTab === "Sell") && (
              <>
                <TitleText>PERSONAL INFO.</TitleText>
                <Formik
                  validationSchema={createOtcSchema}
                  initialValues={{
                    email: "",
                    name: "",
                    phone: "",
                    country: "",
                    cryptoType: "",
                    walletAddress: "",
                    transactionAmount: "",
                    accountNumber: "",
                    accountName: "",
                    bankName: "",
                  }}
                  onSubmit={async (values, { resetForm }) => {
                    const response = await setCreateOtc({
                      ...values,
                      transactionType: activeTab,
                    });

                    if (
                      response &&
                      response.status === 201 &&
                      response.message === "Otc created"
                    ) {
                      resetForm();
                      return toast.success(
                        "Your otc transaction has been submitted and a representative will get to it right away",
                        { toastId: "Otc created" }
                      );
                    }
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    errors,
                    setFieldValue,
                  }) => (
                    <>
                      <form noValidate onSubmit={handleSubmit}>
                        <InputWrapper>
                          <CustomInputIconCmp
                            background="rgba(225, 235, 245, 0.1)"
                            required={true}
                            icon={
                              <>
                                <User
                                  size={20}
                                  color="rgba(107, 114, 128, 0.5)"
                                />
                              </>
                            }
                            type="text"
                            name="name"
                            placeholder="Name"
                            values={values}
                            onChange={handleChange}
                            message={`${errors["name"] ? errors["name"] : ""}`}
                            showMessage={true}
                          />
                        </InputWrapper>
                        <InputWrapper>
                          <CustomInputIconCmp
                            background="rgba(225, 235, 245, 0.1)"
                            required={true}
                            icon={
                              <>
                                <User
                                  size={20}
                                  color="rgba(107, 114, 128, 0.5)"
                                />
                              </>
                            }
                            type="text"
                            name="phone"
                            placeholder="Phone Number"
                            values={values}
                            onChange={handleChange}
                            message={`${
                              errors["phone"] ? errors["phone"] : ""
                            }`}
                            showMessage={true}
                          />
                        </InputWrapper>
                        <InputWrapper>
                          <CustomInputIconCmp
                            background="rgba(225, 235, 245, 0.1)"
                            required={true}
                            icon={
                              <>
                                <Email
                                  size={20}
                                  color="rgba(107, 114, 128, 0.5)"
                                />
                              </>
                            }
                            type="email"
                            name="email"
                            placeholder="Email Address"
                            values={values}
                            onChange={handleChange}
                            message={`${
                              errors["email"] ? errors["email"] : ""
                            }`}
                            showMessage={true}
                          />
                        </InputWrapper>
                        <InputWrapper>
                          <CustomInputSelectCmp
                            background="rgba(225, 235, 245, 0.1)"
                            required={true}
                            name="country"
                            placeholder="Select Country"
                            data={countries}
                            values={values}
                            onChange={handleChange}
                            message={`${
                              errors["country"] ? errors["country"] : ""
                            }`}
                            showMessage={true}
                          />
                        </InputWrapper>
                        <InputWrapper>
                          <CustomInputSelectCmp
                            background="rgba(225, 235, 245, 0.1)"
                            required={true}
                            name="cryptoType"
                            placeholder="Crypto Type"
                            data={cryptoTypeData}
                            values={values}
                            onChange={handleChange}
                            message={`${
                              errors["cryptoType"] ? errors["cryptoType"] : ""
                            }`}
                            showMessage={true}
                          />
                        </InputWrapper>
                        <InputWrapper>
                          <CustomInputCmp
                            background="rgba(225, 235, 245, 0.1)"
                            required={true}
                            type="text"
                            name="walletAddress"
                            placeholder="Wallet Address"
                            values={values}
                            onChange={handleChange}
                            message={`${
                              errors["walletAddress"]
                                ? errors["walletAddress"]
                                : ""
                            }`}
                            showMessage={true}
                          />
                        </InputWrapper>
                        <InputWrapper>
                          <CustomInputSelectCmp
                            background="rgba(225, 235, 245, 0.1)"
                            required={true}
                            name="transactionAmount"
                            placeholder="Transaction Amount"
                            data={[
                              "Beginner  (₦5,000 - ₦49,999)",
                              "Trader (₦50,000 - ₦99,999)",
                              "Expert Trader  (₦100,000 - ₦999,999)",
                              "Whale (₦1,000,000 - More)",
                            ]}
                            values={values}
                            onChange={handleChange}
                            message={`${
                              errors["transactionAmount"]
                                ? errors["transactionAmount"]
                                : ""
                            }`}
                            showMessage={true}
                          />
                        </InputWrapper>
                        <TitleText>BANK DETAILS.</TitleText>
                        <InputWrapper>
                          <CustomInputSelectCmp
                            background="rgba(225, 235, 245, 0.1)"
                            required={true}
                            name="bankName"
                            placeholder="Bank Name"
                            data={banksName}
                            values={values}
                            onChange={handleChange}
                            message={`${
                              errors["bankName"] ? errors["bankName"] : ""
                            }`}
                            showMessage={true}
                          />
                        </InputWrapper>
                        <InputWrapper>
                          <CustomInputCmp
                            background="rgba(225, 235, 245, 0.1)"
                            required={true}
                            type="text"
                            name="accountNumber"
                            placeholder="Account Number"
                            values={values}
                            onChange={handleChange}
                            message={`${
                              errors["accountNumber"]
                                ? errors["accountNumber"]
                                : ""
                            }`}
                            showMessage={true}
                          />
                        </InputWrapper>
                        <InputWrapper>
                          <CustomInputCmp
                            background="rgba(225, 235, 245, 0.1)"
                            required={true}
                            type="text"
                            name="accountName"
                            placeholder="Account Name"
                            values={values}
                            onChange={handleChange}
                            message={`${
                              errors["accountName"] ? errors["accountName"] : ""
                            }`}
                            showMessage={true}
                          />
                        </InputWrapper>
                        <SolidButton
                          text={
                            loading ? (
                              <>
                                <SpinnerCmp
                                  enabled={true}
                                  color={appTheme.COLORS.bluePrimary}
                                  size={20}
                                  secondaryColor={appTheme.COLORS.white}
                                />
                              </>
                            ) : (
                              "Submit"
                            )
                          }
                          margin="2rem 0"
                          type="submit"
                          weighty="500"
                          textFontSize="16px"
                          specifyPadding="1rem 2rem"
                          color="#374151"
                          borderRadius="8px"
                          backColor="#FFB140"
                          backgroundHoverColor="#FFB140"
                          textHoverColor="#374151"
                          widthWebkit="100%"
                          widthMoz="100%"
                          widthNormal="100%"
                          maxWidth="120px"
                          disabled={loading ? true : false}
                        />
                      </form>
                    </>
                  )}
                </Formik>
              </>
            )}
          </FormWrapper>
        </FormContainer>
      </TransactionsContainer>
    </>
  );
}

const mapState = (state) => ({
  loading: state.otc.createOtcLoading,
});

const mapDispatch = (dispatch) => ({
  setCreateOtc: (data) => dispatch(createOtc(data)),
});

export default connect(mapState, mapDispatch)(TransactionsCmp);
