import { Facebook, Linkedin, Twitter } from "@styled-icons/boxicons-logos";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import appTheme from "../../constants/theme";
import { getAllNews } from "../../redux/actions/newsAction";
import NewsCardCmp from "../NewsCardCmp/NewsCardCmp";
import PaginationCmp from "../PaginationCmp/PaginationCmp";
import { connect } from "react-redux";
import {
  TwitterShareButton,
  FacebookShareButton,
  LinkedinShareButton,
} from "react-share";

const BlogContainer = styled(Container)`
  background: rgba(225, 235, 245, 0.3);
  padding: 5rem 0 5rem;

  @media only screen and (max-width: 600px) {
    padding: 4rem 0;
  }
`;

const Wrapper = styled.div`
  max-width: 1272px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

const BlogWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;

const BlogHeadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 1rem;
  margin: 0 2rem;
  align-items: center;

  @media only screen and (max-width: 420px) {
    margin: 0 1rem;
  }
`;

const Title = styled.h2`
  font-size: 30px;
  font-weight: 700;
  color: ${appTheme.COLORS.grey1};

  @media only screen and (max-width: 700px) {
    font-size: 24px;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const IconContainer = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #adb5bd;
  background-color: #fff;
  cursor: pointer;
  margin: 0 0.5rem 0.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Line = styled.hr`
  width: 150px;
  height: 1px;
  color: #adb5bd;
  background-color: #adb5bd;
  margin: 0 0.5rem 0;

  @media only screen and (max-width: 700px) {
    width: 75px;
  }

  @media only screen and (max-width: 374px) {
    display: none;
  }
`;

const EachBlog = styled.div`
  margin: 1rem;
`;

const PaginateWrapper = styled.div`
  padding-top: 3rem;
`;

function BlogCmp({ setGetAllNews }) {
  const navigate = useNavigate();
  const [newsData, setNewsData] = useState({});
  const getAllNews = async (query) => {
    const response = await setGetAllNews(query);

    setNewsData(response.data);
  };
  useEffect(() => {
    getAllNews(`?page=${1}&limit=${15}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <BlogContainer fluid>
        <Wrapper>
          <BlogHeadingWrapper>
            <Title>Latest</Title>
            <IconWrapper>
              <Line />
              <IconContainer>
                <TwitterShareButton
                  url={`${process.env.REACT_APP_BASE_URL}/blog`}
                  title={"The Jessie Blog"}
                >
                  <Twitter size={24} color="#323232" />
                </TwitterShareButton>
              </IconContainer>
              <IconContainer>
                <FacebookShareButton
                  url={`${process.env.REACT_APP_BASE_URL}/blog`}
                  quote={"The Jessie Blog"}
                >
                  <Facebook size={24} color="#323232" />
                </FacebookShareButton>
              </IconContainer>
              <IconContainer>
                <LinkedinShareButton
                  url={`${process.env.REACT_APP_BASE_URL}/blog`}
                  title={"The Jessie Blog"}
                  summary={
                    "Stories from the world’s leading digital asset platform where you have full control of your assets."
                  }
                  source={`${process.env.REACT_APP_BASE_URL}/post/blog`}
                >
                  <Linkedin size={24} color="#323232" />
                </LinkedinShareButton>
              </IconContainer>
            </IconWrapper>
          </BlogHeadingWrapper>
          <BlogWrapper>
            {newsData &&
              newsData.results &&
              newsData.results.map((v, i) => {
                return (
                  <EachBlog
                    key={i}
                    onClick={() => {
                      navigate(`/post/${v.slug}`, { state: v });
                    }}
                  >
                    <NewsCardCmp
                      key={i}
                      title={v.title}
                      desc={v.snippet}
                      image={v.newsPicUrl}
                      slugs={v.tags}
                    />
                  </EachBlog>
                );
              })}
          </BlogWrapper>
          <PaginateWrapper>
            <PaginationCmp
              total={newsData?.count}
              limit={15}
              pageClick={(data) => {
                getAllNews(`?limit=${15}&page=${data.selected + 1}`);
              }}
            />
          </PaginateWrapper>
        </Wrapper>
      </BlogContainer>
    </>
  );
}

const mapDispatch = (dispatch) => ({
  setGetAllNews: (query) => dispatch(getAllNews(query)),
});

export default connect(null, mapDispatch)(BlogCmp);
