import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Menu } from "@styled-icons/evaicons-solid";
import appTheme from "../../constants/theme";
import SolidButton from "../Buttons/SolidButton";
import SidebarCmp from "./SidebarCmp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import LogoColor from "../../assets/logo-color.svg";
import LogoWhite from "../../assets/logo-white.svg";
import otc from "../../assets/otc.png";
import giftcard from "../../assets/giftcard.png";
import crypto from "../../assets/crypto.png";
import pm from "../../assets/perfect-money.png";
import airtime from "../../assets/airtime-cash.png";
import renew from "../../assets/renew.png";
import help from "../../assets/help.png";
import faq from "../../assets/faq.png";
import blog from "../../assets/blog.png";
import { DownArrow, UpArrow } from "@styled-icons/boxicons-solid";

const NavWrapper = styled.div`
  /* background: transparent; */
  background: ${(props) =>
    props.navBarState || props.type ? "#fff" : "transparent"};
  box-shadow: ${(props) =>
    props.navBarState || props.type
      ? "0px 4px 6px rgba(0, 0, 0, 0.07)"
      : "none"};
  height: 80px;
  margin-top: ${(props) => (props.type ? "0" : "-80px")};
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 10;

  @media only screen and (max-width: 960px) {
    transition: 0.8s all ease;
  }
`;

const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  width: 100%;
  padding: 1rem 2rem;
  max-width: 1536px;
`;

const NavLogo = styled(Link)`
  justify-self: flex-start;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white;
`;

const NavImage = styled(LazyLoadImage)`
  object-fit: cover;
  width: 137px;
  height: 46px;
`;

const MobileIconWrapper = styled.div`
  display: none;

  @media only screen and (max-width: 920px) {
    display: block;
    /* position: absolute;
    top: 0;
    right: 0; */
    /* transform: translate(-100%, 60%); */
    cursor: pointer;
  }
`;

const NavMenu = styled.div`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  /* margin-right: -22px; */

  @media only screen and (max-width: 920px) {
    display: none;
  }
`;

const NavItem = styled.div`
  /* height: 80px; */
  height: ${(props) => (props.clickState === true ? "50px" : "80px")};

  &:hover,
  &:active {
    height: 50px;
  }
`;

const NavLink = styled(Link)`
  color: ${(props) =>
    props.navBarState || props.type || props.colored ? "#333333" : "#ffffff"};
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;

  &:hover,
  &:active {
    background: rgba(204, 226, 241, 0.3);
    border-radius: 10px;
    color: ${(props) =>
      props.navBarState || props.type || props.colored ? "#333333" : "#ffffff"};
  }
`;

const NavLink2 = styled.a`
  color: ${(props) =>
    props.navBarState || props.type || props.colored ? "#333333" : "#ffffff"};
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;

  &:hover,
  &:active {
    background: rgba(204, 226, 241, 0.3);
    border-radius: 10px;
    color: ${(props) =>
      props.navBarState || props.type || props.colored ? "#333333" : "#ffffff"};
  }
`;

const NavButtonWrapper = styled.div`
  @media only screen and (max-width: 920px) {
    display: none;
  }
`;

const DropdownWrapper = styled.div`
  width: 400px;
  border-radius: 10px;
  background-color: ${appTheme.COLORS.white};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.09);
  position: absolute;
  top: 65px;

  @media only screen and (max-width: 920px) {
    display: none;
  }
`;

const DropdownItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  flex-wrap: wrap;
`;

const EachItemWrapper = styled.a`
  flex: 50%;
  display: flex;
  align-items: center;
  padding: 1rem 0.5rem;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background: rgba(196, 196, 196, 0.3);
    border-radius: 6px;
  }
`;

const ItemImg = styled(LazyLoadImage)``;

const ItemText = styled.h2`
  font-weight: 500;
  font-size: 15px;
  margin: 0;
  padding-left: 0.5rem;
  color: #333333;
`;

const MenuSpan = styled.span``;

function NavbarCmp(props) {
  const ref = useRef();

  const [isOpen, setIsOpen] = useState(false);
  const [navbar, setNavbar] = useState(false);
  const [productClick, setProductClick] = useState(false);
  const [resourceClick, setResourceClick] = useState(false);

  const handleProductClick = () => {
    setProductClick(!productClick);
    // console.log(productClick);
  };

  const handleResourceClick = () => {
    setResourceClick(!resourceClick);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (
        (productClick || resourceClick) &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        setProductClick(false);
        setResourceClick(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [productClick, resourceClick]);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const changeBackgroundColor = () => {
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackgroundColor);

  const { pathname } = window.location;

  return (
    <>
      <NavWrapper type={props.type} navBarState={navbar}>
        <NavContainer>
          <NavLogo to="/">
            <NavImage
              src={
                navbar || props.type || props.colored ? LogoColor : LogoWhite
              }
              alt="Logo"
              effect="blur"
            />
          </NavLogo>
          <MobileIconWrapper onClick={toggle}>
            <Menu
              color={navbar || props.type || props.colored ? "#333333" : "#fff"}
              size={24}
            />
          </MobileIconWrapper>
          <NavMenu>
            <NavItem>
              <NavLink
                type={props.type}
                colored={props.colored}
                navBarState={navbar}
                to="/about"
              >
                About
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                type={props.type}
                colored={props.colored}
                navBarState={navbar}
                to="/careers"
              >
                Careers
              </NavLink>
            </NavItem>
            <NavItem
              onClick={handleProductClick}
              clickState={productClick}
              style={{ position: "relative" }}
            >
              <NavLink2
                type={props.type}
                colored={props.colored}
                navBarState={navbar}
              >
                Products &nbsp;
                <MenuSpan>
                  {productClick ? (
                    <UpArrow size={12} />
                  ) : (
                    <DownArrow size={12} />
                  )}
                </MenuSpan>
              </NavLink2>
              {/* dropdown */}
              {productClick && (
                <DropdownWrapper click={productClick} ref={ref}>
                  <DropdownItemContainer>
                    <EachItemWrapper href="/otc">
                      <ItemImg src={otc} alt="OTC" />
                      <ItemText>OTC</ItemText>
                    </EachItemWrapper>
                    <EachItemWrapper
                      href={`${pathname !== "/" ? `/` : "#giftcard-trading"}`}
                    >
                      <ItemImg src={giftcard} alt="Giftcards" />
                      <ItemText>Giftcards</ItemText>
                    </EachItemWrapper>
                    <EachItemWrapper
                      href={`${pathname !== "/" ? `/` : "#trade-crypto"}`}
                    >
                      <ItemImg src={crypto} alt="Cryptocurrency" />
                      <ItemText>Cryptocurrency</ItemText>
                    </EachItemWrapper>
                    <EachItemWrapper
                      href={`${pathname !== "/" ? `/` : "#perfect-money"}`}
                    >
                      <ItemImg src={pm} alt="Perfect Money" />
                      <ItemText>Perfect Money</ItemText>
                    </EachItemWrapper>
                    <EachItemWrapper
                      href={`${pathname !== "/" ? `/` : "#airtime-product"}`}
                    >
                      <ItemImg src={renew} alt="Renew" />
                      <ItemText>Renew</ItemText>
                    </EachItemWrapper>
                    <EachItemWrapper
                      href={`${pathname !== "/" ? `/` : "#airtime-product"}`}
                    >
                      <ItemImg src={airtime} alt="Airtime" />
                      <ItemText>Airtime to cash</ItemText>
                    </EachItemWrapper>
                  </DropdownItemContainer>
                </DropdownWrapper>
              )}
            </NavItem>
            <NavItem>
              <NavLink
                type={props.type}
                colored={props.colored}
                navBarState={navbar}
                to="/market"
              >
                Markets
              </NavLink>
            </NavItem>
            <NavItem click={resourceClick}>
              <NavLink2
                type={props.type}
                colored={props.colored}
                navBarState={navbar}
                onClick={handleResourceClick}
              >
                Resources &nbsp;
                <MenuSpan>
                  {resourceClick ? (
                    <UpArrow size={12} />
                  ) : (
                    <DownArrow size={12} />
                  )}
                </MenuSpan>
              </NavLink2>
              {resourceClick && (
                <DropdownWrapper click={resourceClick} ref={ref}>
                  <DropdownItemContainer>
                    <EachItemWrapper as={Link} to="/resources">
                      <ItemImg src={help} alt="Help Center" />
                      <ItemText>Help Center</ItemText>
                    </EachItemWrapper>
                    <EachItemWrapper as={Link} to="/faq">
                      <ItemImg src={faq} alt="FAQs" />
                      <ItemText>FAQs</ItemText>
                    </EachItemWrapper>
                    <EachItemWrapper as={Link} to="/blog">
                      <ItemImg src={blog} alt="Blog" />
                      <ItemText>Blog</ItemText>
                    </EachItemWrapper>
                  </DropdownItemContainer>
                </DropdownWrapper>
              )}
            </NavItem>
          </NavMenu>
          <NavButtonWrapper>
            <SolidButton
              text="Sign in"
              type="submit"
              weighty="500"
              textFontSize="14px"
              specifyPadding="0.8rem 1.4rem"
              borderColor={navbar ? "#636363" : "none"}
              margin="0 0.5rem"
              color={navbar ? "#333333" : "#016CBA"}
              borderRadius="24px"
              backColor="#fff"
              textHoverColor="#fff"
              onClick={() => {
                window.open("https://app.jessiepay.co/login");
              }}
            />
            <SolidButton
              text="Create Account"
              type="button"
              weighty="500"
              textFontSize="14px"
              specifyPadding="0.8rem 1.4rem"
              color="#374151"
              borderRadius="22px"
              backColor="#FFB140"
              backgroundHoverColor="#FFB140"
              textHoverColor="#374151"
              margin="0 0.5rem"
              onClick={() => {
                window.open("https://app.jessiepay.co/get-started");
              }}
            />
          </NavButtonWrapper>
        </NavContainer>
      </NavWrapper>
      <SidebarCmp isOpen={isOpen} toggle={toggle} />
    </>
  );
}

export default NavbarCmp;
