import axios from "axios";
import { toast } from "react-toastify";
import {
  CREATE_FEEDBACK_ERROR,
  CREATE_FEEDBACK_LOADING,
} from "../reducers/types";
import { apiBaseUrl } from "../../constants/url";

export const createFeedback = (data) => async (dispatch) => {
  dispatch({ type: CREATE_FEEDBACK_LOADING, payload: true });
  dispatch({ type: CREATE_FEEDBACK_ERROR, payload: null });
  try {
    const response = await axios.post(`${apiBaseUrl}/feedback/create`, data);
    dispatch({ type: CREATE_FEEDBACK_LOADING, payload: null });

    if (response.data.status !== 200) {
      return toast.error(response.data.message);
    }
    return response.data;
  } catch (err) {
    dispatch({ type: CREATE_FEEDBACK_LOADING, payload: null });

    if (err.response.status === 422) {
      return toast.error(`Validation error`);
    } else {
      return toast.error(err.response?.data?.message);
    }
  }
};
