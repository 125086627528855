import React from "react";
import styled from "styled-components";
import appTheme from "../../constants/theme";
import GreaterIcon from "../../assets/greater.svg";
import { Link } from "react-router-dom";

const Container = styled.div`
  background: #ffffff;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
  cursor: pointer;
  padding: 1.3rem 1.5rem;
  margin: 0.8rem 0;
  text-decoration: none;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ContentWrapper = styled.div`
  text-align: left;
`;

const Title = styled.h2`
  color: ${appTheme.COLORS.grey1};
  font-weight: ${appTheme.WEIGHT.mediumBold};
  font-size: 20px;

  @media only screen and (max-width: 828px) {
    font-size: 18px;
  }

  @media only screen and (max-width: 773px) {
    font-size: 16px;
  }

  @media only screen and (max-width: 368px) {
    font-size: 15px;
  }
`;

const Desc = styled.p``;

const Img = styled.img``;

const AttrWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const AttrText = styled.h2`
  font-weight: ${appTheme.WEIGHT.small2};
  font-size: 13px;
  color: #374151;
  margin: 0;

  @media only screen and (max-width: 600px) {
    font-size: 11px;
  }
`;

const Dot = styled.div`
  width: 2px;
  height: 2px;
  border-radius: 50%;
  margin: 0 0.5rem;
  background-color: #374151;
`;

function RoleCmp(props) {
  return (
    <>
      <Container as={Link} to={props.url}>
        <Wrapper>
          <ContentWrapper>
            <Title>{props.name}</Title>
            <Desc></Desc>
            <AttrWrapper>
              {props.attr.map((v, i) => {
                return (
                  <>
                    <AttrText key={v}>{v}</AttrText>
                    {i < props.attr.length - 1 && <Dot />}
                  </>
                );
              })}
            </AttrWrapper>
          </ContentWrapper>
          <Img src={GreaterIcon} alt="Apply" />
        </Wrapper>
      </Container>
    </>
  );
}

export default RoleCmp;
