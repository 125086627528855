import axios from "axios";
import { toast } from "react-toastify";
import { SUBSCRIBE_ERROR, SUBSCRIBE_LOADING } from "../reducers/types";
import { apiBaseUrl } from "../../constants/url";

export const subscribe = (data) => async (dispatch) => {
  dispatch({ type: SUBSCRIBE_LOADING, payload: true });
  dispatch({ type: SUBSCRIBE_ERROR, payload: null });
  try {
    const response = await axios.post(
      `${apiBaseUrl}/newsletter/subscribe`,
      data
    );
    dispatch({ type: SUBSCRIBE_LOADING, payload: null });

    if (response.data.status !== 200) {
      return toast.error(response.data.message);
    }
    return response.data;
  } catch (err) {
    dispatch({ type: SUBSCRIBE_LOADING, payload: null });

    if (err.response.status === 422) {
      return toast.error(`Validation error`);
    } else {
      return toast.error(err.response?.data?.message);
    }
  }
};
