const feature1 = [
  {
    text: "Seamless crypto transactions",
    borderRadius: "36px",
    color: "#016CBA",
    backColor: "rgba(1, 108, 186, 0.1)",
    borderColor: "#016CBA",
    widthNormal: "275px",
    textFontSize: "16px",
    weighty: "500",
    specifyPadding: "1rem",
    margin: "0.5rem",
  },
  {
    text: "Send and receive crypto",
    borderRadius: "36px",
    color: "#323232",
    backColor: "transaprent",
    borderColor: "#016CBA",
    widthNormal: "275px",
    textFontSize: "16px",
    weighty: "500",
    specifyPadding: "1rem",
    margin: "0.5rem",
  },
  {
    text: "Real time wallet value",
    borderRadius: "36px",
    color: "#323232",
    backColor: "transaprent",
    borderColor: "#016CBA",
    widthNormal: "275px",
    textFontSize: "16px",
    weighty: "500",
    specifyPadding: "1rem",
    margin: "0.5rem",
  },
  {
    text: "Buy and sell crypto",
    borderRadius: "36px",
    color: "#016CBA",
    backColor: "rgba(1, 108, 186, 0.1)",
    borderColor: "#016CBA",
    widthNormal: "275px",
    textFontSize: "16px",
    weighty: "500",
    specifyPadding: "1rem",
    margin: "0.5rem",
  },
  {
    text: "Receive and send money",
    borderRadius: "36px",
    color: "#016CBA",
    backColor: "rgba(1, 108, 186, 0.1)",
    borderColor: "#016CBA",
    widthNormal: "275px",
    textFontSize: "16px",
    weighty: "500",
    specifyPadding: "1rem",
    margin: "0.5rem",
  },
  {
    text: "Store and convert crypto to cash",
    borderRadius: "36px",
    color: "#323232",
    backColor: "transaprent",
    borderColor: "#016CBA",
    widthNormal: "275px",
    textFontSize: "16px",
    weighty: "500",
    specifyPadding: "1rem",
    margin: "0.5rem",
  },

  {
    text: "Pay bills with Crypto",
    borderRadius: "36px",
    color: "#323232",
    backColor: "transaprent",
    borderColor: "#016CBA",
    widthNormal: "275px",
    textFontSize: "16px",
    weighty: "500",
    specifyPadding: "1rem",
    margin: "0.5rem",
  },
  {
    text: "Do more with Jessie renew",
    borderRadius: "36px",
    color: "#016CBA",
    backColor: "rgba(1, 108, 186, 0.1)",
    borderColor: "#016CBA",
    widthNormal: "275px",
    textFontSize: "16px",
    weighty: "500",
    specifyPadding: "1rem",
    margin: "0.5rem",
  },
];

export default feature1;
