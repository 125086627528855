import React from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import appTheme from "../../constants/theme";
import SolidButton from "../Buttons/SolidButton";
import CollapsibleCmp from "../CollapsibleCmp/CollapsibleCmp";
import TicketCardCmp from "../TicketCardCmp/TicketCardCmp";
import { Element } from "react-scroll";
import { getAllFaqsHome } from "../../redux/actions/faqAction";
import { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";

const ResourcesContainer = styled(Container)`
  background: rgba(225, 235, 245, 0.3);
  padding: 3rem 0 3rem;
  text-align: center;

  @media only screen and (max-width: 600px) {
    padding: 3rem 0;
  }
`;

const ResourcesWrapper = styled.div`
  margin: 0 auto;
  margin-top: 2rem;
  max-width: 1000px;
  text-align: center;
`;

const Title = styled.h2`
  font-weight: ${appTheme.WEIGHT.mediumBold};
  font-size: 36px;
  color: ${appTheme.COLORS.grey1};

  @media only screen and (max-width: 800px) {
    font-size: 30px;
  }

  @media only screen and (max-width: 650px) {
    font-size: 25px;
  }

  @media only screen and (max-width: 450px) {
    font-size: 22px;
  }

  @media only screen and (max-width: 355px) {
    font-size: 18px;
  }
`;

const CardWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 1.5rem 0 5rem;

  @media only screen and (max-width: 600px) {
    padding: 1.5rem 0 3rem;
  }
`;

const CollapsibleWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 1.5rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const CollapsibleDiv = styled.div`
  margin: 1rem;
  flex: 45%;

  @media only screen and (max-width: 639px) {
    flex: unset;
  }
`;

function ResourcesCmp({ setGetAllFaqsHome, faqHome, loadingHome, errorHome }) {
  const navigate = useNavigate();
  const [faqData, setFaqData] = useState([]);
  useEffect(() => {
    const getAllFaqs = async () => {
      const response = await setGetAllFaqsHome();

      setFaqData(response.data.results);
    };
    getAllFaqs();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // const collapsibleData = [
  //   {
  //     collapseTitle: "How can I open a crypto account",
  //     desc: "With a Jessiepay account, you already have your own Jessie CRYPTO Wallet where you can send, receive and save your CRYPTO.",
  //   },
  //   {
  //     collapseTitle: "How Do I Access My Account?",
  //     desc: "Click on “Login” at the top of the webpage and Enter your registered email address or Username, then click on 'Sign in'",
  //   },
  //   {
  //     collapseTitle: "How to trade Physical Card?",
  //     desc: "For smooth confirmations, it is recommended that the physical card is sent in full, the picture showing all four corners of the card, with pin scratched",
  //   },
  //   {
  //     collapseTitle: "Why can we not trade on social media?",
  //     desc: "Those are not the appropriate platforms for our type of transactions. For secure, safe and seamless transactions, you can log in to our website; Jessiepay.co or download the mobile App, to carry out all your transactions.",
  //   },
  // ];
  return (
    <>
      <ResourcesContainer fluid>
        <ResourcesWrapper>
          <CardWrapper>
            <TicketCardCmp
              backColor="#FFE0A1"
              title="FAQs"
              desc="Get fast answers to popular questions."
              inline={true}
              to="faq"
            />
            <TicketCardCmp
              backColor="rgba(222, 83, 72, 0.3)"
              title="Create Ticket"
              desc="Have a problem with your transactions? Create a ticket."
              inline={true}
              to="createTicket"
            />
            <TicketCardCmp
              backColor="rgba(12, 122, 147, 0.3)"
              title="Leave Feedback"
              desc="Help us serve you better. Leave a feedback."
              onClick={() => {
                navigate("/feedback");
              }}
            />
          </CardWrapper>
          <Element name="faq">
            <Title>Most asked questions</Title>
            <CollapsibleWrapper>
              {faqData &&
                faqData?.length > 0 &&
                faqData.map((v, i) => {
                  return (
                    <CollapsibleDiv key={i}>
                      <CollapsibleCmp
                        collapseTitle={v.question}
                        desc={v.answer}
                      />
                    </CollapsibleDiv>
                  );
                })}
            </CollapsibleWrapper>
            <SolidButton
              text="See More"
              onClick={() => {
                navigate("/faq");
              }}
              margin="2rem 0"
              type="submit"
              weighty="500"
              textFontSize="16px"
              specifyPadding="1rem 1.5rem"
              color="#016CBA"
              borderColor="#016CBA"
              borderHoverColor="#016CBA"
              borderRadius="22px"
              backColor="transparent"
              backgroundHoverColor="#016CBA"
              textHoverColor="#fff"
            />
          </Element>
        </ResourcesWrapper>
      </ResourcesContainer>
    </>
  );
}

const mapState = (state) => ({
  loadingHome: state.faq.allFaqLoading,
  errorHome: state.faq.allFaqError,
  faqHome: state.faq.allFaq,
});

const mapDispatch = (dispatch) => ({
  setGetAllFaqsHome: () => dispatch(getAllFaqsHome()),
});

export default connect(mapState, mapDispatch)(ResourcesCmp);
