import {
  ALL_NEWS_ERROR,
  ALL_NEWS_LOADING,
  ALL_NEWS_SUCCESS,
  ALL_NEWS_HOME_ERROR,
  ALL_NEWS_HOME_LOADING,
  ALL_NEWS_HOME_SUCCESS,
} from "./types";

const initialState = {
  allNewsLoading: null,
  allNews: null,
  allNewsError: null,
  allNewsHomeLoading: null,
  allNewsHome: null,
  allNewsHomeError: null,
};

export default function newsReducer(state = initialState, action) {
  switch (action.type) {
    case ALL_NEWS_LOADING:
      return { ...state, allNewsLoading: action.payload };
    case ALL_NEWS_SUCCESS:
      return { ...state, allNews: action.payload };
    case ALL_NEWS_ERROR:
      return { ...state, allNewsError: action.payload };
    case ALL_NEWS_HOME_LOADING:
      return { ...state, allNewsHomeLoading: action.payload };
    case ALL_NEWS_HOME_SUCCESS:
      return { ...state, allNewsHome: action.payload };
    case ALL_NEWS_HOME_ERROR:
      return { ...state, allNewsHomeError: action.payload };
    default:
      return state;
  }
}
