import React from "react";
import styled from "styled-components";
import appTheme from "../../constants/theme";

const Container = styled.div`
  border-radius: 10px;
  background-color: ${(props) =>
    props.type === "careers" ? "transparent" : `${appTheme.COLORS.white}`};
  filter: drop-shadow(0px 4px 7px rgba(0, 0, 0, 0.03));
  padding: 1.5rem 1rem;
  margin: ${(props) => (props.type === "careers" ? "0 auto" : "0 1rem")};
  margin-bottom: 3rem;
  /* min-height: 180px; */
  max-width: 235px;

  &:hover {
    @keyframes myAnim {
      0% {
        transform: scale(1);
      }

      50% {
        transform: scale(1.02);
      }

      100% {
        transform: scale(1);
      }
    }

    animation: myAnim 1000ms linear 0s 1 normal forwards;
  }

  @media only screen and (min-width: 2052px) {
    min-height: 200px;
    padding: 2rem;
  }

  @media only screen and (min-width: 2015px) and (max-width: 2051px) {
    min-height: 210px;
    padding: 2rem;
  }

  @media only screen and (max-width: 790px) {
    margin-bottom: ${(props) => props.type === "careers" && "1rem"};
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const IconWrapper = styled.div``;

const Circle = styled.div`
  width: ${(props) => (props.size ? `${props.size}` : "40px")};
  height: ${(props) => (props.size ? `${props.size}` : "40px")};
  background-color: ${(props) => (props.circleColor ? props.circleColor : "")};
  border-radius: 50%;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
`;

const IconImg = styled.img`
  width: 20px;
`;

const TextWrapper = styled.div``;

const TitleText = styled.h2`
  font-weight: ${appTheme.WEIGHT.mediumBold};
  color: ${appTheme.COLORS.grey1};
  font-size: 0.8rem;
`;

const TitleDesc = styled.p`
  font-weight: ${appTheme.WEIGHT.small3};
  color: ${appTheme.COLORS.grey1};
  font-size: 0.7rem;

  /* @media only screen and (max-width: 508px) {
    font-size: 0.9rem;
  } */
`;

function SingleFeatureCmp(props) {
  return (
    <>
      <Container type={props.type}>
        <Wrapper>
          <IconWrapper>
            <Circle size={props.size} circleColor={props.circleColor}>
              <IconImg src={props.icon} alt="icon" />
            </Circle>
          </IconWrapper>
          <TextWrapper>
            <TitleText>{props.title}</TitleText>
            <TitleDesc>{props.desc}</TitleDesc>
          </TextWrapper>
        </Wrapper>
      </Container>
    </>
  );
}

export default SingleFeatureCmp;
