import React from "react";
import NavbarCmp from "../components/Navbar/NavbarCmp";
import MeetTeamCmp from "../components/MeetTeamCmp/MeetTeamCmp";
import InterestedWorkingUsCmp from "../components/InterestedWorkingUsCmp/InterestedWorkingUsCmp";
import WhoIsCmp from "../components/WhoIsCmp/WhoIsCmp";
import CoreValuesCmp from "../components/CoreValuesCmp/CoreValuesCmp";
import OurSuperHeroesCmp from "../components/OurSuperHeroesCmp/OurSuperHeroesCmp";
import NewsletterCmp from "../components/NewsletterCmp/NewsletterCmp";
import FooterCmp from "../components/FooterCmp/FooterCmp";
import CounterRowCmp from "../components/CounterRowCmp/CounterRowCmp";

function About() {
  return (
    <>
      <NavbarCmp colored />
      <MeetTeamCmp />
      <WhoIsCmp />
      <CounterRowCmp />
      <OurSuperHeroesCmp />
      <CoreValuesCmp />
      <InterestedWorkingUsCmp
        title="Interested in working with us?"
        text="Are you fascinated by blockchain technology? Do you want to work in a fast-paced environment where self-improvement is the norm? Jessie is the perfect home for you, let’s make a difference together."
        buttonText="Join our team"
      />
      <NewsletterCmp backColor="#f9fafb" />
      <FooterCmp />
    </>
  );
}

export default About;
