import React, { useEffect } from "react";
import TawkTo from "tawkto-react";

function ChatCmp() {
  useEffect(() => {
    var tawk = new TawkTo(
      process.env.REACT_APP_TAWK_PROPERTY_ID,
      process.env.REACT_APP_TAWK_ID
    );

    tawk.onStatusChange((status) => {
      // console.log(status)
    });
  }, []);
  return <></>;
}

export default ChatCmp;
