import React from "react";
import Feature from "../components/FeatureCmp/Feature";
import Hero from "../components/HeroCmp/Hero";
import Product from "../components/ProductCmp/Product";
import ServiceLeftImgCmp from "../components/ServiceLeftImgCmp/ServiceLeftImgCmp";
import ServiceRightImgCmp from "../components/ServiceRightImgCmp/ServiceRightImgCmp";
import ServiceSvg1 from "../assets/service-svg-1.svg";
import ServiceSvg2 from "../assets/service-svg-2.svg";
import ServiceSvg3 from "../assets/service-svg-3.svg";
import JessieMockup1 from "../assets/jessie-app-mockup-1.png";
import JessieMockup2 from "../assets/jessie-app-mockup-2.png";
import JessieMockup3 from "../assets/jessie-app-mockup-3.png";
import feature1 from "../constants/feature1";
import feature2 from "../constants/feature2";
import feature3 from "../constants/feature3";
import FooterCmp from "../components/FooterCmp/FooterCmp";
import InTheNewsCmp from "../components/InTheNewsCmp/InTheNewsCmp";
import NewsletterCmp from "../components/NewsletterCmp/NewsletterCmp";
import NavbarCmp from "../components/Navbar/NavbarCmp";

function Home() {
  return (
    <>
      <NavbarCmp />
      <Hero />
      <Feature />
      <Product />
      <ServiceLeftImgCmp
        name="crypto"
        title="Crypto Trading The Easy Way"
        svgImage={ServiceSvg1}
        phoneMockup={JessieMockup1}
        featureData={feature1}
        backSectionColor="linear-gradient(
          113.09deg,
          rgba(31, 109, 166, 0.06) 35.05%,
          rgba(23, 98, 152, 0.06) 90.23%
        )"
      />
      <ServiceRightImgCmp
        name="business"
        title="Do More With Jessie Business"
        svgImage={ServiceSvg3}
        phoneMockup={JessieMockup3}
        featureData={feature3}
        backSectionColor="linear-gradient(113.09deg, rgba(135, 132, 132, 0.06) 35.05%, rgba(140, 134, 134, 0.06) 90.23%)"
      />
      <ServiceLeftImgCmp
        name="giftcard"
        title="Transact With Ease And Without Bounds"
        svgImage={ServiceSvg2}
        phoneMockup={JessieMockup2}
        featureData={feature2}
        backSectionColor="linear-gradient(113.09deg, rgba(215, 25, 32, 0.06) 35.05%, rgba(191, 8, 31, 0.06) 90.23%)"
      />
      <InTheNewsCmp />
      <NewsletterCmp />
      <FooterCmp />
    </>
  );
}

export default Home;
