import React from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Team1 from "../../assets/different.png";
import appTheme from "../../constants/theme";

const DareContainer = styled(Container)`
  padding: 4rem 5rem 4rem;
  background-color: #fff;

  @media only screen and (max-width: 767px) {
    padding: 5rem 4rem 3rem;
  }

  @media only screen and (max-width: 580px) {
    padding: 4rem 1rem 3rem;
  }
`;

const DareWrapper = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  background: #fff;
  display: flex;
  /* flex-wrap: wrap; */
  /* padding: 3rem 3rem;
  border-radius: 24px; */
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 760px) {
    flex-direction: column;
  }

  /* @media only screen and (max-width: 424px) {
    padding: 3rem 2rem;
  } */
`;

const ContentWrapper = styled.div`
  width: 45%;
  padding-right: 2rem;

  @media only screen and (max-width: 1088px) {
    flex-basis: 70%;
  }

  @media only screen and (max-width: 952px) {
    flex-basis: 90%;
  }

  @media only screen and (max-width: 889px) {
    flex-basis: 120%;
  }

  @media only screen and (max-width: 760px) {
    flex-basis: unset;
    width: 100%;
    text-align: center;
    padding: 0 1rem;
    padding-bottom: 2rem;
  }
`;

const DareTitle = styled.h2`
  color: ${appTheme.COLORS.grey1};
  font-weight: ${appTheme.WEIGHT.mediumBold};
  font-size: 2.5rem;

  @media only screen and (max-width: 828px) {
    font-size: 2.2rem;
  }

  @media only screen and (max-width: 773px) {
    font-size: 2rem;
  }

  @media only screen and (max-width: 368px) {
    font-size: 1.7rem;
  }
`;

const Line = styled.hr`
  width: 33%;
  height: 1px;
  color: #fff;
  border-top: 1px dashed #000;
  margin: 5rem auto 0;
  transform: scale(3);
`;

const DareDesc = styled.p`
  font-size: 1rem;
  color: #374151;
  font-weight: ${appTheme.WEIGHT.small3};

  @media only screen and (max-width: 600px) {
    font-size: 14px;
  }
`;

const ImgWrapper = styled.div``;

const DareImg = styled(LazyLoadImage)`
  object-fit: cover;
  background-size: fit;
  width: 100%;
`;

function DareDifferentCmp() {
  return (
    <>
      <DareContainer fluid>
        <DareWrapper>
          <ContentWrapper>
            <DareTitle>Dare to be different!</DareTitle>
            <DareDesc>
              Working at Jessie offers one the opportunity to not just
              contribute one’s skills to the development of our innovative
              service offerings, but also the chance to be a part of a company
              that is truly disrupting the norm and creating the future.
            </DareDesc>
          </ContentWrapper>
          <ImgWrapper>
            <DareImg src={Team1} alt="Jessie Team" effect="blur" />
          </ImgWrapper>
        </DareWrapper>
        <Line />
      </DareContainer>
    </>
  );
}

export default DareDifferentCmp;
