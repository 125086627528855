import React from "react";
import { Container } from "react-bootstrap";
import appTheme from "../../constants/theme";
import styled from "styled-components";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import PillCmp from "../PillCmp/PillCmp";
import { Element } from "react-scroll";

const ServiceContainer = styled(Container)`
  padding: 2rem 5rem 2rem;

  @media only screen and (max-width: 767px) {
    padding: 1rem 4rem 3rem;
  }

  @media only screen and (max-width: 580px) {
    padding: 1rem 1rem 1rem;
  }
`;

const ServiceWrapper = styled.div`
  max-width: 1536px;
  margin: 0 auto;
  /* background: linear-gradient(
    113.09deg,
    rgba(31, 109, 166, 0.06) 35.05%,
    rgba(23, 98, 152, 0.06) 90.23%
  ); */
  background: ${(props) => props.backSectionColor};
  display: flex;
  /* flex-wrap: wrap; */
  padding: 3rem 3rem;
  border-radius: 24px;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }

  @media only screen and (max-width: 424px) {
    padding: 3rem 2rem;
  }
`;

const LeftSection = styled.div`
  @media only screen and (max-width: 1206px) {
    flex-basis: 150%;
  }
`;

const ImgSvg = styled(LazyLoadImage)``;

const Title = styled.h2`
  padding: 1.5rem 0;
  font-size: ${appTheme.SIZES.largeTitle};
  font-weight: ${appTheme.WEIGHT.mediumBold};
  color: ${appTheme.COLORS.grey1};
  width: 70%;

  @media only screen and (max-width: 1400px) {
    font-size: 2.5rem;
  }

  @media only screen and (max-width: 745px) {
    font-size: 2rem;
    width: 90%;
  }

  @media only screen and (max-width: 480px) {
    width: 100%;
  }

  @media only screen and (max-width: 415px) {
    font-size: 1.8rem;
  }

  @media only screen and (max-width: 381px) {
    font-size: 1.6rem;
  }
`;

const FeaturesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media only screen and (max-width: 1272px) {
    max-width: fit-content;
  }

  @media only screen and (max-width: 1206px) {
    max-width: 85%;
  }

  @media only screen and (max-width: 1152px) {
    max-width: 90%;
  }

  @media only screen and (max-width: 1112px) {
    max-width: 95%;
  }

  @media only screen and (max-width: 1077px) {
    max-width: 100%;
  }

  @media only screen and (max-width: 832px) {
    flex-direction: column;
  }
`;

const RightSection = styled.div`
  @media only screen and (max-width: 1044px) {
    display: none;
  }

  @media only screen and (max-width: 600px) {
    display: unset;
  }
`;

const AppMockupOne = styled(LazyLoadImage)`
  /* @media only screen and (max-width: 1257px) {
    width: 200%;
    background-size: cover;
  } */

  @media only screen and (max-width: 1272px) {
    width: 90%;
  }

  @media only screen and (max-width: 1206px) {
    width: 100%;
  }
`;

function ServiceLeftImgCmp(props) {
  return (
    <>
      <Element name={props.name}>
        <ServiceContainer fluid>
          <ServiceWrapper backSectionColor={props.backSectionColor}>
            <LeftSection>
              <ImgSvg src={props.svgImage} alt="Service" effect="blur" />
              <Title>{props.title}</Title>
              <FeaturesWrapper>
                {props.featureData.map((feature, index) => {
                  return (
                    <PillCmp
                      text={feature.text}
                      borderRadius={feature.borderRadius}
                      color={feature.color}
                      backColor={feature.backColor}
                      borderColor={feature.borderColor}
                      widthNormal={feature.widthNormal}
                      textFontSize={feature.textFontSize}
                      weighty={feature.weighty}
                      specifyPadding={feature.specifyPadding}
                      margin={feature.margin}
                    />
                  );
                })}
              </FeaturesWrapper>
            </LeftSection>
            <RightSection>
              <AppMockupOne
                src={props.phoneMockup}
                alt="Mockup"
                effect="blur"
              />
            </RightSection>
          </ServiceWrapper>
        </ServiceContainer>
      </Element>
    </>
  );
}

export default ServiceLeftImgCmp;
