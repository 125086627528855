import React from "react";
import styled from "styled-components";
import appTheme from "../../constants/theme";
import ProductVector from "../../assets/product-vector.png";
import SolidButton from "../Buttons/SolidButton";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "./SingleProductCmp.css";
import { Link } from "react-scroll";

const Wrapper = styled.div`
  border-radius: 24px;
  text-align: center;
  height: 560px;
  /* background: url(${ProductVector}), rgba(82, 67, 170, 0.05) no-repeat; */
  background: ${(props) =>
    `url(${ProductVector}), ${props.backColor} no-repeat` || ""};
  background-size: 100%;
  /* background-color: rgba(82, 67, 170, 0.05); */
  padding: 4rem 3rem 0;
  margin: 1rem;
  flex: 45%;
  position: relative;

  @media only screen and (max-width: 960px) {
    padding: 4rem 2rem 0;
  }

  @media only screen and (max-width: 960px) {
    height: 530px;
  }

  @media only screen and (max-width: 767px) {
    margin: 1rem 0rem;
    flex: 100%;
  }

  @media only screen and (max-width: 850px) {
    height: 500px;
  }

  @media only screen and (max-width: 767px) {
    height: 560px;
  }

  @media only screen and (max-width: 580px) {
    margin: 1rem 0;
  }

  @media only screen and (max-width: 440px) {
    height: 510px;
  }

  @media only screen and (max-width: 374px) {
    margin: 1rem 0;
  }

  @media only screen and (max-width: 360px) {
    height: 530px;
  }
`;

const Title = styled.h2`
  font-weight: ${appTheme.WEIGHT.mediumBold};
  font-size: 1.5rem;
  color: ${appTheme.COLORS.grey1};
`;

const Desc = styled.p`
  font-weight: ${appTheme.WEIGHT.small};
  font-size: ${appTheme.SIZES.body2};
  color: #374151;
  padding: 0 3rem 0;

  @media only screen and (max-width: 960px) {
    padding: 0;
  }
`;

const ImageWrapper = styled.div``;

const Image = styled(LazyLoadImage)`
  background-size: cover;
  max-width: 100%;

  @media only screen and (max-width: 1186px) {
    max-width: 80%;
  }

  @media only screen and (max-width: 915px) {
    max-width: 75%;
  }

  @media only screen and (max-width: 920px) {
    max-width: 70%;
  }

  @media only screen and (max-width: 850px) {
    max-width: 65%;
  }

  @media only screen and (max-width: 825px) {
    max-width: 58%;
  }

  @media only screen and (max-width: 767px) {
    max-width: 100%;
  }

  @media only screen and (max-width: 600px) {
    max-width: 96%;
  }

  @media only screen and (max-width: 485px) {
    max-width: 90%;
  }

  @media only screen and (max-width: 470px) {
    max-width: 79%;
  }
`;

function SingleProductCmp(props) {
  return (
    <>
      <Wrapper
        id={props.name}
        className={props.className}
        backColor={props.backColor}
      >
        <Title>{props.title}</Title>
        <Desc>{props.desc}</Desc>
        <Link to={props.linkTo} spy={true} smooth={true} duration={500}>
          <SolidButton
            text="Learn More"
            color="#0A7CB1"
            borderRadius="24px"
            specifyPadding="0.8rem 1.5rem"
            backColor="transparent"
            fontWeight="400"
            borderColor="#0A7CB1"
            textHoverColor="#fff"
            backgroundHoverColor="#0A7CB1"
          />
        </Link>
        <ImageWrapper style={props.styleObj} className="image-wrapper-product">
          <Image src={props.image} alt={props.alt} effect="blur" />
        </ImageWrapper>
      </Wrapper>
    </>
  );
}

export default SingleProductCmp;
