import { SUBSCRIBE_ERROR, SUBSCRIBE_LOADING, SUBSCRIBE_SUCCESS } from "./types";

const initialState = {
  subscribeLoading: null,
  subscribeSuccess: null,
  subscribeError: null,
};

export default function newsletterReducer(state = initialState, action) {
  switch (action.type) {
    case SUBSCRIBE_LOADING:
      return { ...state, subscribeLoading: action.payload };
    case SUBSCRIBE_SUCCESS:
      return { ...state, subscribeSuccess: action.payload };
    case SUBSCRIBE_ERROR:
      return { ...state, subscribeError: action.payload };
    default:
      return state;
  }
}
