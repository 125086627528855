import React from "react";
import styled from "styled-components";
import appTheme from "../../constants/theme";

const Title = styled.h2`
  color: ${appTheme.COLORS.grey1};
  font-size: 2rem;
  font-weight: ${appTheme.WEIGHT.mediumBold};
  padding-bottom: 0.5rem;

  @media only screen and (max-width: 600px) {
    font-size: 1.5rem;
  }
`;

function TitleCmp(props) {
  return (
    <>
      <Title>{props.title}</Title>
    </>
  );
}

export default TitleCmp;
