/* eslint-disable no-useless-escape */
import * as yup from "yup";

const createTicketSchema = yup.object().shape({
  file: yup
    .mixed()
    .required("Please add an image")
    .test("fileSize", "File Size is too large", (value) => {
      if (value && value[0]?.size) {
        return value[0].size <= 500000;
      }
    })
    .test("fileType", "Unsupported File Format", (value) => {
      if (value && value[0]?.type) {
        if (["image/jpg", "image/jpeg", "image/png"].includes(value[0].type)) {
          return true;
        }
      }
    }),
  email: yup
    .string()
    .required("Provide an email address ")
    .email("Provide a valid email address"),
  transactionId: yup.string().required("Please add news transaction ID"),
  issue: yup.string().required("Please describe the problem"),
});

export default createTicketSchema;
