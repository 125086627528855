import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import appTheme from "../../constants/theme";
import SolidButton from "../Buttons/SolidButton";
import { useNavigate } from "react-router-dom";

const WorkContainer = styled(Container)`
  background: #fff;
  padding: 3rem 0 4rem;
  text-align: center;
  background-size: cover;

  @media only screen and (max-width: 600px) {
    padding-top: 2rem;
  }
`;

const Wrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const Title = styled.h2`
  font-weight: ${appTheme.WEIGHT.mediumBold};
  font-size: 36px;
  color: ${appTheme.COLORS.grey1};

  @media only screen and (max-width: 800px) {
    font-size: 30px;
  }

  @media only screen and (max-width: 650px) {
    font-size: 25px;
  }

  @media only screen and (max-width: 450px) {
    font-size: 22px;
  }

  @media only screen and (max-width: 355px) {
    font-size: 18px;
  }
`;

const Text = styled.p`
  font-size: 1.3rem;
  font-weight: ${appTheme.WEIGHT.small3};
  color: #374151;
  padding-top: 1rem;

  @media only screen and (max-width: 900px) {
    font-size: 1rem;
  }
`;

const ButtonWrapper = styled.div`
  text-align: center;
`;

function InterestedWorkingUsCmp(props) {
  const navigate = useNavigate();
  return (
    <>
      <WorkContainer>
        <Wrapper>
          <Title>{props.title}</Title>
          <Text>{props.text}</Text>
          <ButtonWrapper>
            <SolidButton
              text={props.buttonText}
              onClick={()=> {navigate("/careers")}}
              type="submit"
              weighty="500"
              textFontSize="16px"
              specifyPadding="1rem 2rem"
              color="#374151"
              borderRadius="8px"
              backColor="#FFB140"
              backgroundHoverColor="#FFB140"
              textHoverColor="#374151"
            />
          </ButtonWrapper>
        </Wrapper>
      </WorkContainer>
    </>
  );
}

export default InterestedWorkingUsCmp;
