/* eslint-disable no-useless-escape */
import * as yup from "yup";

const createFeedbackSchema = yup.object().shape({
  email: yup
    .string()
    .required("Provide an email address ")
    .email("Provide a valid email address"),
  name: yup.string().required("Provide your name").optional(),
  review: yup.string().required("Provide your review"),
  rating: yup
    .number()
    .min(1, "Minimum rating is 1")
    .max(5, "Maximum rating is 5")
    .required("Pick a rating"),
});

export default createFeedbackSchema;
