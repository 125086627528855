import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import CareerSubImg from "../../assets/careersub-vector.svg";
import appTheme from "../../constants/theme";
import Team from "../../assets/team.png";

const MeetContainer = styled(Container)`
  background: url(${CareerSubImg}), #fff no-repeat;
  padding-top: 9rem;
  text-align: center;
  background-size: cover;

  @media only screen and (max-width: 920px) {
    padding-top: 7rem;
  }

  @media only screen and (max-width: 600px) {
    padding-top: 6rem;
  }
`;

const Title = styled.h2`
  font-weight: ${appTheme.WEIGHT.mediumBold};
  font-size: 36px;
  color: ${appTheme.COLORS.grey1};
  padding-bottom: 1rem;

  @media only screen and (max-width: 800px) {
    font-size: 30px;
  }

  @media only screen and (max-width: 650px) {
    font-size: 25px;
  }

  @media only screen and (max-width: 450px) {
    font-size: 22px;
  }

  @media only screen and (max-width: 355px) {
    font-size: 18px;
  }
`;

const Wrapper = styled.div`
  /* background-image: url(${Team});
  background-repeat: no-repeat;
  /* height: 600px; */
  width: 100%;
  background-size: contain;
  position: relative;
`;

const Image = styled(LazyLoadImage)`
  width: 100%;
  padding-top: 1rem;
  object-fit: cover;
  display: flex;
  margin: 0 auto;
`;

const BlueWrapper = styled.div`
  background-color: #016cba;
  position: absolute;
  border-radius: 20px 20px 0px 0px;
  padding: 0rem 2rem;
  /* height: 350px; */
  width: 100%;
  margin: 0 auto;
  bottom: 0;
  height: 150px;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 960px) {
    height: 120px;
  }

  @media only screen and (max-width: 720px) {
    height: 100px;
  }

  @media only screen and (max-width: 600px) {
    height: 95px;
  }

  @media only screen and (max-width: 500px) {
    padding: 0 1rem;
  }

  @media only screen and (max-width: 375px) {
    height: 85px;
  }

  @media only screen and (max-width: 330px) {
    height: 70px;
  }
`;

const Text = styled.p`
  color: ${appTheme.COLORS.white};
  font-size: 26px;

  @media only screen and (max-width: 1000px) {
    font-size: 22px;
  }

  @media only screen and (max-width: 960px) {
    font-size: 18px;
  }

  @media only screen and (max-width: 700px) {
    font-size: 16px;
  }

  @media only screen and (max-width: 400px) {
    font-size: 14px;
  }

  @media only screen and (max-width: 375px) {
    font-size: 12px;
  }
`;

function MeetTeamCmp() {
  return (
    <>
      <MeetContainer>
        <Title>Meet The Jessie Team</Title>
        <Wrapper>
          <Image src={Team} effect="blur" />
          <BlueWrapper>
            <Text>
              “At Jessie, We strive to work closely together with our customers
              in solving their problems. We value honesty, integrity, and
              efficiency”
            </Text>
          </BlueWrapper>
        </Wrapper>
      </MeetContainer>
    </>
  );
}

export default MeetTeamCmp;
