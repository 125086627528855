import React from "react";
import ReactPaginate from "react-paginate";
import SolidButton from "../Buttons/SolidButton";
import "./PaginationCmp.css";

function PaginationCmp({ total, limit, pageClick }) {
  return (
    <>
      <ReactPaginate
        containerClassName="jessie-paginate-container"
        previousLabel={
          <SolidButton
            text="Prev"
            type="submit"
            weighty="500"
            textFontSize="14px"
            specifyPadding="0.8rem 1.4rem"
            borderColor="#016CBA"
            borderHoverColor="transparent"
            margin="0 0.5rem"
            color="#fff"
            borderRadius="24px"
            backColor="#016CBA"
            textHoverColor="#fff"
          />
        }
        nextLabel={
          <SolidButton
            text="Next"
            type="submit"
            weighty="500"
            textFontSize="14px"
            specifyPadding="0.8rem 1.4rem"
            borderColor="#016CBA"
            borderHoverColor="transparent"
            margin="0 0.5rem"
            color="#fff"
            borderRadius="24px"
            backColor="#016CBA"
            textHoverColor="#fff"
          />
        }
        breakClassName="paginate-break"
        breakLabel=".  .  ."
        breakLinkClassName={"jessie-break-link"}
        // pageCount={Math.ceil(total)}
        pageCount={Math.ceil(total / limit)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={1}
        onPageChange={pageClick}
        disabledClassName="paginate-disabled"
        pageClassName="jessie-page"
        pageLinkClassName="jessie-link"
        activeClassName="jessie-active"
        activeLinkClassName="jessie-active-link"
      />
    </>
  );
}

export default PaginationCmp;
