import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import appTheme from "../../constants/theme";
import SuperHeroCardCmp from "../SuperHeroCardCmp/SuperHeroCardCmp";
import JajaImg from "../../assets/jaja.png";
// import AdebayoImg from "../../assets/Adebayo.png";
// import AllohImg from "../../assets/Alloh.png";
// import AmushiImg from "../../assets/Amushi.png";
import DingImg from "../../assets/Ding.png";
// import EbiImg from "../../assets/Ebi.png";
import FurayeImg from "../../assets/Furaye.png";
import MaziImg from "../../assets/Mazi.png";
import OlagwojuImg from "../../assets/Olagwoju.png";
import OmoyeleImg from "../../assets/Omoyele.png";
import AyindeImg from "../../assets/Ayinde.jpg";

const SuperHeroesContainer = styled(Container)`
  background: rgba(128, 181, 220, 0.06);
  padding: 3rem 0;
  text-align: center;
  background-size: cover;

  @media only screen and (max-width: 600px) {
    padding-top: 2rem;
  }
`;

const Wrapper = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 3rem 1rem;
`;

const Title = styled.h2`
  font-weight: ${appTheme.WEIGHT.mediumBold};
  font-size: 36px;
  color: ${appTheme.COLORS.grey1};

  @media only screen and (max-width: 800px) {
    font-size: 30px;
  }

  @media only screen and (max-width: 650px) {
    font-size: 25px;
  }

  @media only screen and (max-width: 450px) {
    font-size: 22px;
  }

  @media only screen and (max-width: 355px) {
    font-size: 18px;
  }
`;

const HeroesWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 1.5rem;
  flex-wrap: wrap;
`;

const EachHeroWrapper = styled.div`
  margin: 1.5rem 1rem;
`;

function OurSuperHeroesCmp() {
  const heroes = [
    { name: "Jaja Evans Sokari", role: "Founder/CEO", img: JajaImg },
    {
      name: "Mazi Yellow Mazi",
      role: "Head Of Operations, Africa",
      img: MaziImg,
    },
    {
      name: "Ding Wei",
      role: "Head Of Operations, Asia",
      img: DingImg,
    },
    {
      name: "Ayinde Samuel",
      role: "Chief Technology Officer",
      img: AyindeImg,
    },
    {
      name: "Olagwoju Muideen",
      role: "Design Lead",
      img: OlagwojuImg,
    },
    {
      name: "Omoyele Temidayo Stella",
      role: "Creative Lead",
      img: OmoyeleImg,
    },
    {
      name: "Furaye Irene T.",
      role: "Product Designer",
      img: FurayeImg,
    },
  ];
  return (
    <>
      <SuperHeroesContainer fluid>
        <Wrapper>
          <Title>Meet Our Super Heroes</Title>
          <HeroesWrapper>
            {heroes.map((v, i) => {
              return (
                <EachHeroWrapper>
                  <SuperHeroCardCmp img={v.img} role={v.role} name={v.name} />
                </EachHeroWrapper>
              );
            })}
          </HeroesWrapper>
        </Wrapper>
      </SuperHeroesContainer>
    </>
  );
}

export default OurSuperHeroesCmp;
