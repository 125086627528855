import {
  ALL_FAQS_ERROR,
  ALL_FAQS_LOADING,
  ALL_FAQS_SUCCESS,
  ALL_FAQS_HOME_ERROR,
  ALL_FAQS_HOME_LOADING,
  ALL_FAQS_HOME_SUCCESS,
} from "./types";

const initialState = {
  allFaqLoading: null,
  allFaq: null,
  allFaqError: null,
  allFaqHomeLoading: null,
  allFaqHome: null,
  allFaqHomeError: null,
};

export default function faqReducer(state = initialState, action) {
  switch (action.type) {
    case ALL_FAQS_LOADING:
      return { ...state, allFaqLoading: action.payload };
    case ALL_FAQS_SUCCESS:
      return { ...state, allFaq: action.payload };
    case ALL_FAQS_ERROR:
      return { ...state, allFaqError: action.payload };
    case ALL_FAQS_HOME_LOADING:
      return { ...state, allFaqHomeLoading: action.payload };
    case ALL_FAQS_HOME_SUCCESS:
      return { ...state, allFaqHome: action.payload };
    case ALL_FAQS_HOME_ERROR:
      return { ...state, allFaqHomeError: action.payload };
    default:
      return state;
  }
}
