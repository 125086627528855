import React from "react";
import NavbarCmp from "../components/Navbar/NavbarCmp";
import NewsletterCmp from "../components/NewsletterCmp/NewsletterCmp";
import FooterCmp from "../components/FooterCmp/FooterCmp";
import MarketCmp from "../components/MarketCmp/MarketCmp";
import PageHeaderCmp from "../components/PageHeaderCmp/PageHeaderCmp";
import CareerSubImg from "../assets/careersub-vector.svg";

function Market() {
  return (
    <>
      <NavbarCmp colored />
      <PageHeaderCmp
        padding="10rem"
        title="Today’s Cryptocurrency Prices"
        backColor="rgba(208, 218, 228, 0.2)"
        backImage={CareerSubImg}
      />
      <MarketCmp />
      <NewsletterCmp backColor="#f9fafb" />
      <FooterCmp />
    </>
  );
}

export default Market;
