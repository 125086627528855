import {
  CREATE_TICKET_ERROR,
  CREATE_TICKET_LOADING,
  CREATE_TICKET_SUCCESS,
} from "./types";

const initialState = {
  createTicketLoading: null,
  createTicketSuccess: null,
  createTicketError: null,
};

export default function ticketReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_TICKET_LOADING:
      return { ...state, createTicketLoading: action.payload };
    case CREATE_TICKET_SUCCESS:
      return { ...state, createTicketSuccess: action.payload };
    case CREATE_TICKET_ERROR:
      return { ...state, createTicketError: action.payload };
    default:
      return state;
  }
}
