const cryptoTypeData = [
  "Bitcoin (BTC)",
  "Ethereum (ETH)",
  "Binance Coin (BNB)",
  "Bitcoin Cash (BCH)",
  "Dogecoin (DOGE)",
  "Ripple (XRP)",
  "Litecoin (LTC)",
  "Matic (MATIC)",
  "Cardano (ADA)",
  "Tron (TRON)",
  "Solana (SOL)",
];

export { cryptoTypeData };
