import React from "react";
import styled from "styled-components";
import appTheme from "../../constants/theme";

const CardWrapper = styled.div`
  border-radius: 30px;
  background: ${(props) =>
    props.backColor && props.backImage
      ? `url(${props.backImage}), ${props.backColor} no-repeat`
      : `${props.backColor}`};
  background-size: cover;
  padding: 2.5rem 1.5rem;
  width: 280px;
  height: 200px;
`;

const Title = styled.h2`
  color: #374151;
  font-size: 20px;
  font-weight: ${appTheme.WEIGHT.medium};
  margin: 0;
  padding-bottom: 1rem;
`;

const Desc = styled.p`
  color: #374151;
  font-size: 1rem;
  font-weight: ${appTheme.WEIGHT.small3};
  margin: 0;
`;

function CoreValueCardCmp(props) {
  return (
    <CardWrapper backColor={props.backColor} backImage={props.backImage}>
      <Title>{props.title}</Title>
      <Desc>{props.desc}</Desc>
    </CardWrapper>
  );
}

export default CoreValueCardCmp;
