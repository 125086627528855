const feature3 = [
  {
    text: "Alternative payments for Business",
    borderRadius: "36px",
    color: "#323232",
    backColor: "transparent",
    borderColor: "#878484",
    widthNormal: "275px",
    textFontSize: "15px",
    weighty: "500",
    specifyPadding: "1rem",
    margin: "0.5rem",
  },
  {
    text: "Accept cryptocurrency payments from anywhere",
    borderRadius: "36px",
    color: "#878484",
    backColor: "rgba(135, 132, 132, 0.1)",
    borderColor: "#878484",
    widthNormal: "275px",
    textFontSize: "11px",
    weighty: "500",
    specifyPadding: "1rem",
    margin: "0.5rem",
  },
  {
    text: "Serve a global audience with your business",
    borderRadius: "36px",
    color: "#323232",
    backColor: "transparent",
    borderColor: "#878484",
    widthNormal: "275px",
    textFontSize: "13px",
    weighty: "500",
    specifyPadding: "1rem",
    margin: "0.5rem",
  },
];

export default feature3;
