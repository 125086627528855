import axios from "axios";
import {
  ALL_FAQS_HOME_LOADING,
  ALL_FAQS_HOME_SUCCESS,
  ALL_FAQS_LOADING,
  ALL_FAQS_SUCCESS,
} from "../reducers/types";
import { toast } from "react-toastify";
import { apiBaseUrl } from "../../constants/url";

export const getAllFaqs = (query) => async (dispatch) => {
  dispatch({ type: ALL_FAQS_LOADING, payload: true });
  try {
    const response = await axios.get(
      `${apiBaseUrl}/faq/all/landing-page${query || ""}`
    );
    dispatch({ type: ALL_FAQS_LOADING, payload: null });
    dispatch({ type: ALL_FAQS_SUCCESS, payload: response.data });
    return response.data;
  } catch (err) {
    dispatch({ type: ALL_FAQS_LOADING, payload: null });
    return toast.error(err.response?.data?.message, {
      toastId: "Error",
    });
  }
};

export const getAllFaqsHome = () => async (dispatch) => {
  dispatch({ type: ALL_FAQS_HOME_LOADING, payload: true });
  try {
    const response = await axios.get(`${apiBaseUrl}/faq/all/home`);
    dispatch({ type: ALL_FAQS_HOME_LOADING, payload: null });
    dispatch({ type: ALL_FAQS_HOME_SUCCESS, payload: response.data });
    return response.data;
  } catch (err) {
    dispatch({ type: ALL_FAQS_HOME_LOADING, payload: null });
    return toast.error(err.response?.data?.message, {
      toastId: "Error",
    });
  }
};
