import React from "react";
import styled from "styled-components";
import CountUp from "react-countup";
import { InView } from "react-intersection-observer";
import appTheme from "../../constants/theme";

const Wrapper = styled.div`
  border-radius: 15px;
  background-color: ${(props) => props.backColor && props.backColor};
  padding: 1rem;
  width: 220px;
`;

const InnerWrapper = styled.div`
  background-color: #fff;
  border-radius: 26px;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  padding: 3rem 0;
`;

const CounterWrapper = styled.div`
  margin: 0;
`;

const Counter = styled(CountUp)`
  color: ${appTheme.COLORS.grey1};
  font-size: 2rem;
  font-weight: ${appTheme.WEIGHT.bold};
`;

const Desc = styled.p`
  color: #6b7280;
  font-size: 1rem;
  margin: 0;
  padding-top: 0.2rem;
  font-weight: ${appTheme.WEIGHT.small};
`;

function CounterCmp(props) {
  return (
    <>
      <Wrapper backColor={props.backColor}>
        <InnerWrapper>
          <InView>
            {({ inView, ref, entry }) => (
              <CounterWrapper ref={ref}>
                {inView ? (
                  <Counter
                    duration={props.duration}
                    suffix={props.suffix}
                    end={props.stat}
                  />
                ) : null}
              </CounterWrapper>
            )}
          </InView>
          <Desc>{props.desc}</Desc>
        </InnerWrapper>
      </Wrapper>
    </>
  );
}

export default CounterCmp;
