import React from "react";
import NavbarCmp from "../components/Navbar/NavbarCmp";
import PageHeaderCmp from "../components/PageHeaderCmp/PageHeaderCmp";
import CareerSubImg from "../assets/careersub-vector.svg";
import NewsletterCmp from "../components/NewsletterCmp/NewsletterCmp";
import FooterCmp from "../components/FooterCmp/FooterCmp";
import BlogCmp from "../components/BlogCmp/BlogCmp";

function Blog() {
  return (
    <>
      <NavbarCmp colored />
      <PageHeaderCmp
        padding="10rem"
        title="The Jessie Blog"
        description="Stories from the world’s leading digital asset platform where you have full control of your assets."
        backColor="rgba(251, 228, 236, 0.2)"
        backImage={CareerSubImg}
      />
      <BlogCmp />
      <NewsletterCmp backColor="#f9fafb" />
      <FooterCmp />
    </>
  );
}

export default Blog;
