import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import SingleProductCmp from "../SingleProductCmp/SingleProductCmp";
import GiftCardProduct from "../../assets/giftcard-product.png";
import BitcoinProduct from "../../assets/bitcoin-product.png";
import AirtimeProduct from "../../assets/airtime-product.png";
import PerfectMoneyProduct from "../../assets/perfect-money-product.png";

const ProductContainer = styled(Container)`
  padding: 4rem 4rem 2rem;

  @media only screen and (max-width: 580px) {
    padding: 2rem 1rem 2rem;
  }
`;

const ProductWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 1536px;
  margin: 0 auto;
`;

function Product() {
  return (
    <>
      <ProductContainer fluid>
        <ProductWrapper className="product-wrapper">
          <SingleProductCmp
            name="giftcard-trading"
            linkTo="giftcard"
            title="Enjoy Seamless Giftard Trading"
            desc="Buy and sell giftcards from over 35 countries aroound the world"
            image={GiftCardProduct}
            alt="Giftcard"
            styleObj={{ paddingTop: "2rem" }}
            backColor="rgba(82, 67, 170, 0.05)"
          />
          <SingleProductCmp
            name="trade-crypto"
            linkTo="crypto"
            title="Trade Crypto with Ease"
            desc="Store and convert your crypto to instant cash with ease"
            image={BitcoinProduct}
            alt="Crypto"
            styleObj={{
              position: "absolute",
              bottom: "0",
              left: "50%",
              marginLeft: "-131px",
            }}
            backColor="rgba(248, 244, 240, 0.5)"
          />
          <SingleProductCmp
            name="perfect-money"
            linkTo="giftcard"
            title="Perfect Money Deals"
            desc="Send and receive perfect money with ease"
            image={PerfectMoneyProduct}
            alt="Perfect Money"
            styleObj={{
              position: "absolute",
              bottom: "0",
              left: "50%",
              marginLeft: "-158.5px",
            }}
            backColor="#fdf4f4"
          />
          <SingleProductCmp
            linkTo="crypto"
            name="airtime-product"
            className="airtime-product"
            title="Renew on the go"
            desc="Pay bills with ease and convenience"
            image={AirtimeProduct}
            alt="Bills"
            styleObj={{
              position: "absolute",
              bottom: "0",
              left: "50%",
              marginLeft: "-216.99px",
            }}
            backColor="#faf8fb"
          />
        </ProductWrapper>
      </ProductContainer>
    </>
  );
}

export default Product;
