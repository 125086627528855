const banksName = [
  "AL-Barakah Microfinance Bank",
  "9 Payment Service Bank",
  "AB Microfinance Bank",
  "ABU Microfinance Bank",
  "AG Mortgage Bank",
  "AMJU Unique Microfinance Bank",
  "AMML MFB",
  "ASOSavings &amp;amp; Loans",
  "Abbey Mortgage Bank",
  "Above Only Microfinance Bank",
  "Access Bank",
  "AccessMobile",
  "Accion Microfinance Bank",
  "Addosser Microfinance Bank",
  "Adeyemi College Staff Microfinance Bank",
  "Al-Hayat Microfinance Bank",
  "Alekun Microfinance Bank",
  "Allworkers Microfinance Bank",
  "Alpha Kapital Microfinance Bank",
  "Apeks Microfinance Bank",
  "Astrapolaris Microfinance Bank",
  "Auchi Microfinance Bank",
  "BC Kash Microfinance Bank",
  "BRIDGEWAY MICROFINANCE BANK",
  "Baines Credit Microfinance Bank",
  "Baobab Microfinance Bank",
  "Boctrust Microfinance Bank",
  "Bosak Microfinance Bank",
  "Bowen Microfinance Bank",
  "Brent Mortgage Bank",
  "CEMCS Microfinance Bank",
  "CHANGHAN RTS MICROFINANCE BANK",
  "CIT Microfinance Bank",
  "Carbon",
  "Cellulant",
  "ChamsMobile",
  "Chikum Microfinance Bank",
  "Citi Bank",
  "Consumer Microfinance Bank",
  "Contec Global Infotech Limited (NowNow)",
  "Coronation Merchant Bank",
  "Covenant Microfinance Bank",
  "Credit Afrique Microfinance Bank",
  "Daylight Microfinance Bank",
  "ENaira",
  "Eartholeum",
  "EcoBank PLC",
  "EcoMobile",
  "Ecobank Xpress Account",
  "Ekondo MFB",
  "Emeralds Microfinance Bank",
  "Empire trust MFB",
  "Enterprise Bank",
  "Esan Microfinance Bank",
  "Eso-E Microfinance Bank",
  "Eyowo MFB",
  "FAST Microfinance Bank",
  "FBN Mortgages Limited",
  "FBNMobile",
  "FBNQUEST Merchant Bank",
  "FCMB Easy Account",
  "FEDETH MICROFINANCE BANK",
  "FET",
  "FFS Microfinance Bank",
  "FINATRUST MICROFINANCE BANK",
  "FSDH Merchant Bank",
  "Fidelity Bank",
  "Fidelity Mobile",
  "Fidfund Microfinance Bank",
  "Firmus MFB",
  "First Bank PLC",
  "First City Monument Bank",
  "First Generation Mortgage Bank",
  "First Royal Microfinance Bank",
  "Flutterwave Technology Solutions Limited",
  "Fortis Microfinance Bank",
  "FortisMobile",
  "Fullrange Microfinance Bank",
  "Futo Microfinance Bank",
  "GTMobile",
  "Gashua Microfinance Bank",
  "Gateway Mortgage Bank",
  "Globus Bank",
  "GoMoney",
  "Gowans Microfinance Bank",
  "GreenBank Microfinance Bank",
  "Greenville Microfinance Bank",
  "Greenwich Merchant Bank",
  "Grooming Microfinance Bank",
  "Guaranty Trust Bank",
  "Hackman Microfinance Bank",
  "Haggai Mortgage Bank Limited",
  "Hasal Microfinance Bank",
  "Hedonmark",
  "Heritage Bank",
  "HighStreet Microfinance Bank",
  "IBILE Microfinance Bank",
  "IRL Microfinance Bank",
  "Imo State Microfinance Bank",
  "Imperial Homes Mortgage Bank",
  "Infinity Microfinance Bank",
  "Infinity Trust Mortgage Bank",
  "Innovectives Kesh",
  "Intellifin",
  "Jaiz Bank",
  "Jubilee-Life Mortgage  Bank",
  "KCMB Microfinance Bank",
  "Kegow",
  "Keystone Bank",
  "Kuda",
  "La  Fayette Microfinance Bank",
  "Lagos Building Investment Company",
  "Lapo Microfinance Bank",
  "Lavender Microfinance Bank",
  "Letshego MFB",
  "Links Microfinance Bank",
  "Lovonus Microfinance Bank",
  "M36",
  "MAUTECH Microfinance Bank",
  "Mainstreet Microfinance Bank",
  "Malachy Microfinance Bank",
  "Manny Microfinance bank",
  "Midland Microfinance Bank",
  "Mint-Finex MICROFINANCE BANK",
  "Mkudi",
  "Money Trust Microfinance Bank",
  "MoneyBox",
  "Mutual Benefits Microfinance Bank",
  "Mutual Trust Microfinance Bank",
  "NIP Virtual Bank",
  "NIRSAL Microfinance Bank",
  "NPF MicroFinance Bank",
  "Nagarta Microfinance Bank",
  "Navy Microfinance Bank",
  "Ndiorah Microfinance Bank",
  "New Dawn Microfinance Bank",
  "New Prudential Bank",
  "Nova Merchant Bank",
  "Ohafia Microfinance Bank",
  "Okpoga Microfinance Bank",
  "Olabisi Onabanjo University Microfinance Bank",
  "Omoluabi savings and loans",
  "One Finance",
  "PALMPAY",
  "Paga",
  "Page Financials",
  "Parallex Bank",
  "Parkway-ReadyCash",
  "Parralex Microfinance bank",
  "PatrickGold Microfinance Bank",
  "PayAttitude Online",
  "Paycom",
  "PecanTrust Microfinance Bank",
  "Pennywise Microfinance Bank",
  "Personal Trust Microfinance Bank",
  "Petra Microfinance Bank",
  "Platinum Mortgage Bank",
  "Polaris bank",
  "PremiumTrust Bank",
  "ProvidusBank PLC",
  "Quickfund Microfinance Bank",
  "Rand merchant Bank",
  "Refuge Mortgage Bank",
  "Regent Microfinance Bank",
  "Reliance Microfinance Bank",
  "RenMoney Microfinance Bank",
  "Richway Microfinance Bank",
  "Royal Exchange Microfinance Bank",
  "Rubies Microfinance Bank",
  "Safe Haven MFB",
  "SafeTrust ",
  "Sagamu Microfinance Bank",
  "Seed Capital Microfinance Bank",
  "Sparkle",
  "Stanbic IBTC @ease wallet",
  "Stanbic IBTC Bank",
  "Standard Chaterted bank PLC",
  "Stanford Microfinance Bak",
  "Stellas Microfinance Bank",
  "Sterling Bank PLC",
  "Suntrust Bank",
  "TCF MFB",
  "TagPay",
  "Taj Bank Limited",
  "Tangerine Bank",
  "TeamApt",
  "TeasyMobile",
  "Titan Trust Bank",
  "Trident Microfinance Bank",
  "Trustbond Mortgage Bank",
  "Trustfund Microfinance Bank",
  "UNN MFB",
  "Uniben Microfinance Bank",
  "Unical Microfinance Bank",
  "Union Bank PLC",
  "United Bank for Africa",
  "Unity Bank PLC",
  "VFD Micro Finance Bank",
  "VTNetworks",
  "Verite Microfinance Bank",
  "Virtue Microfinance Bank",
  "Visa Microfinance Bank",
  "Wema Bank PLC",
  "Wetland Microfinance Bank",
  "Xslnce Microfinance Bank",
  "Yes Microfinance Bank",
  "Zenith bank PLC",
  "ZenithMobile",
  "Zinternet Nigera Limited",
  "e-Barcs Microfinance Bank",
  "eTranzact",
];

export { banksName };
