import React from "react";
import FooterCmp from "../components/FooterCmp/FooterCmp";
import NavbarCmp from "../components/Navbar/NavbarCmp";
import NewsletterCmp from "../components/NewsletterCmp/NewsletterCmp";
import PageHeaderCmp from "../components/PageHeaderCmp/PageHeaderCmp";
import PolicyContent from "../components/PolicyContentCmp/PolicyContent";

function Policy() {
  return (
    <>
      <NavbarCmp type="color" />
      <PageHeaderCmp
        title="Anti-Money Laundering Policy"
        backColor="rgba(208, 218, 228, 0.2)"
      />
      <PolicyContent />
      <NewsletterCmp backColor="#f9fafb" />
      <FooterCmp />
    </>
  );
}

export default Policy;
