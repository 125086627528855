import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import appTheme from "../../constants/theme";
import WhoIsCardCmp from "../WhoIsCardCmp/WhoIsCardCmp";
import MissionIcon from "../../assets/mission.svg";
import VisionIcon from "../../assets/vision.svg";

const WorkContainer = styled(Container)`
  background: #fff;
  padding: 3rem 0;
  text-align: center;
  background-size: cover;

  @media only screen and (max-width: 600px) {
    padding-top: 2rem;
  }
`;

const Wrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const Title = styled.h2`
  font-weight: ${appTheme.WEIGHT.mediumBold};
  font-size: 36px;
  color: ${appTheme.COLORS.grey1};

  @media only screen and (max-width: 800px) {
    font-size: 30px;
  }

  @media only screen and (max-width: 650px) {
    font-size: 25px;
  }

  @media only screen and (max-width: 450px) {
    font-size: 22px;
  }

  @media only screen and (max-width: 355px) {
    font-size: 18px;
  }
`;

const TitleSpan = styled.span`
  color: #016cba;
`;

const Text = styled.p`
  font-size: 1.3rem;
  font-weight: ${appTheme.WEIGHT.small3};
  color: #374151;
  padding-top: 1rem;

  @media only screen and (max-width: 900px) {
    font-size: 1rem;
  }
`;

const JessieWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

function WhoIsCmp(props) {
  return (
    <>
      <WorkContainer>
        <Wrapper>
          <Title>
            <TitleSpan>Who Is</TitleSpan> Jessie
          </Title>
          <Text>
            Jessie is a Fintech and E-Trading company that facilitates
            convenient ways of using and exchanging your digital assets as a
            means of providing payment solutions for our everyday transactions,
            speeding up the world of business with Defi, Crypto and Blockchain
            technology.
          </Text>
          <JessieWrapper>
            <WhoIsCardCmp
              iconImage={VisionIcon}
              iconBackColor="rgba(12, 58, 147, 0.3)"
              title="Vision"
              desc="Our vision is to be globally accepted and recognized as the most preferred and trusted digital asset entity across Africa and around the world."
            />
            <WhoIsCardCmp
              iconImage={MissionIcon}
              iconBackColor="rgba(24, 153, 60, 0.2)"
              title="Mission"
              desc="Our mission is to simplify the use of digital assets for endless possibilities through our seamless, reliable and secure trades."
            />
          </JessieWrapper>
        </Wrapper>
      </WorkContainer>
    </>
  );
}

export default WhoIsCmp;
