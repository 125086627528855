import {
  CREATE_FEEDBACK_ERROR,
  CREATE_FEEDBACK_LOADING,
  CREATE_FEEDBACK_SUCCESS,
} from "./types";

const initialState = {
  createFeedbackLoading: null,
  createFeedbackSuccess: null,
  createFeedbackError: null,
};

export default function feedbackReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_FEEDBACK_LOADING:
      return { ...state, createFeedbackLoading: action.payload };
    case CREATE_FEEDBACK_SUCCESS:
      return { ...state, createFeedbackSuccess: action.payload };
    case CREATE_FEEDBACK_ERROR:
      return { ...state, createFeedbackError: action.payload };
    default:
      return state;
  }
}
