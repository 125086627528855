import React from "react";
import styled from "styled-components";
import appTheme from "../../constants/theme";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import StarImg from "../../assets/star.svg";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
`;

const Image = styled(LazyLoadImage)``;

const Title = styled.h2`
  color: ${appTheme.COLORS.grey1};
  font-size: 2rem;
  font-weight: ${appTheme.WEIGHT.mediumBold};
  padding-left: 1rem;
  margin-bottom: 0;

  @media only screen and (max-width: 600px) {
    font-size: 1.2rem;
  }

  @media only screen and (max-width: 500px) {
    font-size: 1.1rem;
  }
`;

function TitleCmpWithStar(props) {
  return (
    <>
      <>
        <Wrapper>
          <Image src={StarImg} alt="Star" effect="blur" />
          <Title>{props.title}</Title>
        </Wrapper>
      </>
    </>
  );
}

export default TitleCmpWithStar;
