import React from "react";
import styled from "styled-components";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import appTheme from "../../constants/theme";

const Wrapper = styled.div`
  border: 1px solid #adb5bd;
  border-radius: 30px;
  padding: 2rem 1rem;
  text-align: left;
  width: 300px;
  margin: 1rem;
`;

const IconWrapper = styled.div`
  border-radius: 10px;
  background-color: ${(props) => props.iconBackColor && props.iconBackColor};
  width: 55px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconImg = styled(LazyLoadImage)``;

const Title = styled.h2`
  color: #000;
  font-weight: ${appTheme.WEIGHT.medium};
  font-size: 20px;
  padding-top: 1rem;
`;

const Desc = styled.p`
  font-weight: ${appTheme.WEIGHT.small2};
  color: #374151;
  font-size: 1rem;
  margin: 0;
`;

function WhoIsCardCmp(props) {
  return (
    <>
      <Wrapper>
        <IconWrapper iconBackColor={props.iconBackColor}>
          <IconImg src={props.iconImage} effect="blur" />
        </IconWrapper>
        <Title>{props.title}</Title>
        <Desc>{props.desc}</Desc>
      </Wrapper>
    </>
  );
}

export default WhoIsCardCmp;
