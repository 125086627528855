/* eslint-disable no-useless-escape */
import * as yup from "yup";
import "yup-phone";

const createOtcSchema = yup.object().shape({
  email: yup
    .string()
    .required("Provide an email address ")
    .email("Provide a valid email address"),
  name: yup.string().required("Provide your name"),
  phone: yup
    .string()
    .phone(undefined, undefined, "Invalid phone number")
    .required(),
  country: yup.string().required("Select a country"),
  cryptoType: yup.string().required("Select a crypto type"),
  walletAddress: yup.string().required("Paste your wallet address"),
  transactionAmount: yup.string().required("Select a transaction amount"),
  //   transactionType: yup.string().required("Select a transaction type"),
  accountNumber: yup
    .string()
    .required("Provide account number")
    .matches(/^\d+$/, "Only digit is allowed")
    .test(
      "length",
      "Provide 10 digits account number",
      (val) => val && val.length === 10
    ),
  accountName: yup.string().required("Provide your account name"),
  bankName: yup.string().required("Select a bank"),
});

export default createOtcSchema;
