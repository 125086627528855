const feature2 = [
  {
    text: "Seamless giftcard trading",
    borderRadius: "36px",
    color: "#D71920",
    backColor: "rgba(215, 25, 32, 0.1)",
    borderColor: "#D71920",
    widthNormal: "275px",
    textFontSize: "16px",
    weighty: "500",
    specifyPadding: "1rem",
    margin: "0.5rem auto",
  },
  {
    text: "Buy and sell giftcards",
    borderRadius: "36px",
    color: "#323232",
    backColor: "transaprent",
    borderColor: "#D71920",
    widthNormal: "275px",
    textFontSize: "16px",
    weighty: "500",
    specifyPadding: "1rem",
    margin: "0.5rem",
  },
  {
    text: "Exchange unused giftcard for value",
    borderRadius: "36px",
    color: "#323232",
    backColor: "transparent",
    borderColor: "#D71920",
    widthNormal: "275px",
    textFontSize: "15px",
    weighty: "500",
    specifyPadding: "1rem",
    margin: "0.5rem",
  },
  {
    text: "Experience the best perfect money deals",
    borderRadius: "36px",
    color: "#D71920",
    backColor: "rgba(215, 25, 32, 0.1)",
    borderColor: "#D71920",
    widthNormal: "275px",
    textFontSize: "13px",
    weighty: "500",
    specifyPadding: "1rem",
    margin: "0.5rem",
  },
  {
    text: "Buy and sell perfect money",
    borderRadius: "36px",
    color: "#D71920",
    backColor: "rgba(215, 25, 32, 0.1)",
    borderColor: "#D71920",
    widthNormal: "275px",
    textFontSize: "16px",
    weighty: "500",
    specifyPadding: "1rem",
    margin: "0.5rem",
  },
  {
    text: "Competitive low transaction fees",
    borderRadius: "36px",
    color: "#323232",
    backColor: "transparent",
    borderColor: "#D71920",
    widthNormal: "275px",
    textFontSize: "16px",
    weighty: "500",
    specifyPadding: "1rem",
    margin: "0.5rem",
  },
];

export default feature2;
