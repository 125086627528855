import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import appTheme from "../../constants/theme";
import FooterVector from "../../assets/footer-vector.svg";
import LogoFooter from "../../assets/jessie-logo-footer.svg";
import LogoFooter2 from "../../assets/jessie-logo-footer-2.svg";
import GooglePlay from "../../assets/google-play-footer.svg";
import AppleStore from "../../assets/apple-store-footer.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import {
  InstagramAlt,
  Facebook,
  Linkedin,
  Youtube,
  Medium,
  Telegram,
  Twitter,
} from "@styled-icons/boxicons-logos";
import { Link } from "react-scroll";
import { Link as LinkR } from "react-router-dom";

const FooterContainer = styled(Container)`
  /* background-color: ${appTheme.COLORS.bluePrimary}; */
  background: url(${FooterVector}), ${appTheme.COLORS.bluePrimary} no-repeat;
  padding: 4rem 4rem 0rem;
  border-radius: 100px 100px 0px 0px;
  background-size: cover;

  @media only screen and (max-width: 596px) {
    padding: 4rem 3rem 0rem;
    border-radius: unset;
  }

  @media only screen and (max-width: 400px) {
    padding: 4rem 2rem 0rem;
  }
`;

const FooterWrapper = styled.div`
  max-width: 1536px;
  margin: 0 auto;
`;

const FirstRow = styled.div`
  display: flex;
  flex-direction: column;
`;

const CompanyLogo = styled(LazyLoadImage)`
  object-fit: cover;
`;

const CompanyAbout = styled.p`
  color: ${appTheme.COLORS.white};
  padding: 1rem 0;
  font-size: ${appTheme.SIZES.body2};
  font-weight: ${appTheme.WEIGHT.small};
`;

const Line = styled.hr`
  width: 100%;
  height: 1px;
  color: #c4c4c4;
  background-color: #c4c4c4;
  margin: 0;
`;

const SecondRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 2rem 0 4rem;
`;

const ColDiv = styled.div`
  flex: 25%;
  max-width: 250px;

  @media only screen and (max-width: 1114px) {
    max-width: 500px;
  }

  @media only screen and (max-width: 939px) {
    max-width: 300px;
    flex: 33%;
    padding-bottom: 2rem;
  }

  @media only screen and (max-width: 625px) {
    flex: 50%;
  }

  @media only screen and (max-width: 596px) {
    flex: 50%;
    max-width: 250px;
  }

  @media only screen and (max-width: 480px) {
    flex: 60%;
  }
`;

const ColDivTitle = styled.h2`
  font-weight: ${appTheme.WEIGHT.bold};
  color: ${appTheme.COLORS.white};
  font-size: ${appTheme.SIZES.body1};
`;

const ItemWrapper = styled.div``;

const ItemList = styled.li`
  list-style-type: none;
  padding: 0 0 0.2rem;
`;

const ItemLink = styled.a`
  text-decoration: none;
  font-size: ${appTheme.SIZES.body3};
  font-weight: ${appTheme.WEIGHT.small2};
  color: ${appTheme.COLORS.white};
  cursor: pointer;

  &:hover {
    color: ${appTheme.COLORS.white};
  }
`;

const ThirdRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 2rem 0 4rem;
`;

const CopyrightFooter = styled.p`
  color: ${appTheme.COLORS.white};
  font-size: 14px;
  margin: 0;
  font-weight: ${appTheme.WEIGHT.small};
  padding: 0.1rem 0;
`;

const Address = styled.p`
  color: ${appTheme.COLORS.white};
  font-size: 15px;
  margin: 0;
  font-weight: ${appTheme.WEIGHT.small};
  padding: 0.1rem 0 1rem;
`;

const SocialWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1px;
  padding-top: 0.7rem;
`;

const StoreWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 0;
`;

const StoreImg = styled(LazyLoadImage)`
  margin: 0 0.3rem 0.3rem 0;
  cursor: pointer;
`;

const IconContainer = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #fff;
  background-color: transparent;
  cursor: pointer;
  margin: 0 0.3rem 0.3rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function FooterCmp() {
  const openLink = (link) => {
    return window.open(link, "_blank");
  };

  const navigate = useNavigate();
  return (
    <>
      <FooterContainer fluid>
        <FooterWrapper>
          <FirstRow>
            <CompanyLogo src={LogoFooter} alt="Jessie" effect="blur" />
            <CompanyAbout>
              Jessie is set to make a difference hereby creating a new and
              sustainable path to innovate, challenge and disrupt the old norm
              in the Digital asset space for the present and future generations.
            </CompanyAbout>
          </FirstRow>
          <Line />
          <SecondRow>
            <ColDiv>
              <ColDivTitle>Product</ColDivTitle>
              <ItemWrapper>
                <ItemList>
                  <ItemLink
                    as={Link}
                    onClick={() => navigate("/")}
                    to="giftcard"
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                    Giftcard
                  </ItemLink>
                </ItemList>
                <ItemList>
                  <ItemLink
                    as={Link}
                    onClick={() => navigate("/")}
                    to="crypto"
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                    Crypto
                  </ItemLink>
                </ItemList>
                <ItemList>
                  <ItemLink
                    as={Link}
                    onClick={() => navigate("/")}
                    to="giftcard"
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                    Perfect Money
                  </ItemLink>
                </ItemList>
                <ItemList>
                  <ItemLink
                    as={Link}
                    onClick={() => navigate("/")}
                    to="crypto"
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                    Renew
                  </ItemLink>
                </ItemList>
                <ItemList>
                  <ItemLink
                    as={Link}
                    onClick={() => navigate("/otc")}
                    to="crypto"
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                    OTC
                  </ItemLink>
                </ItemList>
                <ItemList>
                  <ItemLink as={LinkR} to="/">
                    Jessie Merch (Coming Soon)
                  </ItemLink>
                </ItemList>
              </ItemWrapper>
            </ColDiv>
            <ColDiv>
              <ColDivTitle>Our Company</ColDivTitle>
              <ItemWrapper>
                <ItemList>
                  <ItemLink as={LinkR} to="/about">
                    About Us
                  </ItemLink>
                </ItemList>
                <ItemList>
                  <ItemLink as={LinkR} to="/careers">
                    Careers
                  </ItemLink>
                </ItemList>
                <ItemList>
                  <ItemLink
                    as={Link}
                    onClick={() => navigate("/")}
                    to="business"
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                    Jessie Business
                  </ItemLink>
                </ItemList>
              </ItemWrapper>
            </ColDiv>
            <ColDiv>
              <ColDivTitle>Resources</ColDivTitle>
              <ItemWrapper>
                <ItemList>
                  <ItemLink as={LinkR} to="/blog">
                    Blog
                  </ItemLink>
                </ItemList>
                <ItemList>
                  <ItemLink as={LinkR} to="/resources">
                    Help Center
                  </ItemLink>
                </ItemList>
                <ItemList>
                  <ItemLink as={LinkR} to="/faq">
                    FAQ
                  </ItemLink>
                </ItemList>
              </ItemWrapper>
            </ColDiv>
            <ColDiv>
              <ColDivTitle>Legal</ColDivTitle>
              <ItemWrapper>
                <ItemList>
                  <ItemLink as={LinkR} to="/privacy-policy">
                    Privacy Policy
                  </ItemLink>
                </ItemList>
                <ItemList>
                  <ItemLink as={LinkR} to="/terms-of-service">
                    Terms & Conditions
                  </ItemLink>
                </ItemList>
                <ItemList>
                  <ItemLink as={LinkR} to="/anti-money-laundering-policy">
                    Anti-Money Laundering Policy
                  </ItemLink>
                </ItemList>
              </ItemWrapper>
            </ColDiv>
          </SecondRow>
          <Line />
          <ThirdRow>
            <ColDiv>
              <CompanyLogo
                src={LogoFooter2}
                alt="Jessie"
                effect="blur"
                style={{ paddingBottom: "1.5rem" }}
              />
              <CopyrightFooter>© 2022 Jessie.</CopyrightFooter>
              <CopyrightFooter>All Rights Reserved</CopyrightFooter>
            </ColDiv>
            <ColDiv>
              <ColDivTitle>Contact</ColDivTitle>
              <ItemWrapper>
                <ItemList>
                  <ItemLink href="mailto:hello@jessiepay.co">
                    hello@jessiepay.co
                  </ItemLink>
                </ItemList>
                <ItemList>
                  <ItemLink href="mailto:support@jessiepay.co">
                    support@jessiepay.co
                  </ItemLink>
                </ItemList>
              </ItemWrapper>
              <SocialWrapper>
                <IconContainer
                  onClick={() => {
                    openLink("https://instagram.com/jessiepay.co");
                  }}
                >
                  <InstagramAlt size={20} color="#fff" />
                </IconContainer>
                <IconContainer
                  onClick={() => {
                    openLink("https://twitter.com/jessiepay_co");
                  }}
                >
                  <Twitter size={20} color="#fff" />
                </IconContainer>
                <IconContainer
                  onClick={() => {
                    openLink("https://www.facebook.com/jessiepay.co/");
                  }}
                >
                  <Facebook size={20} color="#fff" />
                </IconContainer>
                <IconContainer
                  onClick={() => {
                    openLink("https://www.linkedin.com/company/jessie-com-ng");
                  }}
                >
                  <Linkedin size={20} color="#fff" />
                </IconContainer>
                <IconContainer
                  onClick={() => {
                    openLink("https://t.me/+-_JJTBv6nNI4ODY0");
                  }}
                >
                  <Telegram size={20} color="#fff" />
                </IconContainer>
                <IconContainer
                  onClick={() => {
                    openLink("https://link.medium.com/GsGUUEFebob");
                  }}
                >
                  <Medium size={20} color="#fff" />
                </IconContainer>
                <IconContainer
                  onClick={() => {
                    openLink(
                      "https://www.youtube.com/channel/UCEo5YaKpIrdm3FrtALCDFzQ"
                    );
                  }}
                >
                  <Youtube size={20} color="#fff" />
                </IconContainer>
              </SocialWrapper>
              <StoreWrapper>
                <StoreImg src={GooglePlay} alt="Playstore" />
                <StoreImg src={AppleStore} alt="Apple store" />
              </StoreWrapper>
            </ColDiv>
            <ColDiv>
              <ColDivTitle>Nigeria</ColDivTitle>
              <Address>
                12 Ebony road, Orazi, Port Harcourt, Rivers State. Nigeria
              </Address>
            </ColDiv>
            <ColDiv>
              <ColDivTitle>China</ColDivTitle>
              <Address>
                No.328 Chenggang Building, Huixing Xingke Avenue, Yubei
                District, Chongqing.
              </Address>
            </ColDiv>
          </ThirdRow>
        </FooterWrapper>
      </FooterContainer>
    </>
  );
}

export default FooterCmp;
