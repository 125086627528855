import axios from "axios";
import { toast } from "react-toastify";
import { CREATE_OTC_ERROR, CREATE_OTC_LOADING } from "../reducers/types";
import { apiBaseUrl } from "../../constants/url";

export const createOtc = (data) => async (dispatch) => {
  dispatch({ type: CREATE_OTC_LOADING, payload: true });
  dispatch({ type: CREATE_OTC_ERROR, payload: null });
  try {
    const response = await axios.post(`${apiBaseUrl}/otc/create-otc`, data);
    dispatch({ type: CREATE_OTC_LOADING, payload: null });

    if (response.data.status !== 201) {
      return toast.error(response.data.message);
    }
    return response.data;
  } catch (err) {
    dispatch({ type: CREATE_OTC_LOADING, payload: null });

    if (err.response.status === 422) {
      return toast.error(`Validation error`);
    } else {
      return toast.error(err.response?.data?.message);
    }
  }
};
