import React from "react";
import CareerSubImg from "../assets/careersub-vector.svg";
import FaqCmp from "../components/FaqCmp/FaqCmp";
import FooterCmp from "../components/FooterCmp/FooterCmp";
import NavbarCmp from "../components/Navbar/NavbarCmp";
import NewsletterCmp from "../components/NewsletterCmp/NewsletterCmp";
import PageHeaderCmp from "../components/PageHeaderCmp/PageHeaderCmp";

function Faq() {
  return (
    <>
      <NavbarCmp colored />
      <PageHeaderCmp
        padding="10rem"
        title="Frequently asked questions"
        description="Get answers to any question or inquiry you might have"
        backColor="rgba(12, 122, 147, 0.1)"
        backImage={CareerSubImg}
      />
      <FaqCmp />
      <NewsletterCmp backColor="#f9fafb" />
      <FooterCmp />
    </>
  );
}

export default Faq;
