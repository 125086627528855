import React from "react";
import styled from "styled-components";
import appTheme from "../../constants/theme";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const Wrapper = styled.div`
  border-radius: 16px;
  position: relative;
  padding: 0;
  margin: 0;
  /* background-size: contain;
  width: 100%; */

  &:hover {
    box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.13);
  }
`;

const HeroImg = styled(LazyLoadImage)`
  width: 100%;
  background-size: cover;
  padding: 0;
  margin: 0;
`;

const TextWrapper = styled.div`
  background-color: #fff;
  width: 100%;
  /* padding: 1rem 1.5rem; */
  bottom: 0;
  left: 0;
  position: absolute;
  height: 100px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0px 0px 16px 16px;
`;

const Title = styled.h2`
  color: ${appTheme.COLORS.grey1};
  font-weight: ${appTheme.WEIGHT.mediumBold};
  font-size: 20px;
`;

const Desc = styled.p`
  color: #adb5bd;
  font-size: 1rem;
`;

function SuperHeroCardCmp(props) {
  return (
    <>
      <Wrapper>
        <HeroImg src={props.img} effect="blur" />
        <TextWrapper>
          <Title>{props.name}</Title>
          <Desc>{props.role}</Desc>
        </TextWrapper>
      </Wrapper>
    </>
  );
}

export default SuperHeroCardCmp;
