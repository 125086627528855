import React from "react";
import styled from "styled-components";
import appTheme from "../../constants/theme";
import { Link } from "react-scroll";

const Wrapper = styled.div`
  width: 185px;
  height: 160px;
  padding: 1.5rem 1rem 1rem;
  background-color: ${(props) => props.backColor && props.backColor};
  border-radius: 15px;
  cursor: pointer;
  margin: 1rem;
  text-align: left;
  text-decoration: none;

  &:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  }
`;

const Title = styled.h2`
  color: ${appTheme.COLORS.grey1};
  font-size: 1rem;
  font-weight: ${appTheme.WEIGHT.medium};
  margin: 0;
  padding-top: 1rem;
`;

const Desc = styled.p`
  font-size: 14px;
  color: #374151;
  font-weight: ${appTheme.WEIGHT.small2};
  margin: 0;
  padding-top: 1rem;
`;

function TicketCardCmp(props) {
  return (
    <>
      <Wrapper
        to={props.inline && props.to}
        as={props.inline && Link}
        spy={props.inline && true}
        smooth={props.inline && true}
        duration={props.inline && 500}
        onClick={props.onClick}
        backColor={props.backColor}
      >
        <Title>{props.title}</Title>
        <Desc>{props.desc}</Desc>
      </Wrapper>
    </>
  );
}

export default TicketCardCmp;
