import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import BackCmp from "../BackCmp/BackCmp";
import FeedbackSvg from "../../assets/feedback-svg.svg";
import appTheme from "../../constants/theme";
import CustomInputCmp from "../InputsCmp/CustomInputCmp";
import CustomTextAreaCmp from "../InputsCmp/CustomTextAreaCmp";
import SolidButton from "../Buttons/SolidButton";
import ReactStars from "react-rating-stars-component";
import "./FeedbackCmp.css";
import { Formik } from "formik";
import { toast } from "react-toastify";
import createFeedbackSchema from "../../validators/createFeedbackValidator";
import { createFeedback } from "../../redux/actions/feedbackAction";
import { connect } from "react-redux";
import SpinnerCmp from "../SpinnerCmp/SpinnerCmp";

const FeedbackContainer = styled(Container)`
  background: rgba(225, 235, 245, 0.3);
  background: url(${FeedbackSvg}), rgba(225, 235, 245, 0.3) no-repeat;
  padding: 5rem 0 3rem;
  text-align: center;
  background-size: cover;
`;

const FeedbackFormContainer = styled.div`
  margin: 2rem auto;
  max-width: 900px;
`;

const Wrapper = styled.div`
  background-color: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  /* border-radius: 30px; */
  padding: 3rem 2rem;
  text-align: left;
`;

const FeedbackTitle = styled.h2`
  color: ${appTheme.COLORS.grey1};
  font-size: 2rem;
  font-weight: ${appTheme.WEIGHT.mediumBold};
  padding: 1rem 0 2rem;
  text-align: left;
`;

const FormWrapper = styled.div`
  padding-top: 1.5rem;
`;

const FeedbackText = styled.h2`
  color: ${appTheme.COLORS.grey1};
  font-weight: ${appTheme.WEIGHT.small2};
  font-size: 14px;
  text-align: left;
`;

const RatingWrapper = styled.div`
  width: fit-content;
  padding: 0.7rem 0 1.5rem;
`;

const InputWrapper = styled.div`
  margin: 1.5rem 0;
`;

const ButtonWrapper = styled.div`
  text-align: right;
`;

const TitleWrapper = styled.div`
  @media only screen and (max-width: 915px) {
    margin-left: 1rem;
  }
`;

function FeedbackCmp({ loading, setCreateFeedback }) {
  return (
    <>
      <FeedbackContainer fluid>
        <FeedbackFormContainer>
          <TitleWrapper>
            <FeedbackText style={{ marginBottom: "0rem" }}>
              Rate your experience with our product.
            </FeedbackText>
            <FeedbackTitle>Leave a feedback</FeedbackTitle>
          </TitleWrapper>
          <Wrapper>
            <BackCmp text="Back to help centre" linkTo="/resources" />
            <FormWrapper>
              <Formik
                validationSchema={createFeedbackSchema}
                initialValues={{
                  email: "",
                  rating: 0,
                  name: "",
                  review: "",
                }}
                onSubmit={async (values, { resetForm }) => {
                  const response = await setCreateFeedback(
                    values.name
                      ? {
                          ...values,
                        }
                      : {
                          email: values.email,
                          rating: values.rating,
                          review: values.review,
                        }
                  );

                  if (
                    response &&
                    response.status === 200 &&
                    response.message === "Feedback submitted successfully"
                  ) {
                    resetForm();
                    // setFieldValue("rating", 0);
                    return toast.success("Thanks for your feedback", {
                      toastId: "Feedback",
                    });
                  }
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  values,
                  errors,
                  setFieldValue,
                }) => (
                  <>
                    <form noValidate onSubmit={handleSubmit}>
                      <FeedbackText>
                        Rate your experience with our product.
                      </FeedbackText>
                      <RatingWrapper
                        style={{
                          border: `${errors.rating ? "1px solid red" : ""}`,
                        }}
                      >
                        <ReactStars
                          classNames={`jessie-rating`}
                          count={5}
                          onChange={(rating) => {
                            setFieldValue("rating", rating);
                          }}
                          value={values.rating}
                          size={20}
                          isHalf={false}
                          color={"rgba(173, 181, 189, 0.3)"}
                          activeColor="#ffd700"
                        />
                      </RatingWrapper>
                      <FeedbackText>
                        Anything else that can be improved?
                      </FeedbackText>
                      <InputWrapper>
                        <CustomInputCmp
                          background="#fff"
                          required={true}
                          type="email"
                          name="email"
                          placeholder="Enter your email address (required)"
                          values={values}
                          onChange={handleChange}
                          message={`${errors["email"] ? errors["email"] : ""}`}
                          showMessage={true}
                        />
                      </InputWrapper>
                      <InputWrapper>
                        <CustomInputCmp
                          background="#fff"
                          required={false}
                          type="text"
                          name="name"
                          placeholder="Enter your name (optional)"
                          values={values}
                          onChange={handleChange}
                          message={`${errors["name"] ? errors["name"] : ""}`}
                          showMessage={true}
                        />
                      </InputWrapper>
                      <InputWrapper>
                        <CustomTextAreaCmp
                          background="#fff"
                          as="textarea"
                          rows={4}
                          required={true}
                          type="text"
                          name="review"
                          placeholder="Describe your review"
                          values={values}
                          onChange={handleChange}
                          message={`${
                            errors["review"] ? errors["review"] : ""
                          }`}
                          showMessage={true}
                        />
                      </InputWrapper>
                      <ButtonWrapper>
                        <SolidButton
                          text={
                            loading ? (
                              <>
                                <SpinnerCmp
                                  enabled={true}
                                  color={appTheme.COLORS.bluePrimary}
                                  size={20}
                                  secondaryColor={appTheme.COLORS.white}
                                />
                              </>
                            ) : (
                              "Submit Feedback"
                            )
                          }
                          margin="2rem 0"
                          type="submit"
                          weighty="500"
                          textFontSize="16px"
                          specifyPadding="1rem 1.5rem"
                          color="#374151"
                          borderRadius="8px"
                          backColor="#FFB140"
                          maxWidth="190px"
                          widthWebkit="100%"
                          widthMoz="100%"
                          widthNormal="100%"
                          backgroundHoverColor="#FFB140"
                          textHoverColor="#374151"
                          disabled={loading ? true : false}
                        />
                      </ButtonWrapper>
                    </form>
                  </>
                )}
              </Formik>
            </FormWrapper>
          </Wrapper>
        </FeedbackFormContainer>
      </FeedbackContainer>
    </>
  );
}

const mapState = (state) => ({
  loading: state.feedback.createFeedbackLoading,
});

const mapDispatch = (dispatch) => ({
  setCreateFeedback: (data) => dispatch(createFeedback(data)),
});

export default connect(mapState, mapDispatch)(FeedbackCmp);
