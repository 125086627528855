import React, { useRef, useState } from "react";
import styled from "styled-components";
import { Times } from "@styled-icons/fa-solid";
import { Link } from "react-router-dom";
import SolidButton from "../Buttons/SolidButton";
import appTheme from "../../constants/theme";
import { DownArrow, UpArrow } from "@styled-icons/boxicons-solid";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import otc from "../../assets/otc.png";
import giftcard from "../../assets/giftcard.png";
import crypto from "../../assets/crypto.png";
import pm from "../../assets/perfect-money.png";
import airtime from "../../assets/airtime-cash.png";
import renew from "../../assets/renew.png";
import help from "../../assets/help.png";
import faq from "../../assets/faq.png";
import blog from "../../assets/blog.png";

const SidebarContainer = styled.div`
  display: none;

  @media only screen and (max-width: 920px) {
    display: block;
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.07);
    padding-bottom: 2rem;
    background: #fff;
    align-items: center;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out;
    opacity: ${(props) => (props.isOpen ? "100%" : "0")};
    top: ${(props) => (props.isOpen ? "0" : "-199%")};
  }
`;

const CloseIconWrapper = styled.div`
  position: absolute;
  top: 1rem;
  right: 1.5rem;
  background: transparent;
  cursor: pointer;
`;

const NavbarWrapper = styled.div`
  color: #fff;
  padding-left: 2rem;
  padding-top: 1rem;
  height: 70%;
`;

const NavbarMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  height: 100%;
`;

const NavItem = styled.div``;

const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  text-decoration: none;
  list-style: none;
  transition: 0.2s ease-in-out;
  text-decoration: none;
  color: ${appTheme.COLORS.blueSecondary};

  &:hover,
  &:active {
    color: ${appTheme.COLORS.secondary};
    transition: 0.2s ease-in-out;
  }
`;

const NavLink2 = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  text-decoration: none;
  list-style: none;
  transition: 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  color: ${appTheme.COLORS.blueSecondary};

  &:hover,
  &:active {
    color: ${appTheme.COLORS.secondary};
    transition: 0.2s ease-in-out;
  }
`;

const NavButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: fit-content;
  align-items: space-between;
`;

const MenuSpan = styled.span``;

const DropdownWrapper = styled.div`
  width: 200px;
  border-radius: 10px;
  background-color: ${appTheme.COLORS.white};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.09);
  position: absolute;
  left: 50px;
  /* top: 35px; */
  display: none;
  z-index: 10;

  @media only screen and (max-width: 920px) {
    display: block;
  }
`;

const DropdownItemContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem 1rem;
  flex-wrap: wrap;
`;

const EachItemWrapper = styled.a`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1rem 0.5rem;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background: rgba(196, 196, 196, 0.3);
    border-radius: 6px;
  }
`;

const ItemImg = styled(LazyLoadImage)``;

const ItemText = styled.h2`
  font-weight: 500;
  font-size: 15px;
  margin: 0;
  padding-left: 0.5rem;
  color: #333333;
`;

function SidebarCmp(props) {
  const { pathname } = window.location;
  const ref = useRef();

  const [productClick, setProductClick] = useState(false);
  const [resourceClick, setResourceClick] = useState(false);

  const handleProductClick = () => {
    setProductClick(!productClick);
    setResourceClick(false);
  };

  const handleResourceClick = () => {
    setResourceClick(!resourceClick);
    setProductClick(false);
  };

  const globalCloseClick = () => {
    props.toggle();
    setProductClick(false);
    setResourceClick(false);
  };

  return (
    <>
      <SidebarContainer
        isOpen={props.isOpen}
        // onClick={() => {
        //   setProductClick(false);
        //   setResourceClick(false);
        // }}
      >
        <CloseIconWrapper onClick={globalCloseClick}>
          <Times size={24} color="#333333" />
        </CloseIconWrapper>
        <NavbarWrapper>
          <NavbarMenu>
            <NavItem>
              <NavLink to="/about">About</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/careers">Careers</NavLink>
            </NavItem>
            <NavItem
              onClick={handleProductClick}
              click={productClick}
              style={{ position: "relative" }}
            >
              <NavLink2>
                Products &nbsp;
                {productClick ? <UpArrow size={12} /> : <DownArrow size={12} />}
              </NavLink2>
              {/* dropdown */}
              {productClick && (
                <DropdownWrapper click={productClick} ref={ref}>
                  <DropdownItemContainer>
                    <EachItemWrapper onClick={globalCloseClick} href="/otc">
                      <ItemImg src={otc} alt="OTC" />
                      <ItemText>OTC</ItemText>
                    </EachItemWrapper>
                    <EachItemWrapper
                      onClick={globalCloseClick}
                      href={`${pathname !== "/" ? `/` : "#giftcard-trading"}`}
                    >
                      <ItemImg src={giftcard} alt="Giftcards" />
                      <ItemText>Giftcards</ItemText>
                    </EachItemWrapper>
                    <EachItemWrapper
                      onClick={globalCloseClick}
                      href={`${pathname !== "/" ? `/` : "#trade-crypto"}`}
                    >
                      <ItemImg src={crypto} alt="Cryptocurrency" />
                      <ItemText>Cryptocurrency</ItemText>
                    </EachItemWrapper>
                    <EachItemWrapper
                      onClick={globalCloseClick}
                      href={`${pathname !== "/" ? `/` : "#perfect-money"}`}
                    >
                      <ItemImg src={pm} alt="Perfect Money" />
                      <ItemText>Perfect Money</ItemText>
                    </EachItemWrapper>
                    <EachItemWrapper
                      onClick={globalCloseClick}
                      href={`${pathname !== "/" ? `/` : "#airtime-product"}`}
                    >
                      <ItemImg src={renew} alt="Renew" />
                      <ItemText>Renew</ItemText>
                    </EachItemWrapper>
                    <EachItemWrapper
                      onClick={globalCloseClick}
                      href={`${pathname !== "/" ? `/` : "#airtime-product"}`}
                    >
                      <ItemImg src={airtime} alt="Airtime" />
                      <ItemText>Airtime to cash</ItemText>
                    </EachItemWrapper>
                  </DropdownItemContainer>
                </DropdownWrapper>
              )}
            </NavItem>
            <NavItem>
              <NavLink to="/market">Markets</NavLink>
            </NavItem>
            <NavItem>
              <NavLink2
                onClick={handleResourceClick}
                click={resourceClick}
                style={{ position: "relative" }}
              >
                Resources &nbsp;
                <MenuSpan>
                  {resourceClick ? (
                    <UpArrow size={12} />
                  ) : (
                    <DownArrow size={12} />
                  )}
                </MenuSpan>
              </NavLink2>
              {resourceClick && (
                <DropdownWrapper click={resourceClick}>
                  <DropdownItemContainer>
                    <EachItemWrapper
                      onClick={globalCloseClick}
                      as={Link}
                      to="/resources"
                    >
                      <ItemImg src={help} alt="Help Center" />
                      <ItemText>Help Center</ItemText>
                    </EachItemWrapper>
                    <EachItemWrapper
                      onClick={globalCloseClick}
                      as={Link}
                      to="/faq"
                    >
                      <ItemImg src={faq} alt="FAQs" />
                      <ItemText>FAQs</ItemText>
                    </EachItemWrapper>
                    <EachItemWrapper
                      onClick={globalCloseClick}
                      as={Link}
                      to="/blog"
                    >
                      <ItemImg src={blog} alt="Blog" />
                      <ItemText>Blog</ItemText>
                    </EachItemWrapper>
                  </DropdownItemContainer>
                </DropdownWrapper>
              )}
            </NavItem>
          </NavbarMenu>
          <NavButtonWrapper>
            <SolidButton
              text="Sign in"
              type="submit"
              weighty="500"
              textFontSize="14px"
              specifyPadding="0.8rem 1.4rem"
              margin={props.isOpen ? "0.5rem 0" : "0 0.5rem"}
              color="#333333"
              borderColor="#636363"
              borderRadius="24px"
              backColor="#fff"
              textHoverColor="#fff"
              onClick={() => {
                window.open("https://app.jessiepay.co/login");
              }}
            />
            <SolidButton
              text="Create Account"
              type="button"
              weighty="500"
              textFontSize="14px"
              specifyPadding="0.8rem 1.4rem"
              color="#374151"
              borderRadius="22px"
              backColor="#FFB140"
              backgroundHoverColor="#FFB140"
              textHoverColor="#374151"
              margin={props.isOpen ? "0.5rem 0" : "0 0.5rem"}
              onClick={() => {
                window.open("https://app.jessiepay.co/get-started");
              }}
            />
          </NavButtonWrapper>
        </NavbarWrapper>
      </SidebarContainer>
    </>
  );
}

export default SidebarCmp;
