import React from "react";
import styled from "styled-components";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import BackImage from "../../assets/back.svg";
import appTheme from "../../constants/theme";
import { useNavigate } from "react-router-dom";

const BackWrapper = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  padding-bottom: 2rem;
`;

const BackImg = styled(LazyLoadImage)``;

const BackText = styled.h2`
  font-weight: ${appTheme.WEIGHT.small2};
  font-size: 15px;
  color: #016cba;
  margin: 0;
  padding-left: 0.5rem;
`;

function BackCmp(props) {
  const navigate = useNavigate();
  return (
    <>
      <BackWrapper onClick={() => navigate(props.linkTo || -1)}>
        <BackImg src={BackImage} alt="Back" effect="blur" />
        <BackText>{props.text}</BackText>
      </BackWrapper>
    </>
  );
}

export default BackCmp;
