import React from "react";
import FeedbackCmp from "../components/FeedbackCmp/FeedbackCmp";
import FooterCmp from "../components/FooterCmp/FooterCmp";
import NavbarCmp from "../components/Navbar/NavbarCmp";
import NewsletterCmp from "../components/NewsletterCmp/NewsletterCmp";

function Feedback() {
  return (
    <>
      <NavbarCmp colored />
      <FeedbackCmp />
      <NewsletterCmp backColor="rgba(225, 235, 245, 0.3)" />
      <FooterCmp />
    </>
  );
}

export default Feedback;
