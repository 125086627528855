import { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import CollapsibleCmp from "../CollapsibleCmp/CollapsibleCmp";
import PaginationCmp from "../PaginationCmp/PaginationCmp";
import { getAllFaqs } from "../../redux/actions/faqAction";
import { connect } from "react-redux";

const FaqContainer = styled(Container)`
  background: rgba(225, 235, 245, 0.3);
  padding: 5rem 0 5rem;

  @media only screen and (max-width: 600px) {
    padding: 4rem 0;
  }
`;

const FaqWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 20px;
  padding: 3rem 2rem;

  @media only screen and (max-width: 600px) {
    padding: 2rem 1rem;
  }
`;

const FaqHolder = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const PaginationWrapper = styled.div`
  text-align: center;
  padding-top: 3rem;
`;

const EachFaqWrapper = styled.div`
  margin: 1rem;
  flex: 45%;

  @media only screen and (max-width: 639px) {
    flex: unset;
  }
`;

function FaqCmp({ setGetAllFaqs }) {
  const [faqData, setFaqData] = useState({});
  const getAllFaqs = async (query) => {
    const response = await setGetAllFaqs(query);

    setFaqData(response.data);
  };
  useEffect(() => {
    getAllFaqs(`?page=${1}&limit=${16}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // const collapsibleData = [
  //   {
  //     collapseTitle: "How can I open a crypto account",
  //     desc: "With a Jessiepay account, you already have your own Jessie CRYPTO Wallet where you can send, receive and save your CRYPTO.",
  //   },
  //   {
  //     collapseTitle: "How Do I Access My Account?",
  //     desc: "Click on “Login” at the top of the webpage and Enter your registered email address or Username, then click on 'Sign in'",
  //   },
  //   {
  //     collapseTitle: "How to trade Physical Card?",
  //     desc: "For smooth confirmations, it is recommended that the physical card is sent in full, the picture showing all four corners of the card, with pin scratched",
  //   },
  //   {
  //     collapseTitle: "Why can we not trade on social media?",
  //     desc: "Those are not the appropriate platforms for our type of transactions. For secure, safe and seamless transactions, you can log in to our website; Jessiepay.co or download the mobile App, to carry out all your transactions.",
  //   },
  //   {
  //     collapseTitle: "How do I sell Gift cards?",
  //     desc: "Those are not the appropriate platforms for our type of transactions. For secure, safe and seamless transactions, you can log in to our website; Jessiepay.co or download the mobile App, to carry out all your transactions.",
  //   },
  //   {
  //     collapseTitle: "How to trade Cash receipt?",
  //     desc: "Those are not the appropriate platforms for our type of transactions. For secure, safe and seamless transactions, you can log in to our website; Jessiepay.co or download the mobile App, to carry out all your transactions.",
  //   },
  //   {
  //     collapseTitle: "How to sell Crypto?",
  //     desc: "Those are not the appropriate platforms for our type of transactions. For secure, safe and seamless transactions, you can log in to our website; Jessiepay.co or download the mobile App, to carry out all your transactions.",
  //   },
  //   {
  //     collapseTitle: "What are some ways I can use Perfect Money?",
  //     desc: "Those are not the appropriate platforms for our type of transactions. For secure, safe and seamless transactions, you can log in to our website; Jessiepay.co or download the mobile App, to carry out all your transactions.",
  //   },
  //   {
  //     collapseTitle: "What is Jessie Renew?",
  //     desc: "Those are not the appropriate platforms for our type of transactions. For secure, safe and seamless transactions, you can log in to our website; Jessiepay.co or download the mobile App, to carry out all your transactions.",
  //   },
  //   {
  //     collapseTitle: "I got an Invalid Meter Response?",
  //     desc: "Those are not the appropriate platforms for our type of transactions. For secure, safe and seamless transactions, you can log in to our website; Jessiepay.co or download the mobile App, to carry out all your transactions.",
  //   },
  //   {
  //     collapseTitle: "How to deactivate 2FA?",
  //     desc: "Those are not the appropriate platforms for our type of transactions. For secure, safe and seamless transactions, you can log in to our website; Jessiepay.co or download the mobile App, to carry out all your transactions.",
  //   },
  //   {
  //     collapseTitle: "How to reset your pin?",
  //     desc: "Those are not the appropriate platforms for our type of transactions. For secure, safe and seamless transactions, you can log in to our website; Jessiepay.co or download the mobile App, to carry out all your transactions.",
  //   },
  // ];
  return (
    <>
      <FaqContainer fluid>
        <FaqWrapper>
          <FaqHolder>
            {faqData &&
              faqData.results &&
              faqData.results.map((v, i) => {
                return (
                  <EachFaqWrapper key={i}>
                    <CollapsibleCmp
                      collapseTitle={v.question}
                      desc={v.answer}
                    />
                  </EachFaqWrapper>
                );
              })}
          </FaqHolder>
        </FaqWrapper>
        <PaginationWrapper>
          <PaginationCmp
            total={faqData?.count}
            limit={10}
            pageClick={(data) => {
              getAllFaqs(`?limit=${16}&page=${data.selected + 1}`);
            }}
          />
        </PaginationWrapper>
      </FaqContainer>
    </>
  );
}

const mapDispatch = (dispatch) => ({
  setGetAllFaqs: (query) => dispatch(getAllFaqs(query)),
});

export default connect(null, mapDispatch)(FaqCmp);
