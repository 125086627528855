import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import TeamJessie from "../../assets/team-jessie.png";

const TeamContainer = styled(Container)`
  padding: 6rem 5rem 6rem;
  background: rgba(12, 122, 147, 0.03);
  text-align: center;

  @media only screen and (max-width: 767px) {
    padding: 5rem 4rem 3rem;
  }

  @media only screen and (max-width: 580px) {
    padding: 4rem 1rem 3rem;
  }
`;

const TeamImage = styled(LazyLoadImage)`
  width: 100%;
`;

function TeamDifferent() {
  return (
    <>
      <TeamContainer fluid>
        <TeamImage src={TeamJessie} alt="Team" effect="blur" />
      </TeamContainer>
    </>
  );
}

export default TeamDifferent;
