import React from "react";
import styled from "styled-components";
import { Container, Row } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import JessieLandingBg from "../../assets/jessie-landing-bg.png";
import Playstore from "../../assets/playstore.svg";
import IOSStore from "../../assets/iosstore.svg";
import JessiePhoneMockup from "../../assets/jessie-mockup-phone.png";
import appTheme from "../../constants/theme";

const HeroContainer = styled(Container)`
  height: 80%;
  background: url(${JessieLandingBg}),
    -moz-linear-gradient(
        83.37deg,
        rgba(0, 146, 198, 1) 2.19%,
        rgba(24, 95, 150, 1) 96.1%
      ) no-repeat;
  background: url(${JessieLandingBg}),
    -webkit-linear-gradient(
        83.37deg,
        rgba(0, 146, 198, 1) 2.19%,
        rgba(24, 95, 150, 1) 96.1%
      ) no-repeat;
  background: url(${JessieLandingBg}),
    linear-gradient(
        83.37deg,
        rgba(0, 146, 198, 1) 2.19%,
        rgba(24, 95, 150, 1) 96.1%
      )
      no-repeat;
  border: none;
  outline: none;
  background-size: cover;

  /* @media only screen and (max-width: 890px) {
    height: 90%;
  } */
`;

const HeroWrapper = styled(Row)`
  display: flex;
  flex-direction: row;
  margin: auto 0;
  padding: 0 2rem;
  align-items: center;

  @media only screen and (max-width: 890px) {
    padding: 0 2rem;
  }

  @media only screen and (min-width: 1536px) {
    max-width: 1536px;
    margin: 0 auto;
  }
`;

const ContentLeft = styled.div`
  padding: 10rem 3rem;
  position: relative;

  @media only screen and (max-width: 960px) {
    padding: 9rem 3rem 5rem;
  }

  @media only screen and (max-width: 890px) {
    padding: 8rem 3rem 5rem;
  }

  @media only screen and (max-width: 600px) {
    padding: 8rem 0rem 5rem;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  padding-top: 1rem;

  @media only screen and (max-width: 890px) {
    justify-content: center;
    padding-top: 2rem;
  }

  @media only screen and (max-width: 600px) {
    padding-top: 2.5rem;
  }
`;

const PlayStoreImg = styled.img`
  padding: 0 0.2rem;

  @media only screen and (max-width: 350px) {
    width: 55%;
  }
`;

const IOSStoreImg = styled.img`
  padding: 0 0.2rem;

  @media only screen and (max-width: 350px) {
    width: 55%;
  }
`;

const HeroText = styled.h2`
  color: ${appTheme.COLORS.white};
  font-size: 2.8rem;
  font-weight: ${appTheme.WEIGHT.mediumBold};
  line-height: 4rem;
  max-width: 57%;

  @media only screen and (min-width: 1490px) {
    max-width: 50%;
  }

  @media only screen and (max-width: 1200px) {
    line-height: 3rem;
    font-size: 2.5rem;
  }

  @media only screen and (max-width: 1090px) {
    max-width: 65%;
  }

  @media only screen and (max-width: 950px) {
    max-width: 70%;
  }

  @media only screen and (max-width: 890px) {
    text-align: center;
    max-width: unset;
    width: 100%;
  }

  @media only screen and (max-width: 890px) {
    font-size: 2.3rem;
    line-height: 2.5rem;
  }

  @media only screen and (max-width: 620px) {
    font-size: 2rem;
    line-height: 2.2rem;
  }

  @media only screen and (max-width: 450px) {
    font-size: 1.8rem;
  }

  @media only screen and (max-width: 380px) {
    font-size: 1.6rem;
  }

  @media only screen and (max-width: 375px) {
    font-size: 1.5rem;
  }

  @media only screen and (max-width: 356px) {
    font-size: 1.4rem;
  }
`;

const HeroDesc = styled.p`
  color: ${appTheme.COLORS.white};
  font-size: ${appTheme.SIZES.body1};
  font-weight: ${appTheme.WEIGHT.small};
  max-width: 48%;

  @media only screen and (min-width: 1490px) {
    max-width: 40%;
  }

  @media only screen and (max-width: 1090px) {
    font-size: 14px;
    max-width: 50%;
  }

  @media only screen and (max-width: 890px) {
    text-align: center;
    margin: 0 auto;
    max-width: unset;
    width: 80%;
    font-size: 1rem;
  }

  @media only screen and (max-width: 750px) {
    width: 100%;
  }

  @media only screen and (max-width: 600px) {
    padding-top: 0.6rem;
  }
`;

// const ContentRight = styled.div``;

const RightImg = styled(LazyLoadImage)`
  position: absolute;
  bottom: 0;
  right: 3rem;
  width: 50%;

  @media only screen and (min-width: 1490px) {
    max-width: 50%;
  }

  @media only screen and (max-width: 960px) {
    width: 40%;
  }

  @media only screen and (max-width: 890px) {
    position: unset;
    margin: 0 auto;
    width: 70%;
    display: block;
  }

  @media only screen and (max-width: 700px) {
    width: 80%;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

function Hero() {
  return (
    <>
      <HeroContainer fluid>
        <HeroWrapper>
          <ContentLeft>
            <HeroText>
              Transforming Digital Asset Across Africa, And Around The World.
            </HeroText>
            <HeroDesc>
              Join millions of users across Africa and around the world to
              experience the best way to trade all your Digital Assets the
              secure and seamless way.
            </HeroDesc>
            <ButtonWrapper>
              <PlayStoreImg src={Playstore} alt="Playstore" />
              <IOSStoreImg src={IOSStore} alt="Apple Store" />
            </ButtonWrapper>
          </ContentLeft>
          <RightImg src={JessiePhoneMockup} alt={"Jessie-App"} effect="blur" />
        </HeroWrapper>
      </HeroContainer>
    </>
  );
}

export default Hero;
