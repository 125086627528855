import axios from "axios";
import {
  ALL_NEWS_HOME_LOADING,
  ALL_NEWS_HOME_SUCCESS,
  ALL_NEWS_LOADING,
  ALL_NEWS_SUCCESS,
  A_NEWS_LOADING,
  A_NEWS_SUCCESS,
} from "../reducers/types";
import { toast } from "react-toastify";
import { apiBaseUrl } from "../../constants/url";

export const getAllNews = (query) => async (dispatch) => {
  dispatch({ type: ALL_NEWS_LOADING, payload: true });
  try {
    const response = await axios.get(
      `${apiBaseUrl}/news/all/landing-page${query || ""}`
    );
    dispatch({ type: ALL_NEWS_LOADING, payload: null });
    dispatch({ type: ALL_NEWS_SUCCESS, payload: response.data });
    return response.data;
  } catch (err) {
    dispatch({ type: ALL_NEWS_LOADING, payload: null });
    return toast.error(err.response?.data?.message, {
      toastId: "Error",
    });
  }
};

export const getAllNewsHome = () => async (dispatch) => {
  dispatch({ type: ALL_NEWS_HOME_LOADING, payload: true });
  try {
    const response = await axios.get(`${apiBaseUrl}/news/all/home`);
    dispatch({ type: ALL_NEWS_HOME_LOADING, payload: null });
    dispatch({ type: ALL_NEWS_HOME_SUCCESS, payload: response.data });
    return response.data;
  } catch (err) {
    dispatch({ type: ALL_NEWS_HOME_LOADING, payload: null });
    return toast.error(err.response?.data?.message, {
      toastId: "Error",
    });
  }
};

export const getANewsBySlug = (slug) => async (dispatch) => {
  dispatch({ type: A_NEWS_LOADING, payload: true });
  try {
    const response = await axios.get(
      `${apiBaseUrl}/news/get-news/slug/${slug}`
    );
    dispatch({ type: A_NEWS_LOADING, payload: null });
    dispatch({ type: A_NEWS_SUCCESS, payload: response.data });
    return response.data;
  } catch (err) {
    dispatch({ type: A_NEWS_LOADING, payload: null });
    return toast.error(err.response?.data?.message, {
      toastId: "Error",
    });
  }
};
