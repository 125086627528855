import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import TextCmp from "../TextCmp/TextCmp";
import TitleCmp from "../TitleCmp/TitleCmp";
import TitleCmpWithStar from "../TitleCmp/TitleCmpWithStar";

const ContentContainer = styled(Container)`
  background-color: #f9fafb;
  padding: 4rem 3rem;
  text-align: left;

  @media only screen and (max-width: 600px) {
    padding: 3rem 2rem;
  }
`;

const ContentWrapper = styled.div`
  max-width: 850px;
  margin: 0 auto;
`;

const WhiteBackground = styled.div`
  max-width: 1000px;
  background-color: #fff;
  margin: 0 auto 2rem;
  padding: 3rem 0;
  border-radius: 20px;
  margin-top: 3rem;

  @media only screen and (max-width: 975px) {
    padding: 2rem;
  }
`;

function PrivacyContent() {
  return (
    <>
      <ContentContainer fluid>
        <ContentWrapper>
          <TitleCmp title="Overview" />
          <TextCmp text="Privacy Policy applies to all users, customer’s employees, third parties, visitors, clients and other connections (referred hereinafter to as “you” or “Users”) who order, sign up or interact with us or require any of Jessie’s products, services, information, content, functions, features and tools (hereinafter known as “our Services” or “the Service”) provided on our website, mobile application or API (altogether referred to as “Jessie’s Platform”)." />
          <br />
          <TitleCmp title="Consent" />
          <TextCmp text="Users accept and consent to the terms of this Privacy Policy when they order, sign up or interact with us or any of our Services regardless of how they are accessed or used" />
          <br />
          <TitleCmp title="Changes to this privacy policy" />
          <TextCmp
            text="We may modify this Privacy Policy from time to time by publishing or uploading an updated version on our website. The updated version will become effective as of the published date.
Furthermore, if any revised version includes a substantial change in terms, Users shall be informed via email (sent to the email address specified in their account), by means of a notice on the Jessie’s Platform."
          />
        </ContentWrapper>
        <WhiteBackground>
          <ContentWrapper>
            <TitleCmpWithStar title="The Data We Collect About You" />
            <TextCmp text="Personal data or personal information means any information about an individual from which that person can be identified. It does not include data where the identity has been removed (anonymous data)." />
            <TextCmp text="Further details are provided below." />
            <TextCmp weighty text="1. Identity Data" />
            <TextCmp
              text="Identity Data includes first name, maiden name, last name, username or similar identifier, marital status, title, date of birth, gender, signature, billing address, delivery address, email address, BVN etc.
"
            />
            <TextCmp
              text="We may collect and process your Identity Data in order to provide our Services, comply with applicable laws and regulatory requirements, detect and prevent fraud, protect our vital interests and that of others, provide Service communications, provide customer service, maintain data and information security, facilitate corporate reorganizations, implement direct marketing campaigns
"
            />
            <TextCmp weighty text="2. Formal Identity Documentation" />
            <TextCmp
              text="Formal Identity Documentation includes government issued identity documents such as International Passport, Driver's License, National Identity Card, Voters’ Card etc.
"
            />
            <TextCmp
              text="We may collect and process your Formal Identity Documentation in order to provide our Services, comply with applicable laws and regulatory requirements, detect and prevent fraud, protect our vital interests and that of others, provide Service communications, provide customer service, maintain data and information security, facilitate corporate reorganizations, implement direct marketing campaigns.
"
            />
            <TextCmp
              weighty
              text="3. Transaction Data
"
            />
            <TextCmp
              text="Transaction Data includes details about payments to and from you, bank account details and other details of Services you have received from us.

"
            />
            <TextCmp
              text="
              We may collect and process your Transaction Data in order to provide our Services, comply with applicable laws and regulatory requirements, detect and prevent fraud, protect our vital interests and that of others, provide customer service, ensure quality control, maintain data and information security, research and development purposes, enhance customer experience and implement direct marketing campaigns.
              
"
            />
            <TextCmp
              weighty
              text="4. Communication Data
"
            />
            <TextCmp
              text="Communication Data includes all messages, communications, requests, files etc which you send to us while using or interacting with our services for the purposes of making customer care enquiries, completing a feedback etc.
"
            />
            <TextCmp
              text="
              We may collect and process your Communication Data in order to provide customer service, ensure quality control, maintain data and information security, research and development purposes, comply with applicable laws and regulatory requirements, enhance customer experience and implement direct marketing campaigns.
"
            />
            <TextCmp
              weighty
              text="5. Technical Data
"
            />
            <TextCmp
              text="Technical Data includes internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, browser fingerprint, authentication data, security questions, click-stream data, public social networking posts, and other data collected via cookies and similar technologies.
"
            />
            <TextCmp
              text="We may collect and process your Technical Data in order to provide our Services, comply with applicable laws and regulatory requirements, detect and prevent fraud, research and development purposes, enhance customer experience and implement third party marketing campaigns.
  
"
            />
            <TextCmp
              weighty
              text="6. Aggregated Data
"
            />
            <TextCmp
              text="We also collect, use and share Aggregated Data such as statistical or demographic data for the purpose of analyzing web traffic and functionality of our website. Aggregated Data could be derived from your personal data but is not considered personal data in law as this data will not directly or indirectly reveal your identity. For example, we may aggregate your Transaction Data and Technical Data to calculate the percentage of users accessing a specific website feature. However, if we combine or connect Aggregated Data with your personal data so that it can directly or indirectly identify you, we treat the combined data as personal data which will be used in accordance with this privacy policy.
"
            />
            <br />
            <TitleCmpWithStar
              title="Disclosure of Your Personal Data
"
            />
            <TextCmp
              text="We may share your personal data with certain third-party which we deem necessary in order to provide our Services effectively or comply with applicable laws and regulatory requirements.

"
            />
            <TextCmp
              text="Our engagement terms with these third parties shall strictly require them to only use your information in connection with the services they perform for us and prohibit them from selling your information to anyone else. Such engagements shall also be subject to professional duty of confidentiality where applicable.
"
            />
            <TextCmp
              text="Further information are set out below –         
"
            />
            <TextCmp
              span="(a) Third party identity verification service providers"
              text="- In order to detect and prevent fraudulent transactions and comply with applicable laws and regulatory requirements, we may share your personal data with third party identity verification service providers who shall verify your identity against available public records and other third-party databases.
"
            />
            <TextCmp
              span="(b) Banks and other financial institution partners"
              text="– In order to process payments and withdrawals and comply with applicable laws and regulatory requirements we may share your personal data with banks and financial institutions.
"
            />
            <TextCmp
              span="(c) Service providers"
              text="– We may share your personal data with service providers that we engage their services to execute different business functions. Examples of these service providers include – cloud storages, network infrastructure providers, marketing, data analytics etc.

"
            />
            <TextCmp
              span="(d) Professional advisors"
              text="– We may share your personal data with professional advisors in order to avail ourselves with technical, financial or legal consulting services or in compliance with our legal obligations.
"
            />
            <TextCmp
              span="(e) Companies or other entities during corporate merger or takeover"
              text="– If a change in our corporate structure occurs as a result of the sale, transfer or merger of parts of our business or our assets or that of another entity by us, then the new corporate entity may use your personal data in the same way as set out in this privacy policy. You will receive prior notice of any change in applicable policies.
"
            />
            <TextCmp
              span="(f) Law enforcement agents and officials"
              text="– We may share your personal data with law enforcement agents and officials if we are bound by law or any legal procedure to share same or when we believe in good faith that the disclosure of your personal information is necessary to prevent physical harm or financial loss, to report suspected illegal activity, or to investigate violations of our Terms of Services or any other applicable policies.
"
            />
            <br />
            <TitleCmpWithStar
              title="Use of Cookies and Similar Technologies
"
            />
            <TextCmp
              text='When you access our websites or use our Services, we may place small data files on your
              computer or other device. These data files may be cookies, pixel tags, "Flash cookies," or other local storage provided by your browser or associated applications (collectively referred to as "Cookies"). These technologies are used to –
              
'
            />
            <br />
            <TextCmp
              noMargin
              text="(a) recognize Users as customers;             
"
            />
            <TextCmp
              noMargin
              text="(b) customize the Service, content, and advertising; measure promotional effectiveness;            
"
            />
            <TextCmp
              noMargin
              text="(c) help ensure that account security is not compromised;       
"
            />
            <TextCmp
              noMargin
              text="(d) mitigate risk and prevent fraud; and      
"
            />
            <TextCmp
              noMargin
              text="(e) promote trust and safety across the Service and related sites.      
"
            />
            <TextCmp
              text="Users can freely decline our Cookies if the web browser or browser add-on permits unless our Cookies are required to prevent fraud or ensure the security of websites we control. However, declining our Cookies may interfere with your use of our websites and the Service.      
"
            />
            <br />
            <TitleCmpWithStar
              title="Third Party Websites
"
            />
            <TextCmp
              text="The Jessie’s Platform may contain links to third-party websites and applications of interest, including advertisements and external services that are not affiliated with us. Once you have used these links to leave the Jessie’s Platform, any information you provide to these third parties is not covered by this Privacy Policy, and we cannot guarantee the safety and privacy of your information.      
"
            />
            <TextCmp
              text="Before visiting and providing any information to any third-party websites, you should inform yourself of the privacy policies and practices (if any) of the third party responsible for that website, and should take those steps necessary to, in your discretion, protect the privacy of your information.    
"
            />
            <TextCmp
              text="We are not responsible for the content or privacy and security practices and policies of any third parties, including other sites, services or applications that may be linked to or from the Jessie’s Platform.
  
"
            />
            <br />
            <TitleCmpWithStar
              title="Data Security
"
            />
            <TextCmp
              text="We have put in place appropriate security measures (and contractually require third parties we share your information with to maintain) appropriate physical, technical and administrative safeguards to prevent the personal data from being accidentally loss, misuse or in an unauthorized access, alteration or disclosure of your personal data. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal data on our instructions, and they are subject to a duty of confidentiality.      
"
            />
            <TextCmp
              text="The security of your personal information it is instructive to note that no method of electronic transmission or storage of data guarantees 100% security. Thus, we strive to use commercially acceptable means to protect your personal information and we cannot guarantee its absolute security.  
"
            />
            <TextCmp
              text="Based on the foregoing, we have established appropriate procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.
"
            />
            <br />
            <TitleCmpWithStar
              title="Retention of Personal Data
"
            />
            <TextCmp
              noMargin
              text="We will only retain your personal information for as long as reasonably necessary to –
      
"
            />
            <TextCmp
              noMargin
              text="(a) provide our services to you,
"
            />
            <TextCmp
              noMargin
              text="(b) archiving purposes to detect and investigate fraudulent transactions,
"
            />
            <TextCmp
              noMargin
              text="(c) comply with our legal obligations under financial or anti-money laundering laws
"
            />
            <TextCmp
              noMargin
              text="(d) satisfying any tax, accounting or reporting requirements; and
"
            />
            <TextCmp
              text="(e) upon our reasonable belief that there is a prospect of litigation in respect to our relationship with you.
"
            />
            <br />
            <TitleCmpWithStar
              title="Your Rights
"
            />
            <TextCmp
              noMargin
              text="Under certain circumstances, you can exercise certain rights provided under applicable laws and regulations in relation to the personal information we hold. These rights include the right to –
"
            />
            <TextCmp
              noMargin
              text="(a) give and withdraw consent;
"
            />
            <TextCmp
              noMargin
              text="(b) request access to your personal information;
"
            />
            <TextCmp
              noMargin
              text="(c) request the rectification of your personal information;
"
            />
            <TextCmp
              noMargin
              text="(d) request erasure of your personal information;
"
            />
            <TextCmp
              noMargin
              text="(e) object to the processing of your personal information;
"
            />
            <TextCmp
              noMargin
              text="(f) request that we restrict the processing of your personal information;
"
            />
            <TextCmp
              noMargin
              text="(g) request the transfer of your personal information to you or a third party; and
"
            />
            <TextCmp
              noMargin
              text="(h) complain at any time with any relevant authority for data protection issues.
"
            />
          </ContentWrapper>
        </WhiteBackground>
      </ContentContainer>
    </>
  );
}

export default PrivacyContent;
