import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import appTheme from "../../constants/theme";
import CoreValueCardCmp from "../CoreValueCardCmp/CoreValueCardCmp";

const CoreValueContainer = styled(Container)`
  background: #fff;
  padding-top: 3rem;
  text-align: center;
  background-size: cover;

  @media only screen and (max-width: 600px) {
    padding-top: 2rem;
  }
`;

const Wrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
`;

const Title = styled.h2`
  font-weight: ${appTheme.WEIGHT.mediumBold};
  font-size: 36px;
  color: ${appTheme.COLORS.grey1};

  @media only screen and (max-width: 800px) {
    font-size: 30px;
  }

  @media only screen and (max-width: 650px) {
    font-size: 25px;
  }

  @media only screen and (max-width: 450px) {
    font-size: 22px;
  }

  @media only screen and (max-width: 355px) {
    font-size: 18px;
  }
`;

const CoreValueWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 1rem;
  flex-wrap: wrap;
`;

const EachCardWrapper = styled.div`
  margin: 2rem 0.5rem;
  /* flex-basis: 33%; */

  @media only screen and (max-width: 767px) {
    margin: 1rem 0.5rem;
  }
`;

function CoreValuesCmp(props) {
  return (
    <>
      <CoreValueContainer>
        <Wrapper>
          <Title>Our Core Values</Title>
          <CoreValueWrapper>
            <EachCardWrapper>
              <CoreValueCardCmp
                title="Fairness"
                desc="Transparency is our watchword at Jessie."
                backColor="#F1F6F9"
              />
            </EachCardWrapper>
            <EachCardWrapper>
              <CoreValueCardCmp
                title="Integrity"
                desc="Jessie is built on honesty, we meet every commitment."
                backColor="#D7192010"
              />
            </EachCardWrapper>
            <EachCardWrapper>
              <CoreValueCardCmp
                title="Respect"
                desc="We communicate respectfully, everyone, everything is treated with utmost regard."
                backColor="#DCD9EE"
              />
            </EachCardWrapper>
            <EachCardWrapper>
              <CoreValueCardCmp
                title="Excellence"
                desc="Excellent customer service is the life blood of Jessie, we strive to bring out the best."
                backColor="#C5C0E3"
              />
            </EachCardWrapper>
            <EachCardWrapper>
              <CoreValueCardCmp
                title="Loyalty"
                desc="We understand, we listen, we are consistent, we are loyal to one another. Devoted to giving you the best!."
                backColor="#F1F6F9"
              />
            </EachCardWrapper>
            <EachCardWrapper>
              <CoreValueCardCmp
                title="Innovation"
                desc="Impossible means nothing as we strive to achieve and create the impossible."
                backColor="#F1F6F9"
              />
            </EachCardWrapper>
          </CoreValueWrapper>
        </Wrapper>
      </CoreValueContainer>
    </>
  );
}

export default CoreValuesCmp;
