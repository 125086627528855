import React from "react";
import styled from "styled-components";
import appTheme from "../../constants/theme";

const PillWrapper = styled.div`
  flex: ${(props) => (props.flex ? props.flex : "45%")};
  margin: ${(props) => (props.margin ? props.margin : "0.5rem 0")};
  text-align: center;
  padding: ${(props) =>
    props.specifyPadding ? props.specifyPadding : "1rem 4rem"};
  font-size: ${(props) => props.textFontSize || appTheme.SIZES.body3};
  background-color: ${(props) =>
    props.backColor || appTheme.COLORS.bluePrimary};
  color: ${(props) => props.color || appTheme.COLORS.black2};
  border-radius: ${(props) => props.borderRadius || "24px"};
  font-weight: ${(props) =>
    props.fontWeight ? props.fontWeight : `${appTheme.WEIGHT.mediumBold}`};
  border: ${(props) =>
    props.borderColor ? `1px solid ${props.borderColor}` : "none"};
  outline: none;
  width: ${(props) => props.widthWebkit || ""};
  width: ${(props) => props.widthMoz || ""};
  width: ${(props) => props.widthNormal || ""};
  max-width: 70%;
  @media only screen and (max-width: 832px) {
    width: 100%;
    max-width: 100%;
    font-size: ${(props) => `calc(${props.textFontSize} - 1px)`};
  }
  @media only screen and (max-width: 550px) {
    font-size: ${(props) => `calc(${props.textFontSize} - 4px)`};
  }
`;

function PillCmp(props) {
  return (
    <PillWrapper
      fontWeight={props.weighty}
      textFontSize={props.textFontSize}
      color={props.color}
      borderColor={props.borderColor}
      backColor={props.backColor}
      specifyPadding={props.specifyPadding}
      borderRadius={props.borderRadius}
      widthWebkit={props.widthWebkit}
      widthMoz={props.widthMoz}
      widthNormal={props.widthNormal}
      margin={props.margin}
      flex={props.flex}
    >
      {props.text}
    </PillWrapper>
  );
}

export default PillCmp;
